import {
  CHAT_USER,
  ACTIVE_USER,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_ACTIVE_USER_DATA,
  SET_USERS,
  ADD_MESSAGES_TO_USER,
  UPDATE_ACTIVE_USER_DATA,
  ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES,
  CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT,
  RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT,
  ADD_NEW_USER,
  CHANGE_CURRENT_USER_LAST_MESSAGE,
  CHANGE_CURRENT_USER_DATA,
  CHANGE_USERS_ONLINE_STATUS,
  CHANGE_INBOX_TITLE_NOTIFICATION,
  CHANGE_MY_CLIENTS_TITLE_NOTIFICATION,
  SET_DEFAULT_USERS,
  CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD,
  CHANGE_USER_ONLINE_STATUS,
  CHANGE_CLIENT_ISTYPING,
  CHANGE_CALL_WAITING_STATUS,
  CHANGE_GROUP_UNREAD_MESSAGES_COUNT,
  SET_ACTIVE_SESSION_NAME,
  SET_IS_CALL_IN_PROCESS,
  SET_NEW_MESSAGE_OBJ,
  ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION,
  CHANGE_ACTIVE_USER_TICKETS,
  SET_TECHNICAL_ASSISTANTS,
  SET_GROUPS,
  CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS,
  SET_DEFAULT_GROUPS,
  SET_DEFAULT_TECHNICAL_ASSISTANTS,
  CHANGE_USER_ISTYPING,
  SET_CLIENT_ACTIVE_CALL_ID,
  REMOVE_CLIENT_FROM_INBOX,
  SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION,
  REMOVE_CLIENT_FROM_CHAT_BOARD,
  ADD_NEW_USER_TO_MY_CLIENTS,
  CHANE_CLIENTS_ONLINE_STATUS,
  CHANGE_CURRENT_USER_NOTE,
  SET_COUNTRIES,
  SET_SELECTED_COUNTRY,
  SET_SELECTED_GROUP,
  SET_SELECTED_SCRIPT,
  SET_SCRIPT_GROUPS,
  SET_SCRIPTS,
  SET_IS_DISABLED_SCRIPTS,
  ADD_SCRIPT_GROUPS,
  SET_SCRIPT_GROUPS_PAGINATION,
  SET_SCRIPTS_PAGINATION,
  ADD_SCRIPTS,
  TOGGLE_GROUPS_BY_COUNTRY,
  SORT_CLIENTS,
  REMOVE_CURRNET_CHAT_ANIMATION
} from './../../Constants/ReduxKeys';


const INIT_STATE = {
  active_user: 0,
  activeUserData: localStorage.getItem("activeUserData") ? JSON.parse(localStorage.getItem("activeUserData")) : null,
  users: [],
  defaultUsers: [],
  activeSessionName: localStorage.getItem("activeSessionName") ? localStorage.getItem("activeSessionName") : "",
  isShowInboxTitleNotification: false,
  isShowMyClientsTitleNotification: false,
  isCallwaiting: false,
  groups: [],
  defaultGroups: [],
  isCallInProcess: localStorage.getItem("isCallInProcess") ? !!localStorage.getItem("isCallInProcess") : false,
  newMessageObj: null,
  technicalAssistants: [],
  defaultTechnicalAssistants: [],
  typerUserName: null,
  clientActiveCallId: null,
  isClientIsInActiveCallException: false,
  countries: [],
  selectedCountry: null,
  scriptGroups: [],
  selectedGroup: null,
  scripts: [],
  selectedScript: null,
  isDisabledScripts: false,
  scriptGroupsPagination: null,
  scriptsPagination: null,
  groupsByCountry: false,
};

const Chat = (state = INIT_STATE, action) => {

  let activeUserDataCopy = state.activeUserData ? { ...state.activeUserData } : null;
  let usersCopy = [...state.users];
  let defaultUsersCopy = [...state.defaultUsers];
  let technicalAssistantsCopy = [...state.technicalAssistants];
  let defaultTechnicalAssistantsCopy = [...state.defaultTechnicalAssistants];
  let scriptGroupsCopy = [...state.scriptGroups];
  let scriptsCopy = [...state.scripts];

  switch (action.type) {

    case REMOVE_CURRNET_CHAT_ANIMATION:
      usersCopy.forEach(chat => {
        if (chat.clientSessionKey === action.payload) {
          chat.isAnimate = false;
        }
      })
      return {
        ...state,
        users: usersCopy
      };

    case SORT_CLIENTS:
      const moveObjectById = (clientSessionKey, newPosition) => {
        if (!clientSessionKey) { return false; }
        const index = usersCopy.findIndex(obj => obj.clientSessionKey === clientSessionKey);
        if (index !== -1) {
          const [currentChat] = usersCopy.splice(index, 1);
          if ((activeUserDataCopy && currentChat && activeUserDataCopy.clientSessionKey !== currentChat.clientSessionKey) || (!activeUserDataCopy && currentChat)) {
            currentChat.isAnimate = true;
          }
          usersCopy.splice(newPosition, 0, currentChat);
        }
        return usersCopy;
      }
      return {
        ...state,
        users: moveObjectById(action.payload, 0)
      };
    case TOGGLE_GROUPS_BY_COUNTRY:
      return {
        ...state,
        groupsByCountry: action.payload,
      };
    case SET_SCRIPT_GROUPS_PAGINATION:
      return {
        ...state,
        scriptGroupsPagination: action.payload,
      };
    case SET_SCRIPTS_PAGINATION:
      return {
        ...state,
        scriptsPagination: action.payload,
      };
    case SET_IS_DISABLED_SCRIPTS:
      return {
        ...state,
        isDisabledScripts: action.payload,
      };
    case SET_SCRIPTS:
      return {
        ...state,
        scripts: action.payload ? action.payload : [],
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload ? action.payload : [],
      };
    case SET_SCRIPT_GROUPS:
      return {
        ...state,
        scriptGroups: action.payload ? action.payload : [],
      };
    case ADD_SCRIPT_GROUPS:
      scriptGroupsCopy = [...scriptGroupsCopy, ...action.payload]
      return {
        ...state,
        scriptGroups: scriptGroupsCopy,
      };
    case ADD_SCRIPTS:
      scriptsCopy = [...scriptsCopy, ...action.payload]
      return {
        ...state,
        scripts: scriptsCopy,
      };

    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload ? action.payload : null,
      };

    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload ? action.payload : null,
      };

    case SET_SELECTED_SCRIPT:
      return {
        ...state,
        selectedScript: action.payload ? action.payload : null,
      };

    case CHAT_USER:
      return {
        ...state
      };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload
      };

    case CHANGE_INBOX_TITLE_NOTIFICATION:
      return {
        ...state,
        isShowInboxTitleNotification: action.payload
      };

    case CHANGE_MY_CLIENTS_TITLE_NOTIFICATION:
      return {
        ...state,
        isShowMyClientsTitleNotification: action.payload
      };

    case CHANGE_CURRENT_USER_NOTE:
      activeUserDataCopy.note = action.payload;
      return {
        ...state,
        activeUserData: activeUserDataCopy,
      };

    case ADD_MESSAGES_TO_USER:
      const users = [...state.users];
      users.length && users.forEach(user => {
        if (user.clientSessionKey === action.payload.clientSessionKey) {
          user.messages = action.payload.messages;
          if (activeUserDataCopy) activeUserDataCopy.messages = action.payload.messages;
        }
      })
      return {
        ...state,
        users: users,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case ADD_FIELDS_TO_ACTIVE_USER_DATA_FROM_SESSION:
      const { tickets, isSessionAttachedMe, attachedUserId, sessionId, feedbackResponse, note, sessionSource } = action.payload;
      if (activeUserDataCopy) {
        activeUserDataCopy.tickets = tickets;
        activeUserDataCopy.isSessionAttachedMe = isSessionAttachedMe;
        activeUserDataCopy.attachedUserId = attachedUserId;
        activeUserDataCopy.sessionId = sessionId;
        activeUserDataCopy.feedbackResponse = feedbackResponse ? feedbackResponse : null;
        activeUserDataCopy.note = note ? note : "";
        activeUserDataCopy.source = sessionSource;
      }
      return {
        ...state,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case SET_USERS:
      return {
        ...state,
        users: action.payload ? action.payload : [],
      };

    case SET_DEFAULT_USERS:
      return {
        ...state,
        users: action.payload ? action.payload : [],
        defaultUsers: action.payload ? action.payload : [],
      };

    case ADD_MESSAGE_TO_ACTIVE_USER_MESSAGES:
      if (!activeUserDataCopy) { return false; }
      if (activeUserDataCopy.messages) {
        activeUserDataCopy.messages.push(action.payload);
      } else {
        activeUserDataCopy.messages = [action.payload];
      }
      return {
        ...state,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case CHANGE_CURRENT_MESSAGE_RECEIVED_FIELD:
      if (!activeUserDataCopy) {
        return false;
      }
      if (activeUserDataCopy.messages && activeUserDataCopy.messages.length) {
        // todo
        activeUserDataCopy.messages.forEach(message => {
          if (+message.timeStampId.trim() === +action.payload) {
            message.isReceived = true;
            // activeUserDataCopy.lastMessageResponse = message;
          }
        })
      }
      return {
        ...state,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case CHANGE_ACTIVE_USER_TICKETS:
      if (!activeUserDataCopy || !action.payload) {
        return;
      }
      if (activeUserDataCopy.tickets && activeUserDataCopy.tickets.length) {
        var foundIndex = activeUserDataCopy.tickets.findIndex(x => +x.id === +action.payload.id);
        if (foundIndex > -1) {
          activeUserDataCopy.tickets[foundIndex] = {
            ...action.payload
          }
        } else {
          activeUserDataCopy.tickets.push({
            ...action.payload
          });
        }
      } else {
        activeUserDataCopy.tickets = [action.payload];
      }
      return {
        ...state,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case FULL_USER:
      return {
        ...state,
        users: action.payload
      };

    case ADD_LOGGED_USER:
      const newUser = action.payload
      return {
        ...state, users: [
          ...state.users, newUser
        ]
      };

    case CREATE_GROUP:
      const newGroup = action.payload
      return {
        ...state, groups: [
          ...state.groups, newGroup
        ]
      };

    case SET_ACTIVE_USER_DATA:
      var activeUserData = null;
      if (action.payload) {
        activeUserData = [...state.users].find(user => user.clientSessionKey && user.clientSessionKey === action.payload);
        if (activeUserData) {
          activeUserData.unreadMessagesCount = 0;
          localStorage.setItem("activeUserData", JSON.stringify(activeUserData));
        }
      } else {
        activeUserData = null;
        localStorage.removeItem("activeUserData");
      }
      return {
        ...state,
        activeUserData,
      };

    case UPDATE_ACTIVE_USER_DATA:
      return {
        ...state,
        activeUserData: action.payload ? action.payload : null,
      };

    case CHANGE_CURRENT_USER_UNREAD_MESSAGES_COUNT:
      var isShowMyClientsTitleNotificationCopy = state.isShowMyClientsTitleNotification;
      var isShowInboxTitleNotificationCopy = state.isShowInboxTitleNotification;
      usersCopy.forEach(user => {
        if (user.clientSessionKey === action.payload) {
          if (user.unreadMessagesCount) {
            user.unreadMessagesCount += 1;
          } else {
            user.unreadMessagesCount = 1;
          }
        }
      })
      return {
        ...state,
        users: usersCopy,
        isShowMyClientsTitleNotification: isShowMyClientsTitleNotificationCopy,
        isShowInboxTitleNotification: isShowInboxTitleNotificationCopy,
      };

    case RESET_CURRENT_USER_UNREAD_MESSAGES_COUNT:
      usersCopy.forEach(user => {
        if (user.clientSessionKey === action.payload) {
          user.unreadMessagesCount = 0;
        }
      })
      return {
        ...state,
        users: usersCopy,
      };

    case ADD_NEW_USER:
      var myClient = usersCopy.find(user => user.supporterUserId);
      // if (!usersCopy.length && state.activeSessionName && state.activeSessionName === "inbox") {
      //   activeUserDataCopy = {
      //     ...action.payload
      //   };
      //   localStorage.setItem("activeUserData", JSON.stringify(activeUserDataCopy))
      // }
      if (!myClient) {
        usersCopy.unshift(action.payload);
      }
      return {
        ...state,
        users: usersCopy,
        // activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case ADD_NEW_USER_TO_MY_CLIENTS:
      let isExistUser = usersCopy.find(user => user.clientSessionKey && user.clientSessionKey === action.payload.clientSessionKey);
      if (!isExistUser) {
        usersCopy.unshift(action.payload);
      }
      return {
        ...state,
        users: usersCopy,
      };

    case CHANGE_CURRENT_USER_LAST_MESSAGE:
      usersCopy.forEach(user => {
        if (user.clientSessionKey === action.payload.clientSessionKey) {
          user.lastMessageResponse = action.payload.lastMessageResponse;
          // user.messages.push(action.payload.lastMessageResponse);
        }
      })
      return {
        ...state,
        users: usersCopy,
      };

    case CHANGE_USERS_ONLINE_STATUS:
      var usersIds = [...action.payload];
      usersCopy.forEach(userCopy => {
        usersIds.find(userId => {
          if (userId === userCopy.clientSessionKey) {
            userCopy.isOnline = true;
          }
          if (activeUserDataCopy && userId === activeUserDataCopy.clientSessionKey) {
            activeUserDataCopy.isOnline = true;
          }
        });
      })
      return {
        ...state,
        users: usersCopy,
        activeUserData: activeUserDataCopy
      };

    case CHANGE_USER_ONLINE_STATUS:
      var {
        clientSessionKey, bool
      } = action.payload;
      if (usersCopy.length) {
        usersCopy.forEach(user => {
          if (user.clientSessionKey === clientSessionKey) {
            user.isOnline = bool;
            if (activeUserDataCopy && activeUserDataCopy.clientSessionKey === clientSessionKey) {
              activeUserDataCopy.isOnline = bool;
            }
          }
        })
      }
      if (defaultUsersCopy.length) {
        defaultUsersCopy.forEach(user => {
          if (user.clientSessionKey === clientSessionKey) {
            user.isOnline = bool;
          }
        })
      }
      return {
        ...state,
        users: usersCopy,
        defaultUsers: defaultUsersCopy,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case CHANGE_CLIENT_ISTYPING:
      var {
        clientSessionKey, bool
      } = action.payload;
      if (!activeUserDataCopy) {
        return;
      }
      usersCopy.forEach(user => {
        if (user.clientSessionKey === clientSessionKey) {
          user.isTyping = bool;
          if (activeUserDataCopy.clientSessionKey === clientSessionKey) activeUserDataCopy.isTyping = bool;
        }
      })
      return {
        ...state,
        users: usersCopy,
        activeUserData: activeUserDataCopy && Object.keys(activeUserDataCopy).length ? activeUserDataCopy : null,
      };

    case CHANGE_USER_ISTYPING:
      var {
        bool, typerUserName
      } = action.payload;
      var typerUserNameItem;
      if (bool) {
        typerUserNameItem = typerUserName;
      } else {
        typerUserNameItem = null;
      }
      return {
        ...state,
        typerUserName: typerUserNameItem
      };

    case CHANGE_CURRENT_USER_DATA:
      var userNewData = { ...action.payload };
      usersCopy.forEach(userData => {
        if (userData.clientSessionKey === userNewData.clientSessionKey) {
          userData.clientName = userNewData.clientName
          userData.userId = userNewData.userId
          userData.email = userNewData.email
          if (activeUserDataCopy && userNewData.clientSessionKey === activeUserDataCopy.clientSessionKey) {
            activeUserDataCopy = userData;
          }
        }
      })
      return {
        ...state,
        users: usersCopy,
        activeUserData: activeUserDataCopy,
      };

    case CHANGE_CALL_WAITING_STATUS:
      return {
        ...state,
        isCallwaiting: action.payload,
      };

    case SET_GROUPS:
      return {
        ...state,
        groups: action.payload ? action.payload : [],
      };

    case SET_DEFAULT_GROUPS:
      return {
        ...state,
        groups: action.payload ? action.payload : [],
        defaultGroups: action.payload ? action.payload : [],
      };

    case SET_ACTIVE_SESSION_NAME:
      localStorage.setItem("activeSessionName", action.payload);
      return {
        ...state,
        activeSessionName: action.payload
      };

    case CHANGE_GROUP_UNREAD_MESSAGES_COUNT:
      const groupsCopy = [...state.groups];
      groupsCopy.forEach(group => {
        if (group.groupName === action.payload) {
          group.inboxMessageCount += 1;
        }
      })
      return {
        ...state,
        groups: groupsCopy
      };

    case SET_IS_CALL_IN_PROCESS:
      if (!action.payload) {
        localStorage.removeItem("isCallInProcess")
      } else { localStorage.setItem("isCallInProcess", action.payload) }
      return {
        ...state,
        isCallInProcess: action.payload,
      };
    case SET_NEW_MESSAGE_OBJ:
      return {
        ...state,
        newMessageObj: action.payload,
      };
    case SET_TECHNICAL_ASSISTANTS:
      return {
        ...state,
        technicalAssistants: action.payload ? action.payload : [],
      };
    case SET_DEFAULT_TECHNICAL_ASSISTANTS:
      return {
        ...state,
        technicalAssistants: action.payload ? action.payload : [],
        defaultTechnicalAssistants: action.payload ? action.payload : [],
      };
    case CHANGE_TECHNICAL_ASSISTANT_ONLINE_STATUS:
      if (technicalAssistantsCopy.length) {
        technicalAssistantsCopy.forEach(technicalAssistant => {
          if (technicalAssistant.id === action.payload.userId) {
            technicalAssistant.isOnline = action.payload.bool;
          }
        })
      }
      if (defaultTechnicalAssistantsCopy.length) {
        defaultTechnicalAssistantsCopy.forEach(technicalAssistant => {
          if (technicalAssistant.id === action.payload.userId) {
            technicalAssistant.isOnline = action.payload.bool;
          }
        })
      }
      return {
        ...state,
        technicalAssistants: technicalAssistantsCopy,
        defaultTechnicalAssistants: defaultTechnicalAssistantsCopy,
      };

    case SET_CLIENT_ACTIVE_CALL_ID:
      if (action.payload) {
        localStorage.setItem("clientActiveCallId", action.payload);
      } else {
        localStorage.removeItem("clientActiveCallId");
      }
      return {
        ...state,
        clientActiveCallId: action.payload ? action.payload : null,
      };

    case SET_CLIENT_IS_IN_ACTIVE_CALL_EXCEPTION:
      return {
        ...state,
        isClientIsInActiveCallException: action.payload,
      };

    case REMOVE_CLIENT_FROM_INBOX:
      usersCopy = usersCopy.filter(userCopy => userCopy.clientSessionKey !== action.payload);
      if (activeUserDataCopy && activeUserDataCopy.clientSessionKey === action.payload) {
        activeUserDataCopy = null;
        localStorage.removeItem("activeUserData");
      }
      return {
        ...state,
        users: usersCopy,
        activeUserData: activeUserDataCopy
      };

    case REMOVE_CLIENT_FROM_CHAT_BOARD:
      usersCopy = usersCopy.filter(userCopy => userCopy.clientSessionKey !== action.payload);
      if (activeUserDataCopy && activeUserDataCopy.clientSessionKey === action.payload) {
        activeUserDataCopy = null;
        localStorage.removeItem("activeUserData");
      }
      return {
        ...state,
        users: usersCopy,
        activeUserData: activeUserDataCopy
      };

    case CHANE_CLIENTS_ONLINE_STATUS:
      if (usersCopy.length) {
        usersCopy.forEach(user => {
          user.isOnline = false;
        })
      }
      if (technicalAssistantsCopy.length) {
        technicalAssistantsCopy.forEach(technicalAssistant => {
          technicalAssistant.isOnline = false;
        })
      }
      if (activeUserDataCopy) {
        activeUserDataCopy.isOnline = false;
        localStorage.setItem("activeUserData", JSON.stringify(activeUserDataCopy));
      }
      return {
        ...state,
        users: usersCopy,
        technicalAssistants: technicalAssistantsCopy,
        activeUserData: activeUserDataCopy
      };

    default:
      return {
        ...state
      };
  }
}

export default Chat;