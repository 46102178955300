import React from 'react'
import { useEffect } from 'react';

export default function DraggableComponent() {

  var dragItem = document.querySelector("#draggable");

  useEffect(() => {
    if (dragItem) {
      dragElement(dragItem);
    }
  }, [dragItem, document.querySelector(".vnd--media-container")]);

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById("videoContainer")) {
      document.getElementById("videoContainer").onmousedown = dragMouseDown;
    } else {
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      if (elmnt.offsetTop <= 150) {
        elmnt.style.top = 151 + 'px';
      } else if (elmnt.offsetLeft <= 150) {
        elmnt.style.left = 151 + 'px';
      } else if (elmnt.offsetTop >= window.innerHeight + 50) {
        elmnt.style.top = window.innerHeight + "px"
      } else if (elmnt.offsetLeft >= window.innerWidth) {
        elmnt.style.left = window.innerWidth - 1 + "px"
      } else {
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      // GPT
      if (elmnt.offsetTop + elmnt.offsetHeight > window.innerHeight) {
        elmnt.style.top = window.innerHeight + "px";
      }
      if (elmnt.offsetLeft + elmnt.offsetWidth > window.innerWidth) {
        elmnt.style.left = window.innerWidth - elmnt.offsetWidth + 'px';
      }
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }


  return (
    <div id='draggable' style={{ left: window.innerWidth + "px" }}>
      <div id="videoContainer" />
    </div>
  )
}
