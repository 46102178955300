import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import loginImage from "../../../assets/images/login-image.jpeg";
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput';
import Input from '../../../Components/Inputs/Input';
import { setActiveSection } from '../../../Store/Actions/main';
import { removeLoginErrorMessage, userLogin } from '../../../Store/Actions/user';
import { addButtonSpinner } from './../../../Store/Actions/spinner';
import uuid from 'react-uuid';
import ActionButton from './../../../Components/Buttons/ActionButton';
import { INVALID_USERNAME_OR_PASSWORD, fieldLengths } from '../../../Constants/MainKeys';
import TranslationService from '../../../Services/translationService';
import planetOrangeSvg from "./../../../assets/images/resources/planet-orange.svg"
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import coverImage from '../../../assets/images/3d-images/massage-emojies.png';
import microsoft from '../../../assets/images/auth-services/microsoft.svg';
import apple from '../../../assets/images/auth-services/apple.svg';
import facebook from '../../../assets/images/auth-services/facebook.svg';
import google from '../../../assets/images/auth-services/google.svg';
// import { GoogleLogin } from 'react-google-login';

import Parser from 'html-react-parser';

const spinnerId = uuid()
const SignIn = (props) => {

  const dispatch = useDispatch();
  const loginErrorMessage = useSelector(state => state.user.loginErrorMessage);
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);


  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    dispatch(removeLoginErrorMessage());
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(setActiveSection("sign-in"));
  }, [])

  const loginWithGoogle = (response) => {
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!username.trim() || !password.trim()) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      dispatch(addButtonSpinner(spinnerId));
      const data = {
        username: username.trim(),
        password: password.trim(),
        userRegistrationSource: 0
      }
      dispatch(userLogin(data, spinnerId, props.history));
    }
  }

  return translationService ? <Auxiliary>
    {/* <img src={planetOrangeSvg} className="vnd-decoreation vnd-decoration-right vnd-decoration-top vnd-orange-planet" alt="planet-orange" /> */}
    <section className="h-section section">
      <div className="container">
        <div className='row'>
          <div className='col-lg-6'>
            <div className="vnd-block white-background vnd-border">
              <div className="mb-4">
                <h5 className="vnd--text-50">{translationService.translate("TR_SIGNIN")}</h5>
              </div>
              <form id="signinForm" onSubmit={onSubmit}>
                <Input
                  id="username"
                  htmlFor="username"
                  type="email"
                  name="username"
                  labelValue={`${translationService.translate("TR_EMAIL")} *`}
                  placeholder={`${translationService.translate("TR_EMAIL")}`}
                  // required={true}
                  value={username}
                  inputClassName={`${(isInvalidSubmit && !username.trim()) ? "error-border" : ""}`}
                  onChange={(event) => onChange(event, setUserName, fieldLengths.maxLength_120)}
                />
                <Input
                  id="password"
                  htmlFor="password"
                  type="password"
                  name="password"
                  labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                  placeholder={`${translationService.translate("TR_PASSWORD")}`}
                  autoComplete="new-password"
                  inputClassName={`${(isInvalidSubmit && !password.trim()) ? "error-border" : ""}`}
                  required={true}
                  onChange={(event) => onChange(event, setPassword, fieldLengths.maxLength_100)}
                />
                <div className="d-flex justify-content-between mt-4">
                  <CheckBoxInput
                    id="remmebrMe"
                    htmlFor="remmebrMe"
                    blockClassName="mt-2 font-size-14"
                    labelValue={translationService.translate("TR_REMEMBER_ME")}
                    checked={rememberMe}
                    onChange={(event) => {
                      if (event.target.checked) {
                        localStorage.setItem("rememberMe", true);
                      } else {
                        localStorage.removeItem("rememberMe");
                      }
                      setRememberMe(event.target.checked);
                      dispatch(removeLoginErrorMessage());
                    }}
                  />
                  <Link to={`/${language}/forgot-password`} className="d-block mt-2 font-size-14">{translationService.translate("TR_FORGOT_PASSWORD")}</Link>
                </div>
                {
                  loginErrorMessage === INVALID_USERNAME_OR_PASSWORD ?
                    <small className="red-color mb-2 d-block mt-2">{translationService.translate("TR_INVALID_EMAIL_PASSWORD")}</small>
                    : null
                }
                <ActionButton
                  type="submit"
                  name={translationService.translate("TR_SIGNIN")}
                  className="vnd-btn vnd-primary-btn my-3 w-100"
                  spinnerId={spinnerId}
                />
              </form>
              <small className='font-size-14'>{translationService.translate("TR_NOT_REGISTRED")} ? <Link to={`/${language}/sign-up`}>{Parser(translationService.translate("TR_CREATE_ACCOUNT"))}</Link></small>
              {/* <div className='d-flex align-items-center my-4'>
                <hr className='flex-1' />
                <small className='mx-3 d-block text-uppercase font-bold'>You can also sign in</small>
                <hr className='flex-1' />
              </div> */}
              <div className='vnd--alternative-auth-section'>
                {/* <GoogleLogin
                  clientId="782906519700-bfpkmk9podrf7h1233rl7ce9qkuhde1i.apps.googleusercontent.com"
                  buttonText="Login with Google"
                  onSuccess={loginWithGoogle}
                  onFailure={loginWithGoogle}
                  cookiePolicy={'single_host_origin'}
                /> */}
                {/* <div className='vnd--alternative-auth-item' onClick={loginWithGoogle}>
                  <img src={google} alt="/" />
                  <span>Google</span>
                </div>
                <div className='vnd--alternative-auth-item'>
                  <img src={facebook} alt="" />
                  <span>facebook</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className='col-lg-6 d-lg-block d-none'>
            <div className="vnd-block light-brand-background h-100 position-relative">
              <div className='vnd-blur-block'>
                <div className="vnd-blur-block-text-wrapper">
                  <p className="vnd--upp-text-22">{translationService.translate("TR_SIGNIN_INFO_TITLE")}</p>
                  <hr />
                  <q className='position-relative font-italic z-index-10 font-size-18'>{translationService.translate("TR_SIGNIN_INFO")}</q>
                </div>
              </div>
              <img className='vnd--auth-image' src={coverImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Auxiliary> : null
}

export default withRouter(SignIn)
