import React, { useState, useEffect, useCallback } from 'react';
import { InputGroup } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY, SUPERVISOR_USER_TYPE_ID_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import ApiService from '../../../../Services/ApiService';
import NoData from '../../../../Components/NoData/NoData';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../../Services/translationService';
import CustomInput from "../../../../Components/Inputs/Input"
import uuid from 'react-uuid';
import {
    setActiveUserData,
    setDefaultTechnicalAssistants,
    setTechnicalAssistants,
} from "../../../../Store/Actions/chat";
import {
    addSpinner,
    removeSpinner,
} from '../../../../Store/Actions/spinner';
import { AiOutlineUser } from "react-icons/ai";


const TechnicalAssistants = (props) => {

    const dispatch = useDispatch();
    const activeUserData = useSelector(state => state.chat.activeUserData);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);
    const user = useSelector(state => state.user.user);
    const partnerId = useSelector(state => state.main.partnerId);

    const technicalAssistants = useSelector(state => state.chat.technicalAssistants);
    const defaultTechnicalAssistants = useSelector(state => state.chat.defaultTechnicalAssistants);

    const [searchText, setSearchText] = useState("");
    const [translationService, setTranslationService] = useState(null);

    useEffect(() => {
        const getTechnicalAssistantsList = (partnerId) => {
            if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) {
                const spinnerId = uuid();
                setSpinner(spinnerId);
                ApiService.getTechnicalAssistantsList(partnerId).then(response => {
                    if (response && response.data) {
                        const data = [...response.data];
                        let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                        dispatch(setTechnicalAssistants(sortedData));
                        dispatch(setDefaultTechnicalAssistants(sortedData));
                    }
                    extractSpinner(spinnerId);
                }).catch(error => getFail(error, spinnerId))
            }
        }
        if (user.partnerId || partnerId) {
            getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
        }
    }, []);

    useEffect(() => {
        dispatch(setActiveUserData(null));
    }, [activeUserData]);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);



    const search = (event, cb, maxLength = null) => {
        const defaultTechnicalAssistantsCopy = [...defaultTechnicalAssistants];
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        if (event.target.value.length && event.target.value.trim().length) {
            if (defaultTechnicalAssistantsCopy && defaultTechnicalAssistantsCopy.length) {
                dispatch(setTechnicalAssistants([...defaultTechnicalAssistantsCopy].filter(technicalAssistant => technicalAssistant.fullName.toUpperCase().includes(event.target.value.toUpperCase()))))
            }
        } else {
            dispatch(setTechnicalAssistants(defaultTechnicalAssistantsCopy))
        }
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
    };

    const maxHeight = () => {
        if (window.innerWidth >= 992) {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 10}px)`
        } else {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 70}px)`
        }
    }

    return translationService ? <Auxiliary>
        {
            <div className='vnd-list-container'>
                <aside>
                    <div className="p-3 left-bar-header align-items-center">
                        <h4 className="vnd--upp-text-20">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}</h4>
                        {
                            defaultTechnicalAssistants && defaultTechnicalAssistants.length ?
                                <div className={`search-box chat-search-box`}>
                                    <InputGroup size="lg" className="rounded-lg">
                                        <span className="text-decoration-none text-muted pr-1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                                        <CustomInput
                                            id="searchText"
                                            htmlFor="searchText"
                                            type="text"
                                            name="searchText"
                                            placeholder={`${translationService.translate("TR_SEARCH")} ...`}
                                            value={searchText}
                                            onChange={(e) => search(e, setSearchText, fieldLengths.maxLength_100)}
                                        />
                                    </InputGroup>
                                </div>
                                : null
                        }
                        {/* Search Box */}
                    </div>
                    <hr className='m-0 mb-2' />
                </aside>
                <div className="px-2 mb-auto">
                    <ul className="list-unstyled chat-list chat-user-list" id="chat-list">
                        {
                            technicalAssistants && technicalAssistants.length ? technicalAssistants.map((technicalAssistant, key) =>
                                <li
                                    key={key}
                                    id={"conversation_" + key}
                                    className={`cl-chat-wrappers`}
                                >
                                    <Link to={`/${language}/dashboard/chats/my-clients/technical-assistant/${technicalAssistant.id}`}>
                                        <div className="d-flex align-items-center">
                                            <div className={"chat-user-img " + `${technicalAssistant.isOnline ? "online" : "away"}` + " align-self-center me-3 ms-0"}>
                                                <div className="avatar-xs">
                                                    {
                                                        technicalAssistant.avatarPath ?
                                                            <img src={technicalAssistant.avatarPath} className="rounded-circle avatar-xs" alt="/" />
                                                            : <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                                {technicalAssistant.fullName ? technicalAssistant.fullName.charAt(0).toUpperCase() : <AiOutlineUser />}
                                                            </span>
                                                    }

                                                </div>
                                                <span className="user-status"></span>
                                            </div>

                                            <div className="flex-1 overflow-hidden menu-item-wrapper">
                                                <h5 className="text-truncate font-size-15 m-0">{technicalAssistant.fullName}</h5>
                                                <small>{translationService.translate(technicalAssistant.userTypeName)}</small>
                                            </div>
                                            {
                                                technicalAssistant.activeSessionsCount ?
                                                    <div className="unread-message" title='Active sessions count'>
                                                        <span className="badge badge-soft-danger rounded-pill">{technicalAssistant.activeSessionsCount}</span>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </Link>
                                </li>
                            ) : <NoData width={200} height={200} />
                        }
                    </ul>
                </div>
            </div>
        }
    </Auxiliary> : null
}

export default withRouter(TechnicalAssistants)
