import React, { useCallback, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import loginImage from "../../../assets/images/login-image.jpeg";
import Input from '../../../Components/Inputs/Input';
import { setActiveSection } from '../../../Store/Actions/main';
import { addButtonSpinner, removeButtonSpinner } from './../../../Store/Actions/spinner';
import uuid from 'react-uuid';
import ActionButton from './../../../Components/Buttons/ActionButton';
import { ERROR_KEY, fieldLengths } from '../../../Constants/MainKeys';
import ApiService from './../../../Services/ApiService';
import AlertService from './../../../Services/AlertService';
import { SUCCESS_KEY } from './../../../Constants/MainKeys';
import MainService from '../../../Services/mainService';
import TranslationService from '../../../Services/translationService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';

import coverImage from '../../../assets/images/3d-images/password.png';

const spinnerId = uuid();

const ForgotPassword = (props) => {

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [email, setEmail] = useState("");
  const [forgotPasswordMailSended, setForgotPasswordMailSended] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(setActiveSection("sign-in"));
  }, [])

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    switch (fieldName) {
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!email.trim().length) {
      setIsInvalidSubmit(true);
      return;
    } else {
      setButtonSpinner(spinnerId);
      ApiService.forgotPassword(email).then(() => {
        setForgotPasswordMailSended(true);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_REQUEST_SENT"));
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setTimeout(() => {
      spinnerId && extractButtonSpinner(spinnerId);
    }, 1000);
    // setEmail("");
  };

  return translationService ? <section className="h-section section">
    <div className="container">
      <div className='row'>
        <div className='col-lg-6'>
          <div className="vnd-block white-background vnd-border">
            <div className="mb-4">
              <h5 className="vnd-section-title">{translationService.translate("TR_FORGOT_PASSWORD")}</h5>
            </div>
            <form id="signinForm" onSubmit={onSubmit}>
              <Input
                id="email"
                htmlFor="email"
                type="email"
                name="email"
                labelValue={`${translationService.translate("TR_EMAIL")} *`}
                placeholder={`${translationService.translate("TR_EMAIL")}`}
                value={email}
                disabled={forgotPasswordMailSended ? true : false}
                inputClassName={`${(isInvalidSubmit && !email.trim() || isInvalidEmail) ? "error-border" : ""}`}
                onChange={(event) => onChange(event, setEmail, fieldLengths.maxLength_120)}
                onBlur={() => checkFieldsValidation(email, "email")}
              />
              {
                !forgotPasswordMailSended ?
                  <ActionButton
                    type="submit"
                    name={translationService.translate("TR_SEND")}
                    className="vnd-btn vnd-primary-btn my-3"
                    spinnerId={spinnerId}
                  /> :
                  <Auxiliary>
                    <Link className="my-3 d-block" to="#" onClick={() => setForgotPasswordMailSended(false)}>{translationService.translate("TR_DIDNT_RECEIVE_EMAIL")}</Link>
                    <hr />
                  </Auxiliary>
              }
            </form>
            <small className='font-size-14'>{translationService.translate("TR_HAVE_ACCOUNT")} ? <Link to={`/${language}/sign-in`}>{translationService.translate("TR_SIGNIN")}</Link></small>
          </div>
        </div>
        <div className='col-lg-6 mt-lg-0 mt-3'>
          <div className="vnd-block light-brand-background h-100">
            {
              !forgotPasswordMailSended ?
                <p>{translationService.translate("TR_FORGOT_PASSWORD_INFO")}</p> :
                <p>{translationService.translate("TR_FORGOT_INFO")}</p>
            }
            <div className='d-flex justify-content-center w-100'>
              <img className='w-25' src={coverImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> : null
}

export default withRouter(ForgotPassword)
