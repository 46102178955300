import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, withRouter, Redirect, useLocation } from "react-router-dom";
import ApiService from './Services/ApiService';
import ScrollTop from './Components/ScrollToTop/scrollTop';
import Auxiliary from './hoc/auxiliary/auxiliary';
import AlertService from './Services/AlertService';
import SemiPartnerLayout from './Layouts/SemiPartnerLayout/SemiPartnerLayout';
import uuid from 'react-uuid';
import { getLanguages, setLanguage } from './Store/Actions/language';
import { getTranslations } from './Store/Actions/translation';
import { addSpinner, removeSpinner } from './Store/Actions/spinner';
import PartnerLayout from './Layouts/PartnerLayout/PartnerLayout';
import SupporterLayout from './Layouts/SupporterLayout/SupporterLayout';
import UnAuthUsersLayout from './Layouts/UnAuthUsersLayout/UnAuthUsersLayout';
import Listeners from "./Listeners";
import TranslationService from './Services/translationService';
import { changeCallWaitingStatus, setIsCallInProcess } from './Store/Actions/chat';
import { setRtcGlobalConnection } from './Store/Actions/signalR';
import CookiesModal from './Components/CookiesModal/CookiesModal';
import {
  SUPPORTER_USER_TYPE_ID_KEY,
  ERROR_KEY,
  PARTNER_USER_TYPE_ID_KEY,
  SEMI_PARTNER_TYPE_ID_KEY,
  LANGUAGE_KEY,
  DEFAULT_LANGUAGE_KEY,
  SUPERVISOR_USER_TYPE_ID_KEY,
  PROJECT_NAME
} from './Constants/MainKeys';
import SpinnerComponent from './Components/Spinners/SpinnerComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorHandling from './ErrorHandling';

const App = (props) => {

  const dispatch = useDispatch();
  const { language, languages } = useSelector(state => state.language);
  const { isShowInboxTitleNotification, isShowMyClientsTitleNotification, activeUserData } = useSelector(state => state.chat);
  const { user } = useSelector(state => state.user);
  const globalConnection = useSelector(state => state.signalR.globalConnectionWithRedux);
  const { rtcGlobalConnection } = useSelector(state => state.signalR);
  const { spinners } = useSelector(state => state.spinner);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [timer, setTimer] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    localStorage.setItem("pathname", pathname);
  }, [pathname])

  useEffect(() => {
    var origTitle, animatedTitle;
    var currentState = false;
    function animateTitle(bool) {
      origTitle = PROJECT_NAME;
      animatedTitle = "New message !";
      if (bool && user) {
        setTimer(setInterval(startAnimation, 1000));
      } else {
        clearInterval(timer);
        setTimer(null);
        document.title = origTitle;
      }
    }
    function startAnimation() {
      document.title = currentState ? origTitle : animatedTitle;
      currentState = !currentState;
    }
    animateTitle((isShowInboxTitleNotification || isShowMyClientsTitleNotification) && user ? true : false);

  }, [isShowInboxTitleNotification, isShowMyClientsTitleNotification, user]);

  useEffect(() => {
    window.addEventListener('click', handleUserHangup);
    return () => {
      window.removeEventListener('click', handleUserHangup);
    };
  }, [globalConnection, rtcGlobalConnection]);

  const hangupCall = () => {
    if (!isConnectionOk() || !activeUserData || !localStorage.getItem("callId") || !localStorage.getItem("activeCallId") || !user) { return false; }
    globalConnection.invoke("HangupCall", localStorage.getItem("activeCallId"), +localStorage.getItem("callId"), 1, user.id).then(() => {
      rtcGlobalConnection && rtcGlobalConnection.closeRoom();
      dispatch(changeCallWaitingStatus(false));
      dispatch(setIsCallInProcess(false));
      dispatch(setRtcGlobalConnection(null));
      localStorage.removeItem("callId");
      localStorage.removeItem("activeCallId");
      localStorage.removeItem("callHangUp");
    }).catch(error => getFail(error));
  }

  const handleUserHangup = (event) => {
    if (
      event.target &&
      (event.target.id || event.target.className) &&
      (
        (event.target.id && event.target.id.trim() === "vnd_stop_stream") ||
        (event.target.className && typeof event.target.className === "string" && event.target.className.includes("stop"))
      )
    ) {
      if (!isConnectionOk()) {
        localStorage.setItem('callHangUp', true);
      } else {
        hangupCall();
      }
    }
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (localStorage.getItem(LANGUAGE_KEY) === null || !localStorage.getItem(LANGUAGE_KEY)) {
      localStorage.setItem(LANGUAGE_KEY, DEFAULT_LANGUAGE_KEY);
    }
    if (localStorage.getItem(LANGUAGE_KEY) && pathname.split("/")[1] !== localStorage.getItem(LANGUAGE_KEY)) {
      const isocode = pathname.split("/")[1];
      const isExist = languages.find(language => language.isocode2 === isocode);
      if (isExist) {
        localStorage.setItem(LANGUAGE_KEY, pathname.split("/")[1]);
        dispatch(setLanguage(pathname.split("/")[1]))
      }
    }
    dispatch(getLanguages());
    language && dispatch(getTranslations());
  }, []);

  useEffect(() => {
    const isocode = pathname.split("/")[1];
    if ((language && isocode) && (isocode !== language)) {
      changeIsocodeInUrl(isocode);
    }
  }, [pathname]);

  const changeIsocodeInUrl = (isocode) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getLanguages().then(response => {
      if (response && response.data) {
        const isExist = response.data.find(language => language.isocode2 === isocode);
        if (isExist) {
          localStorage.setItem(LANGUAGE_KEY, isocode);
          window.location.reload();
        } else returnToCurrentUrl();
      } else returnToCurrentUrl();
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const returnToCurrentUrl = () => {
    var urlArr = props.history.location.pathname.split("/");
    urlArr[1] = language;
    const newUrl = urlArr.join("/");
    props.history.push(newUrl);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const isConnectionOk = () => {
    if (globalConnection && globalConnection.state === "Connected") return true;
    else return false;
  }

  const isShowUnAuthUsersLayout = !user;

  const isShowSupporterLayout =
    (user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY)) ||
    (user && user.userTypeId === PARTNER_USER_TYPE_ID_KEY && pathname.includes("/dashboard"))

  const isShowPartnerLayout = user && user.userTypeId === PARTNER_USER_TYPE_ID_KEY && !pathname.includes("/dashboard");

  const isShowSemiPartnerLayout = user && user.userTypeId === SEMI_PARTNER_TYPE_ID_KEY;

  return translationService ? <Auxiliary>
    {
      isShowUnAuthUsersLayout ?
        <Auxiliary>
          <Route path={`/${language}`}>
            <UnAuthUsersLayout />
          </Route>
          <Route path="/" exact>
            <Redirect to={`/${language}`} />
          </Route>
          <ScrollTop />
        </Auxiliary>
        : null
    }
    {
      isShowSemiPartnerLayout ?
        <SemiPartnerLayout />
        : null
    }
    {
      isShowPartnerLayout ?
        <Auxiliary>
          <Route path={`/${language}`}>
            <PartnerLayout />
          </Route>
          <Route path="/" exact>
            <Redirect to={`/${language}/profile`} />
          </Route>
          <ScrollTop />
        </Auxiliary>
        : null
    }
    {
      isShowSupporterLayout ?
        <Auxiliary>
          <Route path={`/${language}`}>
            <SupporterLayout />
          </Route>
          <Route path="/" exact>
            {
              user && user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
                <Redirect to={`/${language}/profile`} />
                : <Redirect to={`/${language}/dashboard/groups`} />
            }
          </Route>
        </Auxiliary>
        : null
    }
    {
      user ?
        <Listeners />
        : null
    }
    <CookiesModal />
    <SpinnerComponent spinner={spinners} />
    <ToastContainer
      theme="dark"
      autoClose={5000}
    />
    <ErrorHandling />
  </Auxiliary > : null
}


export default withRouter(App)

