import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import DraggableComponent from "../../Components/DraggableComponent/DraggableComponent";
import LeftSidebarMenu from "../../Pages/AgentPages/dashboard/LeftSidebarMenu";
import UserChat from "../../Pages/AgentPages/dashboard/UserChat/UserChat";
import SupporterRouting from "../../Routing/SupporterRouting/SupporterRouting";
import { useSelector } from "react-redux";

const SupporterLayout = props => {
  const { pathname } = useLocation();
  const { spinners } = useSelector(state => state.spinner);
  const returnLeftMenuWidth = () => {
    switch (true) {
      case pathname.includes("/dashboard/groups"):
        return "group-side-width";
      case pathname.includes("/dashboard/settings"):
        return "group-side-width";
      default:
        return "";
    }
  }

  return (
    <div id='agent'>
      <div className="layout-wrapper d-lg-flex">
        <LeftSidebarMenu />
        <div className={`chat-leftsidebar position-relative ${returnLeftMenuWidth()} ${spinners.length ? "disabled" : ""}`}>
          <SupporterRouting />
        </div>
        <UserChat />
      </div>
      <DraggableComponent />
    </div>
  );
}

export default withRouter(SupporterLayout);

