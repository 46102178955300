import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY } from '../../../Constants/MainKeys';
import UseScroll from "./../../../Hooks/useScroll"
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import Parser from "html-react-parser"
import { useState } from 'react';
import { addSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import TranslationService from '../../../Services/translationService';
import moment from 'moment';
import MainService from '../../../Services/mainService';

const SetPageData = ({ pagecontent, translationService }) => {
  const scrollY = UseScroll();
  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
      behavior: "smooth"
    });
  }

  const setHeight = () => {
    let scrollY = UseScroll();
    return scrollY > 170 && scrollY + 500 + document.body.querySelector("footer").offsetHeight < document.body.offsetHeight
  };

  return (
    <div className="container">
      <div className='row'>
        <div className='col-12'>
          <h1 className="vnd--upp-text-22 mb-2">
            {
              pagecontent && pagecontent.firstContent && pagecontent.firstContent.title ?
                pagecontent.firstContent.title
                : null
            }
          </h1>
          <div className='vnd--m-block light-brand-background'>
            <small className="d-block">Relamy Inc.</small>
            <small className="d-block">{translationService.translate("TR_UPDATED")} :
              {
                moment(MainService.convertUTCDateToLocalDate(new Date(pagecontent && pagecontent.firstContent && pagecontent.firstContent.updateDate ? pagecontent.firstContent.updateDate : null))).format(' LL HH:mm')
              }
            </small>
          </div>
          <hr />
        </div>
      </div>
      <div className='row'>
        {
          pagecontent && pagecontent.firstContent && pagecontent.firstContent.fullContent ?
            Parser(pagecontent.firstContent.fullContent)
            : null
        }
        <div className='col-md-4'>
          <div className={`cx--contact-info-wrapper mt-4 ${setHeight() ? "list-fixed" : ""}`}>
            <h2 className="vnd--upp-text-20 mb-3">{translationService.translate("TR_TABLE_OF_CONTENTS")}</h2>
            <ol className="vnd--fixed-list-menu list-unstyled mt-2">
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("introduction")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_INTRODUCTION")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("acceptableUse")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_ACCEPTABLE_USE")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("privacy")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_PRIVACY_POLICY")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("intellectualProperty")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_INTELLECTUAL_PROPERTY")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("userAccounts")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_USER_ACCOUNTS")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("termination")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_TERMINATION")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("limitationOfLiability")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_LIMITATION_LIABILITY")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("changesToTerms")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_CHANGES_TERMS")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("contactInformation")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_CONTACT_INFORMATION")}</Link>
              </li>
              <li className='cursor-pointer my-1' onClick={() => scrollToCurrentPosition("conclusion")}>
                <Link to="#" className='text-underline text-uppercase'>{translationService.translate("TR_CONCLUSION")}</Link>
              </li>
            </ol>
          </div>
        </div>

      </div>
    </div>
  )
}

const TermsAndConditions = (props) => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const { user } = useSelector(state => state.user);
  const [pagecontent, setPageContent] = useState(null);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [language]);

  useEffect(() => {
    getPageContent();
  }, [language])

  const setPage = () => {
    if (user) {
      if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
        return <aside className="right-section bg-white">
          <section className="dashboard top-section ">
            <SetPageData
              pagecontent={pagecontent}
              translationService={translationService}
            />
          </section>
        </aside>
      }
    } else {
      return (
        <section className="h-section bg-white">
          <SetPageData
            pagecontent={pagecontent}
            translationService={translationService}
          />
        </section>
      )
    }
  }

  const getPageContent = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPageByName("terms")
      .then((response) => {
        if (response && response.data) {
          const data = { ...response.data };
          setPageContent(data);
        }
        extractSpinner(spinnerId);
      })
      .catch((error) => { getFail(error, spinnerId) });
  };


  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? <>{setPage()}</> : null
}
export default withRouter(TermsAndConditions)
