import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import loginImage from "../../../assets/images/signup-image.jpg";
import Input from '../../../Components/Inputs/Input';
import MainService from '../../../Services/mainService';
import uuid from 'react-uuid';
import { userRegistration } from '../../../Store/Actions/user';
import ActionButton from '../../../Components/Buttons/ActionButton';
import TranslationService from '../../../Services/translationService';
import Parser from 'html-react-parser';
import microsoft from '../../../assets/images/auth-services/microsoft.svg';
import apple from '../../../assets/images/auth-services/apple.svg';
import coverImage from '../../../assets/images/3d-images/registration.png';
import { fieldLengths } from '../../../Constants/MainKeys';
import { event } from 'jquery';
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleAuth from './Components/GoogleAuth';
import FacebookAuth from './Components/FacebookAuth';

const spinnerId = uuid();
const SignUp = (props) => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    user && props.history.push(language);
  }, [user, language, props.history])

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    setMismatchPasswords(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    switch (fieldName) {
      case "email":
        changeIsinvalidFieldName(isValid, setIsInvalidEmail)
        break;
      case "password":
        changeIsinvalidFieldName(isValid, setIsInvalidPassword)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onCheckBoxChange = (event) => {
    setIsAgree(event.target.checked);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      email.trim().length &&
      password.trim().length &&
      !isInvalidEmail &&
      !isInvalidPassword
    ) {
      const form = {
        email,
        password,
        userRegistrationType: 1
      }
      dispatch(userRegistration(form, spinnerId, props.history))
    }
  }

  return translationService ? <section className="section h-section">
    <div className="container">
      <div className='row'>
        <div className='col-lg-6 d-lg-block d-none'>
          <div className="vnd-block light-brand-background h-100 position-relative">
            <div className='vnd-blur-block'>
              <div className="vnd-blur-block-text-wrapper">
                <p className="vnd--upp-text-22">{Parser(translationService.translate("TR_SIGNUP_INFO_TITLE"))}</p>
                <hr />
                <q className='position-relative font-italic z-index-10 font-size-16'>{Parser(translationService.translate("TR_SIGNUP_INFO"))}</q>
              </div>
            </div>
            <img className='vnd--auth-image' src={coverImage} alt="" />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className="vnd-block white-background vnd-border">
            <div className='mb-4'>
              <h5 className="vnd--text-50">{Parser(translationService.translate("TR_CREATE_ACCOUNT"))}</h5>
            </div>
            <form id="signinForm" onSubmit={onSubmit}>
              <Input
                id="email"
                htmlFor="email"
                type="email"
                name="email"
                value={email}
                labelValue={`${translationService.translate("TR_EMAIL")} *`}
                placeholder={`${translationService.translate("TR_EMAIL")}`}
                required={true}
                inputClassName={`${(isInvalidSubmit && !email.trim()) || isInvalidEmail ? "error-border" : ""}`}
                onChange={(event) => onChange(event, setEmail, fieldLengths.maxLength_120)}
                onBlur={() => checkFieldsValidation(email, "email")}
              />
              <Input
                id="password"
                htmlFor="password"
                type="password"
                name="password"
                value={password}
                labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                placeholder={`${translationService.translate("TR_PASSWORD")}`}
                autoComplete="new-password"
                inputClassName={`${(isInvalidSubmit && !password.trim()) || mismatchPasswords || isInvalidPassword ? "error-border" : ""}`}
                required={true}
                onChange={(event) => onChange(event, setPassword, fieldLengths.maxLength_100)}
                onBlur={() => checkFieldsValidation(password, "password")}
              />
              {
                mismatchPasswords ?
                  <div className='my-4'><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                  : null
              }
              {
                isInvalidConfirmPassword || isInvalidPassword ?
                  <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                  : null
              }
              <div className='d-flex'>
                <CheckBoxInput
                  id="isAgree"
                  htmlFor="isAgree"
                  labelValue=""
                  checked={isAgree}
                  onChange={onCheckBoxChange}
                />
                <label htmlFor='isAgree' className='cursor-pointer font-size-14'>
                  {translationService.translate("TR_I_AGREE_TO_THE")}&nbsp;
                  <Link to={`/${language}/terms-and-conditions`} target="_blank">{translationService.translate("TR_TERMS")}</Link>
                  &nbsp;
                  {translationService.translate("TR_AND")}
                  &nbsp;
                  <Link to={`/${language}/privacy-policy`} target="_blank">{translationService.translate("TR_PRIVACY_POLICY")}</Link>.
                </label>
              </div>
              <ActionButton
                type="submit"
                name={translationService.translate("TR_SIGNUP")}
                className={`my-4 vnd-primary-btn vnd-btn w-100 ${!isAgree ? "disabled" : ""}`}
                disabled={!isAgree}
                spinnerId={spinnerId}
              />
            </form>
            <small className='font-size-14'>{translationService.translate("TR_HAVE_ACCOUNT")} ? <Link to={`/${language}/sign-in`}>{translationService.translate("TR_SIGNIN")}</Link></small>
            {/* <div className='d-flex align-items-center my-4'>
              <hr className='flex-1' />
              <small className='mx-3 d-block text-uppercase font-bold'>You can also sign up</small>
              <hr className='flex-1' />
            </div>
            <div className='vnd--alternative-auth-section'>
              <GoogleOAuthProvider clientId='841648827700-0llvg91hc9ep3enke2o4ts6ib1fr7d0i.apps.googleusercontent.com'>
                <GoogleAuth />
              </GoogleOAuthProvider>
              <FacebookAuth />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </section> : null
}

export default withRouter(SignUp)