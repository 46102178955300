import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from 'react-router-dom';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { setActiveGroupName, setActiveSection, setPartnerId } from '../../../Store/Actions/main';
import { ACTIVE_SECTION_KEY, LANGUAGE_KEY, PARTNER_USER_TYPE_ID_KEY, PROJECT_NAME, SPINNER_COLOR } from '../../../Constants/MainKeys';
import { logout } from '../../../Store/Actions/user';
import { setconversationNameInOpenChat } from '../../../Store/Actions/layout';
import { setActiveUserData } from '../../../Store/Actions/chat';
import { setLanguage } from '../../../Store/Actions/language';
import { getTranslations } from '../../../Store/Actions/translation';
import TranslationService from '../../../Services/translationService';
import PuffLoader from "react-spinners/PuffLoader";
import logoSvg from "../../../assets/logo/logo-big.png";


const useOutsideAlerter = (ref, setIsShowNavbar, setIsShowFlags, setIsShowUserPopup) => {

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowFlags(false);
        setIsShowUserPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = (props) => {
  const { pathname } = useLocation();

  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.language.language);
  const languages = useSelector(state => state.language.languages);
  const translations = useSelector(state => state.translation.translations);
  const { isShowFlagSpinner } = useSelector(state => state.spinner);

  const [translationService, setTranslationService] = useState(null);
  const activeSection = useSelector(state => state.main.activeSection);
  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const [isShowFlags, setIsShowFlags] = useState(false);
  const [isShowUserPopup, setIsShowUserPopup] = useState(false);

  useOutsideAlerter(
    wrapperRef,
    setIsShowNavbar,
    setIsShowFlags,
    setIsShowUserPopup
  );

  useEffect(() => {
    localStorage.removeItem(ACTIVE_SECTION_KEY);
    setActiveSectionName("home");
  }, [])

  const closeLanguageBlock = () => {
    var checkLanguage = document.getElementById("checkLanguage");
    var checkLanguageItem = document.getElementById("checkLanguageItem");
    checkLanguage && checkLanguage.click();
    checkLanguageItem && checkLanguageItem.click();
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const logOut = () => {
    props.history.push(language);
    dispatch(logout());
    dispatch(setActiveGroupName(null));
    dispatch(setconversationNameInOpenChat("group"));
    dispatch(setPartnerId(null));
    dispatch(setActiveUserData(null));
  }

  const changeLanguage = isocode => {
    if (!isocode || isocode === language) {
      closeLanguageBlock();
      return false;
    }
    localStorage.setItem(LANGUAGE_KEY, isocode);
    setLanguageHandler(isocode);
    const newPath = pathname.toString().replace(props.match.path.toString(), `/${isocode}`);
    props.history.push(newPath.toString());
    closeLanguageBlock();
    dispatch(getTranslations(true));
  }

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  return translationService ? <header className="vnd-header vnd-box-shadow">
    <div className="container">
      <nav className="vnd-navbar vnd-navbar-expand-lg vnd-light-navbar">
        <div className="vnd-navbar-brand cursor-pointer" onClick={() => props.history.push(`/${language}/become-partner/step-1`)}>
          <img className='w-100' src={logoSvg} alt={PROJECT_NAME} />
        </div>
        <ul className="vnd-navbar-nav ms-auto d-lg-flex gap-3">
          <Auxiliary>
            <li className="vnd-nav-item d-flex align-items-center">
              <div className="current-language">
                {
                  isShowFlagSpinner ?
                    <div className='d-flex'>
                      <PuffLoader size={30} color={SPINNER_COLOR} />
                    </div>
                    : <>
                      {
                        languages && languages.length ?
                          languages.map(languageItem => {
                            if (languageItem.isocode2 === language) {
                              return <div key={languageItem.id}><img src={languageItem.flagPath} alt="flag" /></div>
                            }
                          })
                          : null
                      }
                      <input id="checkLanguageItem" type="checkbox" checked={isShowFlags} onChange={() => setIsShowFlags(!isShowFlags)} />
                    </>
                }
                <ul className="falges">
                  {
                    languages && languages.length ?
                      languages.map(languageItem => {
                        return <li key={languageItem.id}>
                          <div className="flag" rel={languageItem.isocode2}>
                            <img
                              src={languageItem.flagPath}
                              alt={languageItem.isocode2}
                              onClick={() => changeLanguage(languageItem.isocode2)}
                            />
                          </div>
                        </li>
                      })
                      : null
                  }
                </ul>
              </div>
            </li>

            <li className=''>
              <Link
                to='#'
                className="link-button vnd-btn vnd-primary-btn vnd-small-btn"
                onClick={() => {
                  dispatch(logout())
                }}
              >
                {translationService.translate("TR_SIGNOUT")}
              </Link>
            </li>
          </Auxiliary>
        </ul>

      </nav>
    </div>
  </header> : null
}

export default withRouter(Header)