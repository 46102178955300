import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import TranslationService from '../../../Services/translationService';
import { Card } from 'react-bootstrap';
import CustomCollapse from './CustomCollapse';
import { useDispatch } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';
import NoData from '../../../Components/NoData/NoData';
import {
  getScriptGroupsByCountry,
  getScriptGroupsWithoutCountry,
  getScripts,
  setScriptGroups,
  setScripts,
  setSelectedCountry,
  setSelectedGroup,
  setSelectedScript,
  toggleGroupsByCountry
} from '../../../Store/Actions/chat';
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput';
import { fieldLengths } from '../../../Constants/MainKeys';

export default function Scripts(props) {

  const { activeCollapseId, setActiveCollapseId } = props;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { translations } = useSelector(state => state.translation);
  const [groupName, setGroupName] = useState("");
  const [scriptName, setScriptName] = useState("");
  const [translationService, setTranslationService] = useState(null);

  const {
    countries,
    selectedCountry,
    scriptGroups,
    selectedGroup,
    scripts,
    isDisabledScripts,
    scriptGroupsPagination,
    scriptsPagination,
    groupsByCountry
  } = useSelector(state => state.chat);
  const {
    partnerId,
  } = useSelector(state => state.main);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    let timer = setTimeout(() => {
      getGroups(1);
    }, 1000)
    return () => clearTimeout(timer);
  }, [groupName])

  useEffect(() => {
    let timer = setTimeout(() => {
      _getScripts(selectedGroup, 1);
    }, 1000)
    return () => clearTimeout(timer);
  }, [scriptName])

  const getGroups = (currentPage, isAddData) => {
    const data = {
      partnerId: user.partnerId ? user.partnerId : partnerId,
      prefix: groupName,
      pageNumber: currentPage,
      pageSize: fieldLengths.pageSize_10,
      isAddData,
    }
    if (selectedCountry) {
      data.countryId = selectedCountry.id;
      dispatch(getScriptGroupsByCountry(data));
    } else {
      dispatch(getScriptGroupsWithoutCountry(data));
    }
  }

  const _getScripts = (group, currentPage, isAddData) => {

    if (!group && !selectedGroup) {
      return false;
    }
    if ((selectedGroup && group && selectedGroup.id !== group.id) || !selectedGroup) {
      dispatch(setSelectedGroup(group));
    }
    if (!isAddData) {
      dispatch(setScripts([]));
    }
    setActiveCollapseId(7);
    const data = {
      groupId: group.id,
      partnerId: user.partnerId ? user.partnerId : partnerId,
      prefix: scriptName,
      pageNumber: currentPage,
      pageSize: fieldLengths.pageSize_10,
      isAddData
    }
    dispatch(getScripts(data));
  }

  const onCheckBoxChange = (event) => {
    setGroupName("");
    setScriptName("")
    dispatch(setSelectedCountry(null));
    dispatch(setScriptGroups([]));
    dispatch(setSelectedGroup(null));
    dispatch(setScripts([]));

    if (!event.target.checked) {
      const data = {
        partnerId: user.partnerId ? +user.partnerId : partnerId,
        prefix: "",
        pageNumber: 1,
        pageSize: fieldLengths.pageSize_10,
      };
      dispatch(getScriptGroupsWithoutCountry(data))
    }
    setActiveCollapseId(null);
    dispatch(toggleGroupsByCountry(event.target.checked));
  }


  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  return (translationService ?
    <div className={`${isDisabledScripts ? "disabled" : ""}`}>
      <hr />
      <label className="mb-1 form-label capitalize">{translationService.translate("TR_SCRIPT")}</label>
      <CheckBoxInput
        id="groupsByCountry"
        htmlFor="groupsByCountry"
        blockClassName="mb-3"
        labelValue={translationService.translate("TR_GROUPS_BY_COUNTRY")}
        checked={groupsByCountry}
        onChange={onCheckBoxChange}
      />

      {
        countries && countries.length && groupsByCountry ?
          <>
            <Card style={{ maxHeight: "600px", overflowY: "hidden", overflowX: "auto" }} className={`overflow-hidden mb-0 `}>
              <CustomCollapse
                title={selectedCountry ? selectedCountry.name : translationService.translate("TR_COUNTRIES")}
                iconclassName="ri-attachment-line"
                isOpen={activeCollapseId === 5}
                toggleCollapse={() => setActiveCollapseId(activeCollapseId === 5 ? null : 5)}
              >
                <div className='d-flex flex-wrap'>
                  {
                    countries.map(country => {
                      return <div
                        key={country.id}
                        className="vnd-added-item-container max-h-initial cursor-pointer"
                        onClick={() => {
                          if (selectedCountry && selectedCountry.id === country.id) { return false; }
                          const data = {
                            partnerId: user.partnerId ? user.partnerId : partnerId,
                            countryId: country.id,
                            prefix: "",
                            pageNumber: 1,
                            pageSize: fieldLengths.pageSize_10
                          }
                          dispatch(setSelectedCountry(country));
                          dispatch(setScriptGroups([]));
                          dispatch(setSelectedGroup(null));
                          dispatch(setScripts([]));
                          dispatch(getScriptGroupsByCountry(data));
                          setActiveCollapseId(6);
                        }}
                      >
                        <div className={`vnd--primary-bordered-block word-break-break-word align-items-center d-flex px-3 ${selectedCountry && selectedCountry.id === country.id ? "active" : ""}`} >
                          <div className='mr-3'>
                            <ReactCountryFlag countryCode={country.isoCode2} svg />
                          </div>
                          <span className="font-size-16">{country.name}</span>
                        </div>
                      </div>
                    })
                  }
                </div>
              </CustomCollapse>
            </Card>
          </>
          : null
      }
      {
        <>
          <Card
            style={{ maxHeight: "500px", overflowY: "hidden", overflowX: "auto" }}
            className={`mb-0 ${isDisabledScripts ? "disabled" : ""}`}
          >
            <CustomCollapse
              title={selectedGroup ? selectedGroup.name : translationService.translate("TR_GROUPS")}
              iconclassName="ri-attachment-line"
              isOpen={activeCollapseId === 6}
              toggleCollapse={() => setActiveCollapseId(activeCollapseId === 6 ? null : 6)}
            >
              <input
                id='name'
                name="name"
                type="text"
                className='mb-2'
                placeholder={`${translationService.translate("TR_SEARCH")}`}
                disabled={isDisabledScripts}
                value={groupName}
                onChange={event => onChange(event, setGroupName, fieldLengths.maxLength_100)}
              />

              <div className='form-group' >
                <div className="vnd-added-item-container max-h-initial">
                  {
                    scriptGroups.length ? scriptGroups.map(group => {
                      return <div
                        key={group.id}
                        className={`vnd-added-item-wrapper d-flex px-3 py-2 cursor-pointer`}
                        onClick={() => _getScripts(group, 1)}
                      >
                        <p className="font-size-16 max-line-2 word-break-break-word" title={group.name}>{group.name}</p>
                      </div>
                    }) : <div className='d-flex justify-content-center w-100'>
                      <NoData width={150} height={150} textClassName="font-size-14" />
                    </div>
                  }
                </div>
              </div>
              {
                scriptGroups.length && scriptGroupsPagination && scriptGroupsPagination.CurrentPage < scriptGroupsPagination.TotalPages ?
                  <>
                    <hr className='my-2' />
                    <div className='d-flex justify-content-center'>
                      <h5 className='font-size-16 my-users-title active-users-blobk-title d-flex align-items-center py-2' onClick={() => {
                        getGroups(scriptGroupsPagination.CurrentPage + 1, true)
                      }}>
                        {translationService.translate("TR_MORE")}
                      </h5>
                    </div>
                  </>
                  : null
              }

            </CustomCollapse>
          </Card>
        </>
      }
      {
        <>
          <Card style={{ maxHeight: "500px", overflowY: "hidden", overflowX: "auto" }}>
            <CustomCollapse
              title={translationService.translate("TR_SCRIPTS")}
              iconclassName="ri-attachment-line"
              isOpen={activeCollapseId === 7}
              toggleCollapse={() => setActiveCollapseId(activeCollapseId === 7 ? null : 7)}
            >
              <input
                id='name'
                name="name"
                type="text"
                className='mb-2'
                placeholder={`${translationService.translate("TR_SEARCH")}`}
                disabled={isDisabledScripts}
                value={scriptName}
                onChange={event => onChange(event, setScriptName, fieldLengths.maxLength_100)}
              />
              <div className='form-group'>
                <div className="vnd-added-item-container max-h-initial">
                  {
                    scripts.length ? scripts.map(script => {
                      return <div
                        key={script.id}
                        className={`vnd-added-item-wrapper d-flex px-3 py-2`}

                      >
                        <p className="font-size-16 max-line-2 word-break-break-word" title={script.script}>{script.script}</p>
                        <span>
                          <i
                            className="ri-send-plane-line font-size-20 vnd--styled-icon vnd--styled-secondary-icon cursor-pointer content-icon d-block ml-2"
                            title={translationService.translate("TR_SEND")}
                            onClick={() => dispatch(setSelectedScript(script))}
                          />
                        </span>
                      </div>
                    }) : <div className='d-flex justify-content-center w-100'>
                      <NoData width={150} height={150} textClassName="font-size-14" />
                    </div>
                  }
                </div>
              </div>
              {
                scripts.length && scriptsPagination && scriptsPagination.CurrentPage < scriptsPagination.TotalPages ?
                  <>
                    <hr className='my-2' />
                    <div className='d-flex justify-content-center'>
                      <h5 className='font-size-16 my-users-title active-users-blobk-title d-flex align-items-center py-2' onClick={() => {
                        _getScripts(selectedGroup, scriptsPagination.CurrentPage + 1, true)
                      }}>
                        {translationService.translate("TR_MORE")}
                      </h5>
                    </div>
                  </>
                  : null
              }
            </CustomCollapse>
          </Card>
        </>
      }
      <hr />
    </div> : null
  )
}
