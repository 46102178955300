import React, { useState, useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY, SPINNER_COLOR, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY } from "../../../Constants/MainKeys";
import AlertService from "../../../Services/AlertService";
import ApiService from "../../../Services/ApiService";
import {
  addSpinner,
  removeSpinner,
} from "../../../Store/Actions/spinner"
import Parser from "html-react-parser"
import { useSelector } from "react-redux"
import TranslationService from "../../../Services/translationService"
import { Accordion } from 'react-bootstrap'
import { getPageContentById, setPageContentId } from "../../../Store/Actions/pageContent"
import { PuffLoader } from "react-spinners"
import NoData from './../../../Components/NoData/NoData'
import { AiOutlineLeft } from "react-icons/ai"
import useWindowSize from "../../../Hooks/useWindowSize";

const Help = () => {

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const { translations } = useSelector((state) => state.translation);
  const { user } = useSelector(state => state.user);
  const { language } = useSelector((state) => state.language);
  const { pageContent, pageContentId, isShowPageContentSpinner } = useSelector((state) => state.pageContent);
  const windowSize = useWindowSize();

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPage();
  }, []);

  useEffect(() => {
    if (!pageContentId) { return false; }
    dispatch(getPageContentById(pageContentId));
    if (pathname.includes("/dashboard")) {
      scrollChatLeftsidebar()
    } else {
      scrollMenuBlock();
    }
  }, [language, pageContentId]);

  const scrollChatLeftsidebar = () => {
    const menuBlock = document.querySelector('.menu-block');
    const scrollElement = document.querySelector('.chat-leftsidebar');
    if (scrollElement) {
      if (windowSize && windowSize.width > 768) {
        scrollElement.scrollTop = 0;
      } else {
        if (menuBlock) {
          const height = menuBlock.offsetHeight;
          scrollElement.scrollTop = height + 100;
        }
      }
    }
  }

  const scrollMenuBlock = () => {
    const menuBlock = document.querySelector('.menu-block');
    if (menuBlock) {
      if (windowSize && windowSize.width > 768) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const height = menuBlock.offsetHeight;
        window.scrollTo({ top: height, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    replaceUrl();
  }, [document.querySelectorAll(".vnd-api-doc-section")])

  const getPage = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPageByName("help")
      .then((response) => {
        if (response && response.data) {
          const data = { ...response.data };
          if (data && data.contents && data.contents.length) {
            dispatch(setPageContentId(data.contents[0].id));
          }
          setPageData(response.data);
        }
        extractSpinner(spinnerId);
      })
      .catch((error) => getFail(error, spinnerId));
  };

  const replaceUrl = () => {
    document.querySelectorAll('a').forEach(x => {
      if (x.href.includes('_langIso')) {
        x.href = x.href.replace('_langIso', language);
      }
    });
  }

  const setHelpPageContainer = () => {
    return (
      <div className="container">
        <div className="row mt-4 position-relative">
          <div className={`col-md-3 col-12 ${pathname.includes("dashboard") && windowSize && windowSize.width < 1200 ? "mb-5" : ""}`}>
            <div className="row">
              {
                user && pathname.includes("dashboard") ?
                  null
                  : <div className="col-12">
                    <div className="vnd--aside-title-block">
                      <div className="backspace">
                        <span
                          className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                          onClick={() => window.history.back()}
                        >
                          <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                        </span>
                      </div>
                      <span className="slash">|</span>
                      <div className="">
                        <p className="vnd--upp-text-20">{translationService.translate("TR_HELP")}</p>
                      </div>
                    </div>
                  </div>
              }
              <div className="col-12 menu-block">
                <div className="vnd--m-block">
                  <ul className="list-unstyled text-left vnd-accordion-left-menu">
                    {
                      pageData ?
                        <LeftMenu data={pageData} />
                        : null
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-md-9 col-12 help-right-menu ${pathname.includes("dashboard") && windowSize && windowSize.width < 1200 ? "mb-5" : ""}`}>
            {
              isShowPageContentSpinner ?
                <div className="step-spinner-layer spinner-layer position-relative" style={{ height: "500px", top: 0, zIndex: "1" }}>
                  <div className="sweet-loading">
                    <PuffLoader size={80} color={SPINNER_COLOR} />
                  </div>
                </div>
                : pageContent ? (
                  <>
                    <h2 className={`vnd--upp-text-20 mb-4 ${user && pathname.includes("dashboard") ? "mt-4" : ""}`}>
                      {translationService.translate(pageContent.title)
                        ? translationService.translate(pageContent.title)
                        : pageContent.title}
                    </h2>
                    {Parser(pageContent.fullContent)}
                  </>
                ) : <div className="d-flex align-items-center justify-content-center" style={{ height: "500px", top: 0, zIndex: "1" }}>
                  <NoData height={200} width={200} />
                </div>
            }
          </div>
        </div>
      </div >
    )
  }

  const setHelpPage = () => {
    if (!translationService) { return false; }
    if (user) {
      if (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) {
        return (
          <div className='vnd-list-container'>
            <aside>
              <div className="px-4 pt-4 left-bar-header">
                <h4 className="mb-4 vnd--upp-text-20">{translationService.translate("TR_HELP")}</h4>
              </div>
              <hr className='my-2' />
            </aside>
            <div className="px-2 mb-auto">
              {setHelpPageContainer()}
            </div>
          </div>
        )
      }
      if (user.userTypeId === PARTNER_USER_TYPE_ID_KEY) {
        if (!pathname.includes("dashboard")) {
          return <aside className="right-section">
            <section className="dashboard top-section">
              {setHelpPageContainer()}
            </section>
          </aside>

        } else {
          return <div className='vnd-list-container'>
            <aside>
              <div className="px-4 pt-4 left-bar-header">
                <h4 className="mb-4 vnd--upp-text-20">{translationService.translate("TR_HELP")}</h4>
              </div>
              <hr className='my-2' />
            </aside>
            <div className="px-2 mb-auto">
              {setHelpPageContainer()}
            </div>
          </div>
        }
      }
    } else {
      return (
        <section className="top-section">{setHelpPageContainer()}</section>
      )
    }
  }

  const setSpinner = useCallback((spinner) => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback((spinner) => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ? (
    setHelpPage()
  ) : null;
};

const LeftMenuContent = ({ contents }) => {

  const dispatch = useDispatch();
  const translations = useSelector((state) => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const { pageContentId } = useSelector((state) => state.pageContent);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return (translationService ? <>
    {
      contents && contents.length ?
        contents.map((item) => {
          return <div key={item.id} className="accordion-item">
            <span className={`without-arrow d-block accordion-header ${pageContentId === item.id ? "active-content" : ""}`}>
              <button
                className={`accordion-button collapsed capitalize`}
                onClick={() => {
                  dispatch(setPageContentId(item.id));
                }}
              >
                {translationService.translate(item.title) ? translationService.translate(item.title) : item.title}
              </button>
            </span>
          </div>
        })
        : null
    }
  </> : null
  )
}

const LeftMenuChildren = ({ children }) => {
  const translations = useSelector((state) => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return (translationService ? <>
    {
      children && children.length ?
        children.map(item => {
          return <Accordion.Item key={item.id} eventKey={item.id}>
            <Accordion.Header className="capitalize">{translationService.translate(item.pageTitle) ? translationService.translate(item.pageTitle) : item.pageTitle}</Accordion.Header>
            <Accordion.Body className="pl-1 pt-0">
              <LeftMenuContent contents={item.contents} />
              <LeftMenuChildren children={item.children} />
            </Accordion.Body>
          </Accordion.Item>
        })
        : null
    }
  </> : null
  )
}

const LeftMenu = ({ data }) => {
  return (
    <Accordion defaultActiveKey={[]} alwaysOpen>
      <LeftMenuContent contents={data?.contents} />
      <LeftMenuChildren children={data?.children} />
    </Accordion>
  )
}

export default withRouter(Help)