import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import loginImage from "../../../assets/images/login-image.jpeg";
import Input from '../../../Components/Inputs/Input';
import { setActiveSection } from '../../../Store/Actions/main';
import { userLogin } from '../../../Store/Actions/user';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import uuid from 'react-uuid';
import ActionButton from '../../../Components/Buttons/ActionButton';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import MainService from '../../../Services/mainService';
import ApiService from '../../../Services/ApiService';
import AlertService from '../../../Services/AlertService';
import TranslationService from './../../../Services/translationService';


const buttonSpinnerId = uuid();
const BecomeSupporterViaUrl = (props) => {

  // via Email

  const query = new URLSearchParams(props.location.search);
  const key = query.get('key');
  const email = query.get('email');

  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [partnerId, setPartnerId] = useState(null);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    setIsInvalidPassword(false);
    setIsInvalidConfirmPassword(false);
    setMismatchPasswords(false);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (password.trim().length && confirmPassword.trim().length && (password !== confirmPassword)) {
        changeIsinvalidFieldName(false, setMismatchPasswords);
      } else {
        changeIsinvalidFieldName(true, setMismatchPasswords);
      }
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(setActiveSection("home"));
    if (!key) {
      props.history.push(`/${language}`)
    } else {
      checkSupporterByKey(key)
    }
  }, [])

  const checkSupporterByKey = (key) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.checkSupporterByKey(key).then((response) => {
      setPartnerId(response.partnerId);
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!password.trim().length || !confirmPassword.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(buttonSpinnerId)
      const form = {
        email,
        key,
        password: btoa(password),
        confirmPassword: btoa(confirmPassword),
        partnerId
      }
      ApiService.existingUserBecomeASupporter(form).then(() => {
        AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        const data = {
          username: email.trim(),
          password: password.trim()
        }
        dispatch(userLogin(data, buttonSpinnerId, props.history));
      }).catch(error => getFail(error, buttonSpinnerId));
    }
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
    props.history.push(`/${language}`);
  };

  return translationService ? <section className="h-section section">
    <div className="container">
      <div className="vnd-box-shadow vnd-block">
        <div className="d-lg-flex">
          <div className="vnd-form-card card-1">
            <div className="d-flex align-items-center mb-4">
              <h5 className="vnd-section-title">{translationService.translate("TR_BECOME_SUPPORTER")}</h5>
            </div>
            <form onSubmit={onSubmit}>
              <Input
                id="email"
                htmlFor="email"
                type="email"
                name="email"
                labelValue={translationService.translate("TR_EMAIL")}
                placeholder={translationService.translate("TR_EMAIL")}
                disabled={true}
                value={email}
                onChange={() => { }}
              />
              <Input
                id="password"
                htmlFor="password"
                type="password"
                name="password"
                value={password}
                labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                placeholder={`${translationService.translate("TR_PASSWORD")}`}
                autoComplete="new-password"
                inputClassName={`${(isInvalidSubmit && !password.trim() || mismatchPasswords || isInvalidPassword) ? "error-border" : ""}`}
                required={true}
                onChange={(event) => onChange(event, setPassword, fieldLengths.maxLength_100)}
                onBlur={() => checkFieldsValidation(password, "password")}
              />
              <Input
                id="confirmPassword"
                htmlFor="confirmPassword"
                type="password"
                value={confirmPassword}
                name="confirmPassword"
                labelValue={`${translationService.translate("TR_CONFIRM")} ${translationService.translate("TR_PASSWORD")} *`}
                placeholder={`${translationService.translate("TR_CONFIRM")} ${translationService.translate("TR_PASSWORD")}`}
                autoComplete="new-password"
                inputClassName={`${(isInvalidSubmit && !confirmPassword.trim() || mismatchPasswords || isInvalidConfirmPassword) ? "error-border" : ""}`}
                required={true}
                onChange={(event) => onChange(event, setConfirmPassword, fieldLengths.maxLength_100)}
                onBlur={() => checkFieldsValidation(confirmPassword, "confirmPassword")}
              />
              {
                mismatchPasswords ?
                  <div className='my-4'><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                  : null
              }
              {
                isInvalidConfirmPassword || isInvalidPassword ?
                  <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                  : null
              }
              <ActionButton
                type="submit"
                name={translationService.translate("TR_CREATE")}
                className="vnd-btn vnd-primary-btn my-3"
                spinnerId={buttonSpinnerId}
              />
            </form>
          </div>
          <div
            className="vnd-image-card card-2 vnd-bkg-cover-img vnd-image-dark-layer"
            style={{ backgroundImage: `url(${loginImage})` }}
          >
            <div className="vnd-blur-block">
              <div className="vnd-blur-block-text-wrapper">
                <p className="text-title">{translationService.translate("TR_BECOME_SUPPORTER_INFO_TITLE")}</p>
                <p>{translationService.translate("TR_BECOME_SUPPORTER_INFO")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> : null
}

export default withRouter(BecomeSupporterViaUrl)
