import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, NUMBER_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import CloseSvg from "./../../../assets/icons/close.svg";
import CheckSvg from "./../../../assets/icons/check_.svg";
import ApiService from './../../../Services/ApiService';
import * as moment from "moment";
import MainService from '../../../Services/mainService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../Services/translationService';
import Input from "./../../../Components/Inputs/Input";
import ActionButton from '../../../Components/Buttons/ActionButton';
import TransactionModal from './TransactionModals/TransactionModal';
import ContactUs from './Components/ContactUs';
import PaymentProcess from './Components/PaymentProcess';
import Parser from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import { removeUserFiled } from '../../../Store/Actions/chat';
import { AiOutlineCheck, AiOutlineLeft } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx"


const buttonSpinnerId = uuid();
const additionalButtonSpinnerId = uuid();

const Subscriptions = (props) => {
  const { pathname } = useLocation()
  const assitentsMaxCount = 10000;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);

  const [partnerLastSubscription, setPartnerLastSubscription] = useState(null);
  const [paymentPackageViewResponse, setPaymentPackageViewResponse] = useState(null);
  const [sameTimeSupportersCountsCosts, setSameTimeSupportersCountsCosts] = useState([]);
  const [translationService, setTranslationService] = useState(null);
  const [paymentPackageMonthResponses, setPaymentPackageMonthResponses] = useState([]);
  const [assitentsCount, setAssitentsCount] = useState(1);
  const [paymentPackageCost, setPaymentPackageCost] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [activePaymentPackageId, setActivePaymentPackageId] = useState(null);
  const [monthCount, setMonthCount] = useState(null);
  const [recurringPayment, setRecurringPayment] = useState(false);
  const [perUserPrice, setPerUserPrice] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [allPaymentPackages, setAllPaymentPackages] = useState([]);
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [paymentSystemId, setPaymentSystemId] = useState(null);
  const [isShowPaymentSystemsPart, setIsShowPaymentSystemsPart] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [partnerSubscriptionHistory, setPartnerSubscriptionHistory] = useState([]);

  const [isShowAddNewSupporter, setIsShowAddNewSupporter] = useState(false);
  const [isShowExtendMonthsDuration, setIsShowExtendMonthsDuration] = useState(false);
  const [isShowContactUs, setIsShowContactUs] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [supportersMaxCount, setSupportersMaxCount] = useState(0);

  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getCurrentUserPaymentPackage();
    // getPartnerSubscriptionHistory(1);
    if (localStorage.getItem("returnUrl")) {
      localStorage.removeItem("returnUrl")
    }
    if (pathname.includes("stripesuccess") && pathname.includes("returnurl")) {
      getStripeResponse("success", true);
    }
    if (pathname.includes("stripecancel") && pathname.includes("returnurl")) {
      getStripeResponse("cancel", false);
    }
    if (pathname.includes("paypalsuccess") && pathname.includes("returnurl")) {
      getPayPalResponse("success", true);
    }
    if (pathname.includes("paypalcancel") && pathname.includes("returnurl")) {
      getPayPalResponse("cancel", false);
    }
  }, [])

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    // getPartnerSubscriptionHistory(event.selected + 1);
  }

  const getStripeResponse = (status, bool) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentId = params.get("paymentId") || null;
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getStripeResponse(status, paymentId).then(() => {
      if (status === "success") {
        let userDatacopy = { ...user };
        userDatacopy.demoExpDuration = null;
        dispatch(removeUserFiled("demoExpDuration"))
      }
      setTransactionStatus(bool);
      extractSpinner(spinnerId);
      props.history.push(`/${language}/subscriptions`)
    }).catch(error => getFail(error, spinnerId));
  }

  const getPayPalResponse = (status, bool) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentId = params.get("paymentId") || null;
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPayPalResponse(status, paymentId).then(() => {
      if (status === "success") {
        let userDatacopy = { ...user };
        userDatacopy.demoExpDuration = null;
        dispatch(removeUserFiled("demoExpDuration"))
      }
      setTransactionStatus(bool);
      extractSpinner(spinnerId);
      props.history.push(`/${language}/subscriptions`)
    }).catch(error => getFail(error, spinnerId));
  }

  const getPaymentPackages = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getAllPaymentPackages().then(response => {
      if (response && response.data) {
        response.data && setAllPaymentPackages(response.data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getPaymentPackage = (id) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPaymentPackageById(id).then(response => {
      setMonthCount(null);
      setAssitentsCount(1);
      if (response && response.data) {
        const data = { ...response.data };
        setPaymentPackageViewResponse(data);
        setActivePaymentPackageId(data.id);
        data.cost && setPaymentPackageCost(data.cost);
        data.sameTimeSupportersCountCost && setSameTimeSupportersCountsCosts(data.sameTimeSupportersCountCost);
        data.paymentPackageMonthResponses && setPaymentPackageMonthResponses(data.paymentPackageMonthResponses);
        if (
          data.paymentPackageAllServices &&
          data.paymentPackageAllServices.length &&
          data.paymentPackageActiveServices &&
          data.paymentPackageActiveServices.length
        ) {
          data.paymentPackageAllServices.find(x => {
            data.paymentPackageActiveServices.find(c => {
              if (x.id === c.id) {
                x.isActiveService = true;
              }
            });
          })
          setIsShowContactUs(false);
          setAllServices(data.paymentPackageAllServices);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getPartnerSubscriptionHistory = (currentPage) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPartnerSubscriptionHistory(currentPage, fieldLengths.pageSize_10).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setPartnerSubscriptionHistory(response.data);
        extractSpinner(spinnerId);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const getCurrentUserPaymentPackage = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getCurrentUserPaymentPackage().then(response => {
      if (response && response.data) {
        const data = { ...response.data };

        if (data.partnerLastSubscription) {
          setPartnerLastSubscription(data.partnerLastSubscription);
          // setMonthCount(data.partnerLastSubscription.duration);
        } else {
          getPaymentPackages();
        }
        if (data.paymentPackageViewResponse) {
          setPaymentPackageViewResponse(data.paymentPackageViewResponse);
          data.paymentPackageViewResponse.supportersMaxCount && setSupportersMaxCount(data.paymentPackageViewResponse.supportersMaxCount);
          data.paymentPackageViewResponse.id && setActivePaymentPackageId(data.paymentPackageViewResponse.id);
          data.paymentPackageViewResponse.sameTimeSupportersCountCost && setSameTimeSupportersCountsCosts(data.paymentPackageViewResponse.sameTimeSupportersCountCost); //current pp bottom info
          data.paymentPackageViewResponse.paymentPackageMonthResponses && setPaymentPackageMonthResponses(data.paymentPackageViewResponse.paymentPackageMonthResponses);
          data.paymentPackageViewResponse.cost && setPaymentPackageCost(data.paymentPackageViewResponse.cost);
          if (
            data.paymentPackageViewResponse.paymentPackageAllServices &&
            data.paymentPackageViewResponse.paymentPackageAllServices.length &&
            data.paymentPackageViewResponse.paymentPackageActiveServices &&
            data.paymentPackageViewResponse.paymentPackageActiveServices.length
          ) {
            data.paymentPackageViewResponse.paymentPackageAllServices.find(x => {
              data.paymentPackageViewResponse.paymentPackageActiveServices.find(c => {
                if (x.id === c.id) {
                  x.isActiveService = true;
                }
              });
            })
            setAllServices(data.paymentPackageViewResponse.paymentPackageAllServices);
          }
        }

      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const showPaymentSystems = (isAddNewSupporters) => {
    setButtonSpinner(buttonSpinnerId);
    ApiService.getPaymentSystems().then(response => {
      extractButtonSpinner(buttonSpinnerId)
      if (response.data) {
        const data = [...response.data]
        if (data && data.length) {
          setPaymentSystemId(data[0]?.id);
          setPaymentSystems(data);
        }
        setIsShowPaymentSystemsPart(true);
        if (isAddNewSupporters && partnerLastSubscription) {
          partnerLastSubscription.duration && setMonthCount(partnerLastSubscription.duration);
          setPerUserPrice(costPerSupporterForMonth(partnerLastSubscription.discount));
          setTotalCost(costPerSupporterByMont(partnerLastSubscription.discount, partnerLastSubscription.duration));
        }
      }
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const confirmPayment = () => {
    setButtonSpinner(buttonSpinnerId);
    const form = {
      paymentPackageId: activePaymentPackageId,
      supportersCount: assitentsCount,
      monthCount,
      paymentSystemId,
      currencyId: 1,
      recurringPayment
    };

    (isShowAddNewSupporter ?
      ApiService.paymentNewSupporters(form) :
      isShowExtendMonthsDuration ?
        ApiService.paymentExtendedMonts(form) :
        ApiService.paymentNewSubscription(form)
    ).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.returnUrl) window.location.href = data.returnUrl;
      }
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const getPrices = (supportersCount) => {
    if (!supportersCount || !sameTimeSupportersCountsCosts) { return false; }
    var sameTimeSupportersCountsCostsCopy = [...sameTimeSupportersCountsCosts];
    var cost = 0;
    sameTimeSupportersCountsCostsCopy.forEach(item => {
      if (!item.minCount && supportersCount === item.maxCount) {
        cost = item.cost;
      } else if (item.minCount && item.maxCount) {
        if (supportersCount >= item.minCount && supportersCount <= item.maxCount) {
          cost = item.cost;
        }
      } else if (!item.maxCount && supportersCount >= item.minCount) {
        cost = item.cost;
      }
    })
    return cost;
  }

  const costPerSupporterForMonth = (discount) => {
    if (!paymentPackageCost) { return false; }
    let amount = paymentPackageCost;
    if (discount) {
      amount -= (amount * discount) / 100;
    }
    return Number(amount.toFixed(2).toString());
  }


  const costPerSupporterByMont = (discount, monthCount) => {
    if (!paymentPackageCost) { return false; }
    let amount = paymentPackageCost;
    if (discount) {
      amount = (paymentPackageCost - (paymentPackageCost * discount / 100)).toFixed(2);
    }
    amount = amount * assitentsCount * monthCount;
    return amount.toFixed(2);
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const onNumberChange = (event, cb, maxCount = null) => {
    // if (event.target.value.includes("e") || !+event.target.value || event.target.value.includes(".")) {
    // if (+event.target.value.charAt(0) === 0) {
    //   event.target.value = event.target.value.substring(1);
    // }
    // if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && Number(event.target.value) < maxCount)) {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
      cb(1);
      setPaymentPackageCost(getPrices(1));
      setIsShowContactUs(false);
      return false;
    };

    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) < maxCount)) {
      cb(event.target.value ? +event.target.value : "");

      setPaymentPackageCost(getPrices(+event.target.value));

      if (+event.target.value >= supportersMaxCount) {
        setIsShowContactUs(true);
      } else {
        setIsShowContactUs(false);
        if (monthCount && +event.target.value && +event.target.value >= 1) {
          if (!discount) {
            setTotalCost(getPrices(+event.target.value) * monthCount * +event.target.value);
            setPerUserPrice(getPrices(+event.target.value));
          } else {
            var amount = getPrices(+event.target.value) * monthCount;
            amount -= amount * discount / 100;
            amount = amount * +event.target.value;
            setTotalCost(amount.toFixed(2));
            let perUserPrice = getPrices(+event.target.value);
            perUserPrice -= perUserPrice * discount / 100;
            setPerUserPrice(perUserPrice.toFixed(2));
          }
        }
      }
    }
  };

  const updatePaymentPackage = (buttonSpinnerId, cb) => {
    setButtonSpinner(buttonSpinnerId);
    setMonthCount(null);
    ApiService.getPaymentPackageById(activePaymentPackageId).then(response => {
      if (response.data) {
        const data = { ...response.data };
        data.paymentPackageMonthResponses && setPaymentPackageMonthResponses(data.paymentPackageMonthResponses);
        cb(true);
      }
      extractButtonSpinner(buttonSpinnerId)
    }).catch(error => getFail(error, buttonSpinnerId, cb));
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId, cb) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
    cb && cb(false)
  };

  return translationService && user ?
    <aside className='right-section'>
      {
        typeof transactionStatus === "boolean" ?
          <TransactionModal
            translationService={translationService}
            transactionStatus={transactionStatus}
            closeModal={() => { setTransactionStatus(null) }}
          />
          : null
      }
      <section className="dashboard top-section customer-profile">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => {
                      dispatch(setActiveSection("home"))
                      window.history.back();
                    }}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <div>
                  <p className="vnd--upp-text-22">{translationService.translate("TR_SUBSCRIPTION")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {
              paymentPackageViewResponse ?
                // CURRENT PAYMENT PACKAGE LEFT BLOCK
                <div className="col-lg-4 my-2">
                  <div className="vnd--m-block light-gray-background h-100">
                    <div className="d-flex align-items-center justify-content-between customer-sucscription mb-3">
                      <span className="vnd--upp-text-20 vnd--opacity-30">{paymentPackageViewResponse.name ? translationService.translate(`${paymentPackageViewResponse.displayName}`) : ""}</span>
                      <strong className='vnd--text-24'>$ {paymentPackageViewResponse && paymentPackageViewResponse.cost ? paymentPackageViewResponse.cost : 0}</strong>
                    </div>
                    <hr />
                    {
                      !partnerLastSubscription || (partnerLastSubscription && (isShowAddNewSupporter || isShowExtendMonthsDuration)) ?
                        sameTimeSupportersCountsCosts && sameTimeSupportersCountsCosts.length && !isShowPaymentSystemsPart ?
                          <Auxiliary>
                            <div className="vnd--user-info-block">
                              <div className="vnd--user-info-title">
                                <strong>{translationService.translate("TR_TA_COSTS")}</strong>
                              </div>
                              <ul className="vnd--list-info">
                                {
                                  sameTimeSupportersCountsCosts.map((sameTimeSupportersCountsCost, index) => {
                                    return <Auxiliary key={index}>
                                      <li
                                        className={`vnd--list-info-item-wrapper px-2 ${sameTimeSupportersCountsCost.cost === paymentPackageCost ? "active-technical-assistants-cost" : ""}`}
                                      >
                                        {
                                          sameTimeSupportersCountsCost.minCount && sameTimeSupportersCountsCost.maxCount ?
                                            <span className="vnd--user-info-item capitalize">
                                              {sameTimeSupportersCountsCost.minCount}-{sameTimeSupportersCountsCost.maxCount}
                                            </span> : !sameTimeSupportersCountsCost.minCount ?
                                              <span className="vnd--user-info-item capitalize">
                                                {sameTimeSupportersCountsCost.maxCount}
                                              </span> :
                                              <span className="vnd--user-info-item capitalize">
                                                &gt;&#x3D; {sameTimeSupportersCountsCost.minCount}
                                              </span>
                                        }
                                        <span>
                                          {
                                            sameTimeSupportersCountsCost.cost ?
                                              <Auxiliary>$ {sameTimeSupportersCountsCost.cost}</Auxiliary>
                                              : null
                                          }
                                          {' '}
                                          {translationService.translate(sameTimeSupportersCountsCost.translation)}
                                        </span>
                                      </li>
                                    </Auxiliary>
                                  })
                                }
                              </ul>
                              <hr />
                            </div>
                          </Auxiliary>
                          : null
                        : null
                    }
                    {
                      allServices && allServices.length ?
                        <Auxiliary>
                          {
                            allServices.find(item => item.isActiveService) ?
                              <Auxiliary>
                                <div>
                                  <AiOutlineCheck className='vnd--text-success font-size-20 mr-2' />
                                  <span className='vnd--upp-text-16'>{translationService.translate("TR_WHAT_INCLUDED")}</span>
                                </div>
                                <ul className="order-list list-unstyled mt-4 d-flex flex-wrap text-small text-left">
                                  {
                                    allServices.map(service => {
                                      if (service.isActiveService) {
                                        return <li key={service.id} className="mb-3 capitalize">

                                          {translationService.translate(service.displayName)},
                                        </li>
                                      }
                                    })
                                  }
                                </ul>
                              </Auxiliary>
                              : null
                          }
                          {
                            allServices.find(item => !item.isActiveService) ?
                              <Auxiliary>
                                <div>
                                  <RxCross2 className='vnd--text-dark vnd--opacity-50 font-size-20 mr-2' />
                                  <span className='vnd--upp-text-16'>{translationService.translate("TR_WHAT_EXCLUDED")}</span>
                                </div>
                                <ul className="order-list list-unstyled mt-4 d-flex flex-wrap text-small text-left">
                                  {
                                    allServices.map(service => {
                                      if (!service.isActiveService) {
                                        return <li
                                          key={service.id}
                                          className="mb-3 text-muted capitalize">
                                          {translationService.translate(service.displayName)},
                                        </li>
                                      }
                                    })
                                  }
                                </ul>
                              </Auxiliary>
                              : null
                          }
                        </Auxiliary>
                        : null
                    }

                  </div>
                </div>
                : null
            }
            {
              // SUBSCRIPTION INFORMATION BLOCK 
              partnerLastSubscription && !isShowAddNewSupporter && !isShowExtendMonthsDuration ?
                <div className="col-lg-8 my-2">
                  <div className="vnd--m-block vnd--borderd">
                    <div className="d-flex align-items-center customer-sucscription mb-3">
                      <p className="vnd--upp-text-20">
                        {translationService.translate("TR_SUBSCRIPTION")} {translationService.translate('TR_INFO')}
                      </p>
                    </div>
                    <div className="vnd--user-info-block">
                      <div className="vnd--user-info-title">
                        <strong>{translationService.translate("TR_TA_COSTS")}</strong>
                      </div>
                      <ul className="vnd--list-info">

                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TECHNICAL_ASSISTANTS")} {translationService.translate("TR_COUNT")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            {partnerLastSubscription.supportersCount ? partnerLastSubscription.supportersCount : 0}
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_DURATION")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            {partnerLastSubscription.duration ? partnerLastSubscription.duration : 0}
                            {' '}
                            {partnerLastSubscription.duration && partnerLastSubscription.duration === 1 ? `${translationService.translate("TR_MONTH")}` : `${translationService.translate("TR_MONTHS")}`}
                          </span>
                        </li>
                        <li><hr /></li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_PAY_DATE")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            {partnerLastSubscription.createDate ? moment(MainService.convertUTCDateToLocalDate(new Date(partnerLastSubscription.createDate))).format("LL") : ""}
                          </span>
                        </li>
                        <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_EXPIRE_DATE")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            {partnerLastSubscription.expireDate ? moment(MainService.convertUTCDateToLocalDate(new Date(partnerLastSubscription.expireDate))).format("LL") : ""}
                          </span>
                        </li>

                        {/* <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_COST")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            $ {paymentPackageViewResponse && paymentPackageViewResponse.cost ? paymentPackageViewResponse.cost : 0}
                          </span>
                        </li> */}
                        {/* <li className="vnd--list-info-item-wrapper">
                          <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TOTAL")}:</span>
                          <span className="vnd--user-info-item capitalize font-bold">
                            $ {partnerLastSubscription.amount ? partnerLastSubscription.amount : 0}
                          </span>
                        </li> */}
                      </ul>
                      <hr />
                      <div className="d-lg-flex align-items-center">
                        <ActionButton
                          type="button"
                          name={`${translationService.translate("TR_ADD_NEW")} ${translationService.translate("TR_TECHNICAL_ASSISTANT")}`}
                          className="vnd-btn vnd-primary-btn w-100 m-1 max-line-1"
                          spinnerId={buttonSpinnerId}
                          onClick={() => {
                            updatePaymentPackage(buttonSpinnerId, setIsShowAddNewSupporter);
                            setIsShowContactUs(false);
                            setAssitentsCount(1);
                            setPaymentPackageCost(getPrices(1));
                          }}
                        />
                        <ActionButton
                          type="button"
                          name={translationService.translate("TR_EXTEND_SUBSCRIPTION_DURATION")}
                          className="vnd-btn vnd-primary-btn w-100 m-1"
                          spinnerId={additionalButtonSpinnerId}
                          onClick={() => {
                            if (partnerLastSubscription.supportersCount >= supportersMaxCount) {
                              setIsShowContactUs(true);
                              setIsShowExtendMonthsDuration(true);
                            } else {
                              updatePaymentPackage(additionalButtonSpinnerId, setIsShowExtendMonthsDuration);
                              setAssitentsCount(partnerLastSubscription.supportersCount);
                              setPaymentPackageCost(getPrices(partnerLastSubscription.supportersCount));
                            }

                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }
            {
              // ADD NEW SUPPORTERS BLOCK
              partnerLastSubscription &&
                isShowAddNewSupporter &&
                !isShowExtendMonthsDuration &&
                paymentPackageMonthResponses &&
                paymentPackageMonthResponses.length &&
                !isShowPaymentSystemsPart ?
                <div className="col-lg-8 my-2">
                  <div className="row">
                    <div className="col-12">
                      <div className="vnd--m-block light-brand-background">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="backspace">
                            <span
                              className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                              onClick={() => {
                                setIsShowAddNewSupporter(false);
                                setAssitentsCount(1);
                              }}
                            >
                              <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                            </span>
                          </div>
                          <p className="vnd--upp-text-20 text-right">{translationService.translate("TR_ADD_NEW")} {translationService.translate("TR_TECHNICAL_ASSISTANT")}</p>
                        </div>
                        <hr />
                        <div className="vnd--pp-assitents-count-wrapper">
                          <div className="d-md-flex align-items-end">
                            <Input
                              type="number"
                              id="assitentsCount"
                              name="assitentsCount"
                              pattern="[0-9]"
                              // min="1"
                              min="0"
                              value={assitentsCount}
                              blockClassName="mb-0"
                              onChange={(event) => onNumberChange(event, setAssitentsCount, assitentsMaxCount)}
                            />
                            <div>
                              <p className=''>{translationService.translate("TR_YOU_HAVE")}
                                <strong className='font-bold'>&nbsp;{partnerLastSubscription.supportersCount}&nbsp;</strong>
                                {translationService.translate("TR_ACTIVE")}&nbsp;
                                {partnerLastSubscription.supportersCount === 1 ?
                                  translationService.translate("TR_TECHNICAL_ASSISTANT") :
                                  translationService.translate("TR_TECHNICAL_ASSISTANTS")}.
                              </p>
                              <hr className='my-1' />
                              <small>{translationService.translate("TR_ADD_NEW_TA_INFO")} <strong>{partnerLastSubscription.duration}</strong> {partnerLastSubscription.duration === 1 ? translationService.translate("TR_MONTH") : translationService.translate("TR_MONTHS")}.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <small>{translationService.translate("TR_PRICE_FOR")} <strong>{assitentsCount}</strong> {translationService.translate("TR_ACTIVE")} {assitentsCount === 1 ? translationService.translate("TR_TECHNICAL_ASSISTANT") : translationService.translate("TR_TECHNICAL_ASSISTANTS")}</small>
                    </div>
                  </div>
                  {
                    !isShowContactUs ?
                      <div className="row">
                        {
                          partnerLastSubscription ?
                            <div className={`col-md-6 my-2 ${assitentsCount ? "" : "disabled"}`}>
                              <div className={`payment-duration-wrapper vnd--m-block cursor-default active-payment-package`}>
                                <div className="payment-duration">{partnerLastSubscription.duration}</div>
                                <div className="payment-info">
                                  {
                                    partnerLastSubscription.discount ?
                                      <div className="payment-discount">- {partnerLastSubscription.discount} %</div>
                                      : null
                                  }
                                  <div className="price">
                                    ${costPerSupporterForMonth(partnerLastSubscription.discount)}
                                    <span>{translationService.translate("TR_FOR")} {translationService.translate("TR_PER_MONTH")}</span>
                                  </div>
                                  <div className="total-payment capitalize">
                                    {translationService.translate("TR_PAYMENT")}
                                    <span> ${costPerSupporterByMont(partnerLastSubscription.discount, partnerLastSubscription.duration)} </span>
                                    {translationService.translate("TR_FOR")}
                                    <span> {partnerLastSubscription.duration} </span>
                                    {translationService.translate("TR_MONTH")}
                                  </div>
                                </div>
                              </div>
                            </div>
                            : null
                        }
                        <div className="col-12">
                          <ActionButton
                            type="button"
                            name={translationService.translate("TR_CONTINUE")}
                            className={`vnd-btn vnd-primary-btn my-4 ${assitentsCount && assitentsCount > 0 ? "" : "disabled"}`}
                            disabled={assitentsCount && assitentsCount > 0 ? false : true}
                            spinnerId={buttonSpinnerId}
                            onClick={() => {
                              showPaymentSystems(true);
                            }}
                          />
                        </div>
                      </div>
                      :
                      //  CONTACT-US FORM
                      <div className='vnd-block vnd--borderd white-background my-2'>
                        <div className="row mb-3">
                          <div className="col-12">
                            <p className="vnd--upp-text-20 mb-2">{Parser(translationService.translate("TR_CONTACT_US"))}</p>
                            <small>
                              {translationService.translate("TR_SUBSCRIPTION_CONTACT_US_INFO").replace('{0}', supportersMaxCount)}
                            </small>
                          </div>
                        </div>
                        <ContactUs />
                      </div>
                  }
                </div>
                : null
            }
            {
              partnerLastSubscription && isShowPaymentSystemsPart && isShowAddNewSupporter && !isShowExtendMonthsDuration ?
                paymentSystems && paymentSystems.length ? <div className="col-lg-8 my-2">
                  {/* PAYMENT PACKAGE BUY BLOCK */}
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='vnd--m-block light-brand-background h-100'>
                        <div className="backspace p-0">
                          <span
                            className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                            onClick={() => {
                              setIsShowPaymentSystemsPart(false);
                              // setAssitentsCount(1);
                            }}
                          >
                            <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                            <hr />
                          </span>
                        </div>
                        <hr />
                        <div className='payment-process-wrapper'>
                          <div className="payment-process-info">
                            <ul className="vnd--list-info mb-0">
                              {
                                perUserPrice ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className=" capitalize">{translationService.translate("TR_PRICE_PER_USER")}:</span>
                                    <span className="vnd--user-info-item font-weight-bold">${perUserPrice}</span>
                                  </li>
                                  : null
                              }
                              {
                                assitentsCount ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}:</span>
                                    <span className="vnd--user-info-item font-weight-bold">{assitentsCount}</span>
                                  </li>
                                  : null
                              }
                              {
                                monthCount ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className="vnd--user-info-item capitalize">{translationService.translate("TR_DURATION")}:</span>
                                    <span className="vnd--user-info-item font-weight-bold">{monthCount} {monthCount === 1 ? translationService.translate("TR_MONTH") : translationService.translate("TR_MONTHS")}</span>
                                  </li>
                                  : null
                              }

                              {/* <li className="vnd--list-info-item-wrapper">
                                        <span className="vnd--user-info-item capitalize">{translationService.translate("TR_RECURRING_PAYMENT")}:</span>
                                        <span className="vnd--user-info-item font-weight-bold">
                                          {recurringPayment ? translationService.translate("TR_YES") : translationService.translate("TR_NO")}
                                        </span>
                                      </li> */}
                              <li><hr /></li>
                              {
                                totalCost ?
                                  <li className="vnd--list-info-item-wrapper">
                                    <span className="vnd--upp-text-16">{translationService.translate("TR_TOTAL")}:</span>
                                    <span className="vnd--upp-text-20">${totalCost}</span>
                                  </li>
                                  : null
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 mt-lg-0 mt-3'>
                      <div className='vnd--m-block vnd--borderd h-100'>
                        <PaymentProcess
                          setPaymentSystemId={setPaymentSystemId}
                          paymentSystemId={paymentSystemId}
                          paymentSystems={paymentSystems}
                          perUserPrice={perUserPrice}
                          assitentsCount={assitentsCount}
                          monthCount={monthCount}
                          totalCost={totalCost}
                          recurringPayment={recurringPayment}
                          buttonSpinnerId={buttonSpinnerId}
                          confirmPayment={confirmPayment}
                        />
                      </div>
                    </div>
                  </div>
                </div> : null
                : null
            }
            {
              // et hatvacy nra hamara vor ete mardy chuni gnac payment package (arajin angama mtnum)
              (!partnerLastSubscription || (partnerLastSubscription && isShowExtendMonthsDuration)) && paymentPackageMonthResponses && paymentPackageMonthResponses.length ?
                <div className="col-lg-8 my-2">
                  <div className="row">
                    {
                      // ALL PAYMENT PACKAGES 
                      !isShowPaymentSystemsPart && allPaymentPackages && allPaymentPackages.length ?
                        <div className="col-12">
                          <div className="vnd--m-block light-brand-background mb-2">
                            <ul className="paymant-package-types">
                              <li className="paymant-package-inactive-types">
                                {
                                  allPaymentPackages.map(paymentPackage => {
                                    if (paymentPackage.cost !== 0) {
                                      return <div key={paymentPackage.id} className={`${paymentPackage.id === activePaymentPackageId ? "active-payment-package-name " : ""}`}>
                                        <Link to="#" className="vnd--upp-text-20 vnd--vnd--text-primary" onClick={() => {
                                          if (paymentPackage.id !== activePaymentPackageId) {
                                            getPaymentPackage(paymentPackage.id);
                                          }
                                        }}>
                                          {translationService.translate(paymentPackage.name) ? translationService.translate(paymentPackage.name) : paymentPackage.name}
                                        </Link>
                                      </div>
                                    }
                                  })
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                        : null
                    }
                    {
                      // SUPPORTERS COUNT INPUT
                      !isShowPaymentSystemsPart ?
                        <div className="col-12">
                          {
                            !partnerLastSubscription ?
                              <div className="">
                                {
                                  isShowAddNewSupporter ?
                                    <div className="backspace">
                                      <span
                                        className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                                        onClick={() => {
                                          // setIsShowPaymentPackageMonthResponses(false);
                                        }}
                                      >
                                        <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                                        <hr />
                                      </span>
                                    </div>
                                    : null
                                }
                                <div className='d-md-flex align-items-end my-3'>
                                  <Input
                                    type="number"
                                    id="assitentsCount"
                                    name="assitentsCount"
                                    // min="1"
                                    pattern="[0-9]"
                                    min="0"
                                    value={assitentsCount}
                                    onChange={(event) => onNumberChange(event, setAssitentsCount, assitentsMaxCount)}
                                  />
                                  <div className='ml-3'>
                                    <label className='d-block m-0 font-bold'>{translationService.translate("TR_TECHNICAL_ASSISTANTS")} {translationService.translate("TR_COUNT")} *</label>
                                    <small className='mb-3'>• {translationService.translate("TR_TA_LOGGED_COUNT_MESSAGE").replace("{0}", assitentsCount)}</small>
                                  </div>
                                </div>
                              </div>
                              : <div className="vnd--m-block light-brand-background">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="backspace p-0">
                                    <span
                                      className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                                      onClick={() => {
                                        setIsShowExtendMonthsDuration(false);
                                      }}
                                    >
                                      <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                                      <hr />
                                    </span>
                                  </div>
                                  <p className="vnd--upp-text-20 text-right">{translationService.translate("TR_EXTEND_SUBSCRIPTION_DURATION")}</p>
                                </div>
                              </div>

                          }
                          <hr />
                        </div>
                        : paymentSystems && paymentSystems.length ? <div className="col-12">
                          {/* PAYMENT PACKAGE BUY BLOCK */}
                          <div className='row'>
                            <div className='col-lg-6'>
                              <div className='vnd--m-block light-brand-background h-100'>
                                <div className="backspace p-0">
                                  <span
                                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                                    onClick={() => {
                                      setIsShowPaymentSystemsPart(false);
                                      // setAssitentsCount(1);
                                    }}
                                  >
                                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                                    <hr />
                                  </span>
                                </div>
                                <hr />
                                <div className='payment-process-wrapper'>
                                  <div className="payment-process-info">
                                    <ul className="vnd--list-info mb-0">
                                      {
                                        perUserPrice ?
                                          <li className="vnd--list-info-item-wrapper">
                                            <span className=" capitalize">{translationService.translate("TR_PRICE_PER_USER")}:</span>
                                            <span className="vnd--user-info-item font-weight-bold">${perUserPrice}</span>
                                          </li>
                                          : null
                                      }
                                      {
                                        assitentsCount ?
                                          <li className="vnd--list-info-item-wrapper">
                                            <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}:</span>
                                            <span className="vnd--user-info-item font-weight-bold">{assitentsCount}</span>
                                          </li>
                                          : null
                                      }
                                      {
                                        monthCount ?
                                          <li className="vnd--list-info-item-wrapper">
                                            <span className="vnd--user-info-item capitalize">{translationService.translate("TR_DURATION")}:</span>
                                            <span className="vnd--user-info-item font-weight-bold">{monthCount} {monthCount === 1 ? translationService.translate("TR_MONTH") : translationService.translate("TR_MONTHS")}</span>
                                          </li>
                                          : null
                                      }
                                      {/* <li className="vnd--list-info-item-wrapper">
                                        <span className="vnd--user-info-item capitalize">{translationService.translate("TR_RECURRING_PAYMENT")}:</span>
                                        <span className="vnd--user-info-item font-weight-bold">
                                          {recurringPayment ? translationService.translate("TR_YES") : translationService.translate("TR_NO")}
                                        </span>
                                      </li> */}
                                      <li><hr /></li>
                                      {
                                        totalCost ?
                                          <li className="vnd--list-info-item-wrapper">
                                            <span className="vnd--upp-text-16">{translationService.translate("TR_TOTAL")}:</span>
                                            <span className="vnd--upp-text-20">${totalCost}</span>
                                          </li>
                                          : null
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-6 mt-lg-0 mt-3'>
                              <div className='vnd--m-block vnd--borderd h-100'>
                                <PaymentProcess
                                  setPaymentSystemId={setPaymentSystemId}
                                  paymentSystemId={paymentSystemId}
                                  paymentSystems={paymentSystems}
                                  perUserPrice={perUserPrice}
                                  assitentsCount={assitentsCount}
                                  monthCount={monthCount}
                                  totalCost={totalCost}
                                  recurringPayment={recurringPayment}
                                  buttonSpinnerId={buttonSpinnerId}
                                  confirmPayment={confirmPayment}
                                />
                              </div>
                            </div>
                          </div>
                        </div> : null
                    }
                  </div>
                  {
                    !isShowContactUs ?
                      // MONTHS PART
                      <div className="row">
                        {
                          !isShowPaymentSystemsPart ?
                            <Auxiliary>
                              {
                                <div className="col-12 text-right">
                                  <div className="d-flex flex-wrap justify-content-between">
                                    <small>{translationService.translate("TR_PRICE_FOR")} <strong>{assitentsCount}</strong> {translationService.translate("TR_ACTIVE")} {assitentsCount === 1 ? translationService.translate("TR_TECHNICAL_ASSISTANT") : translationService.translate("TR_TECHNICAL_ASSISTANTS")}</small>
                                    <small>{translationService.translate("TR_MONTH_DAYS")}</small>
                                  </div>
                                </div>
                              }
                              {
                                paymentPackageMonthResponses.map((paymentPackageMonthResponse, index) => {
                                  return <div key={index} className={`col-sm-6 col-12 my-2 ${assitentsCount ? "" : "disabled"}`}>
                                    <div
                                      className={`payment-duration-wrapper vnd--m-block
                                          ${monthCount === paymentPackageMonthResponse.monthCount ? "active-payment-package" : ""}
                                        `}
                                      onClick={() => {
                                        setMonthCount(paymentPackageMonthResponse.monthCount);
                                        setPerUserPrice(costPerSupporterForMonth(paymentPackageMonthResponse.discount));
                                        setTotalCost(costPerSupporterByMont(paymentPackageMonthResponse.discount, paymentPackageMonthResponse.monthCount));
                                        setDiscount(paymentPackageMonthResponse.discount);
                                      }}
                                    >
                                      <div className="payment-duration">{paymentPackageMonthResponse.monthCount}</div>
                                      <div className={`payment-info ${paymentPackageMonthResponse.monthCount === 1 ? 'justify-content-center' : ''}`}>
                                        {
                                          paymentPackageMonthResponse.discount ?
                                            <div className="payment-discount">- {paymentPackageMonthResponse.discount} %</div>
                                            : null
                                        }
                                        <div className="price">
                                          ${costPerSupporterForMonth(paymentPackageMonthResponse.discount)}
                                          <span>{translationService.translate("TR_FOR")} {translationService.translate("TR_PER_MONTH")}</span>
                                        </div>
                                        <div className="total-payment capitalize">
                                          {translationService.translate("TR_PAYMENT")}
                                          <span> ${costPerSupporterByMont(paymentPackageMonthResponse.discount, paymentPackageMonthResponse.monthCount)} </span>
                                          {translationService.translate("TR_FOR")}
                                          <span> {paymentPackageMonthResponse.monthCount} </span>
                                          {translationService.translate("TR_MONTH")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                })
                              }
                              {
                                monthCount && assitentsCount > 0 ?
                                  <div className="col-12">
                                    <ActionButton
                                      type="button"
                                      name={translationService.translate("TR_CONTINUE")}
                                      className={`vnd-btn vnd-primary-btn ml-auto my-2`}
                                      spinnerId={buttonSpinnerId}
                                      onClick={() => {
                                        showPaymentSystems(false)
                                      }}
                                    />
                                  </div>
                                  : null
                              }
                            </Auxiliary>
                            : null
                        }
                      </div>
                      // CONTACT-US FORM
                      : <>
                        {
                          partnerLastSubscription ?
                            <div className="col-12 text-right">
                              <div className="d-flex flex-wrap justify-content-between">
                                <small>{translationService.translate("TR_PRICE_FOR")} <strong>{partnerLastSubscription.supportersCount}</strong> {translationService.translate("TR_ACTIVE")} {assitentsCount === 1 ? translationService.translate("TR_TECHNICAL_ASSISTANT") : translationService.translate("TR_TECHNICAL_ASSISTANTS")}</small>
                                <small>{translationService.translate("TR_MONTH_DAYS")}</small>
                              </div>
                            </div>
                            : null
                        }
                        <div className='vnd-block vnd--borderd white-background my-2'>
                          <div className="row mb-3">
                            <div className="col-12">
                              <p className="vnd--upp-text-20 mb-2">{Parser(translationService.translate("TR_CONTACT_US"))}</p>
                              <small>
                                {translationService.translate("TR_SUBSCRIPTION_CONTACT_US_INFO").replace('{0}', supportersMaxCount)}
                              </small>
                            </div>
                          </div>
                          <ContactUs cb={() => {
                            setIsShowContactUs(false);
                            setIsShowExtendMonthsDuration(false);
                          }} />
                        </div>
                      </>
                  }
                </div>
                : null
            }
          </div>

          {
            partnerSubscriptionHistory && partnerSubscriptionHistory.length ?
              <div className='row'>
                <div className='col-12'><hr /></div>
                <div className="col-12 my-2">
                  <div className="vnd-block light-brand-background">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-center customer-sucscription mb-3">
                          <p className="vnd--upp-text-20">{translationService.translate("TR_SUBSCRIPTION_HISTORY")}</p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className='table-responsive table-bordered border-radius-10'>
                          <table className="table mb-0">
                            <thead className="thead-dark">
                              <tr>
                                <th>{translationService.translate("TR_NAME")}</th>
                                <th>{translationService.translate("TR_COST")}</th>
                                <th>{translationService.translate("TR_AMOUNT")}</th>
                                <th>{translationService.translate("TR_DURATION")}</th>
                                <th>{translationService.translate("TR_DESCRIPTION")}</th>
                                <th>{translationService.translate("TR_TECHNICAL_ASSISTANTS")}</th>
                                <th>{translationService.translate("TR_PAY_DATE")}</th>
                                <th>{translationService.translate("TR_EXPIRE_DATE")}</th>
                                <th>{translationService.translate("TR_STATUS")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                partnerSubscriptionHistory.map((item, index) => {
                                  return <tr key={index}>
                                    <td>{item?.paymentPackage.name ? item?.paymentPackage.name : ""}</td>
                                    <td>{item?.currency ? item.currency.symbol : null} {item?.paymentPackage.cost ? item?.paymentPackage.cost : ""}</td>
                                    <td>{item?.currency ? item.currency.symbol : null} {item.amount ? item.amount.toFixed(2) : ""}</td>
                                    <td>{item.duration ? item.duration : ""} {item.duration === 1 ? translationService.translate("TR_MONTH") : translationService.translate("TR_MONTHS")}</td>
                                    <td>{item.paidFor ? translationService.translate(item.paidFor) : ""}</td>
                                    <td>{item.supportersCount ? item.supportersCount : ""}</td>
                                    <td>{item.createDate ? moment(item.createDate).format("ll HH:mm") : ""}</td>
                                    <td>{item.expireDate ? moment(item.expireDate).format("ll HH:mm") : ""}</td>
                                    <td>{translationService.translate(item.statusText)}</td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        {
                          pagination && pagination.TotalPages > 1 ?
                            <div className='col-12'>
                              <div className='w-100 d-flex justify-content-center mt-4'>
                                <ReactPaginate
                                  nextLabel={translationService.translate("TR_NEXT")}
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={3}
                                  marginPagesDisplayed={2}
                                  pageCount={pagination.TotalPages}
                                  previousLabel={translationService.translate("TR_PREVIOUS")}
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakLabel="..."
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  renderOnZeroPageCount={null}
                                  forcePage={activePageNumber}
                                />
                              </div>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null
          }


        </div>
      </section>
    </aside >
    : null
}

export default Subscriptions;