import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSection } from '../../../Store/Actions/main'
import AlertService from '../../../Services/AlertService'
import { ERROR_KEY, PROJECT_NAME, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys'
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner'
import uuid from 'react-uuid'
import ApiService from '../../../Services/ApiService'
import Input from './../../../Components/Inputs/Input'
import CloseIcon from "./../../../assets/icons/close-blue-01.svg"
import Laptop from "./../../../assets/images/laptop.png"
import { CirclePicker } from 'react-color'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import Textarea from '../../../Components/Inputs/Textarea'
import ReactSelectOption from '../../../Components/SelectOption/ReactSelectOption'
import ActionButton from '../../../Components/Buttons/ActionButton'
import NoData from '../../../Components/NoData/NoData'
import TranslationService from './../../../Services/translationService'
import { Picker } from 'emoji-mart'
import { UncontrolledTooltip, ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput'
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import MainService from './../../../Services/mainService'
import { Tabs, Tab } from "react-bootstrap"
import Parser from 'html-react-parser'
import Widget from './Components/Widget'
import CompanyLogo from './Components/CompanyLogo'
import { AiOutlineLeft } from "react-icons/ai"
import { TiArrowSync } from 'react-icons/ti'

const buttonSpinnerId = uuid();
const WidgetSettings = () => {

  const workingGraphic = [
    {
      id: 1,
      name: "TR_CUSTOM"
    },
    {
      id: 2,
      name: "24/7"
    },
  ];

  const widgetPositionArr = [
    {
      name: "TR_TOP_LEFT",
      dataPosition: "top-left",
      className: "position-button top-left"
    },
    {
      name: "TR_TOP_CENTER",
      dataPosition: "top-center",
      className: "position-button top-center"
    },
    {
      name: "TR_TOP_RIGHT",
      dataPosition: "top-right",
      className: "position-button top-right"
    },
    {
      name: "TR_BOTTOM_LEFT",
      dataPosition: "bottom-left",
      className: "position-button bottom-left"
    },
    {
      name: "TR_BOTTOM_CENTER",
      dataPosition: "bottom-center",
      className: "position-button bottom-center"
    },
    {
      name: "TR_BOTTOM_RIGHT",
      dataPosition: "bottom-right",
      className: "position-button bottom-right"
    },
  ];

  const startTime = 0;
  const endTime = 1439;

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { translations } = useSelector(state => state.translation);
  const { subscriptionService } = useSelector(state => state.main);
  const [translationService, setTranslationService] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isOpenWelcomeMessageEmojies, setIsOpenWelcomeMessageEmojies] = useState(false);
  const [isOpenWelcomeSubMessageEmojies, setIsOpenWelcomeSubMessageEmojies] = useState(false);
  const [widgetGradientColor, setWidgetGradientColor] = useState("");
  const [currentFAQ, setCurrentFAQ] = useState("");
  const [key, setKey] = useState(1);
  const [welcomeSubMessageTitleLibrary, setWelcomeSubMessageTitleLibrary] = useState([]);
  const [welcomeMessageTitleLibrary, setWelcomeMessageTitleLibrary] = useState([]);
  const [isShowWelcomeSubMessageTextarea, setIsShowWelcomeSubMessageTextarea] = useState(false);
  const [isShowWelcomeMessageTextarea, setIsShowWelcomeMessageTextarea] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isShowCopiedMessage, setIsShowCopiedMessage] = useState(false);
  const [script, setScript] = useState(null);
  const [timeZones, setTimeZones] = useState([]);
  const [isActiveFirstWidgetCircularMenu, setIsActiveFirstWidgetCircularMenu] = useState(false);
  const [isActiveSecondWidgetCircularMenu, setIsActiveSecondWidgetCircularMenu] = useState(false);
  const [widget_size, set_widget_size] = useState("widget_medium_size");
  const [isShowFirstWidget, setIsShowFirstWidget] = useState(true);
  const [isShowSecondWidget, setIsShowSecondWidget] = useState(true);
  const [companyLogoPath, setCompanyLogoPath] = useState(null);

  const [values, setValues] = useState({
    id: null,
    weekDaysModel: [],
    domainName: "",
    workingGraphicId: 1,
    isCustomButton: false,
    useWidgetButton: true,
    position: "bottom-right",
    companyName: "",
    color: "#00266C",
    textColor: "#ffffff",
    widgetSize: "4em",
    welcomeMessageTitle: "",
    isSolidColor: true,
    isGradient: false,
    widgetFAQs: [],
    welcomeSubMessageLibDataId: 2,
    welcomeMessageLibDataId: 5,
    welcomeSubMessage: "",
    welcomeMessage: "",
    timeZoneId: "",
    customButtonTag: "",
    languageId: null,
  })

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getWidgetData();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!MainService.checkServicePermission(subscriptionService, "schedule_service")) {
      setValues((values) => ({ ...values, workingGraphicId: 2 }))
    }
  }, [])

  useEffect(() => {
    if (values.color) {
      document.querySelector('.vnd--floating-btn')?.style.setProperty('--primary-color', values.color);
      document.querySelector('.vnd-local-message')?.style.setProperty('--primary-color', values.color);
      if (document.querySelectorAll('.vnd--circular-menu')?.length) {
        document.querySelectorAll('.vnd--circular-menu').forEach(element => {
          element.style.setProperty('--primary-color', values.color);
        })
      }
    }
  }, [values.color])

  useEffect(() => {
    if (values.textColor) {
      if (document.querySelectorAll("#vndPlusIcon").length) {
        document.querySelectorAll("#vndPlusIcon").forEach(element => {
          element?.style.setProperty('--white-color', values.textColor);
        })
      }
      if (document.querySelectorAll('.vnd--circular-menu')?.length) {
        document.querySelectorAll('.vnd--circular-menu').forEach(element => {
          element.style.setProperty('--white-color', values.textColor)
        })
      }
    }
  }, [values.textColor])

  useEffect(() => {
    if (document.querySelectorAll('.vnd--circular-menu')?.length) {
      document.querySelectorAll('.vnd--circular-menu').forEach(element => {
        element.style.setProperty('--vnd-4em', values.widgetSize);
      })
    }
  }, [values.widgetSize])

  const changeGradientColors = () => {
    setValues(values => ({ ...values, color: widgetGradientColor }))
    setWidgetGradientColor(values.color);
  }

  const toggleWelcomeMessageEmojies = () => {
    setIsOpenWelcomeMessageEmojies(!isOpenWelcomeMessageEmojies)
  }
  const toggleWelcomeSubMessageEmojies = () => {
    setIsOpenWelcomeSubMessageEmojies(!isOpenWelcomeSubMessageEmojies)
  }

  const addEmoji = (e, name, text, maxLength = null) => {
    if (maxLength && (maxLength <= text.length || maxLength <= text.length + 1)) { return; }
    let emoji = e.native;
    setValues((values) => ({ ...values, [name]: `${text}${emoji}` }))
  }

  const changeWeekDayChecked = (event, name) => {
    const weekDaysModelCopy = [...values.weekDaysModel];
    const filteredWeekDaysModel = weekDaysModelCopy.filter(item => item.checked);
    if (filteredWeekDaysModel.length <= 1 && !event.target.checked) return false;
    weekDaysModelCopy.forEach(weekDaysModelCopyItem => {
      if (weekDaysModelCopyItem.name === name) {
        weekDaysModelCopyItem.checked = event.target.checked;
      }
    })
    setValues((values) => ({ ...values, weekDaysModel: weekDaysModelCopy }))
  }

  const getWidgetData = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getWidgetData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        var _widgetSize = data.widgetSize === 48 ? "3em" : data.widgetSize === 60 ? "4em" : data.widgetSize === 80 ? "5em" : "4em";
        data.gradient && setWidgetGradientColor(data.gradient);
        data.companyLogoPath && setCompanyLogoPath(data.companyLogoPath);
        if (data.widgetSize) {
          set_widget_size(_widgetSize === "3em" ? "widget_small_size" : _widgetSize === "4em" ? "widget_medium_size" : _widgetSize === "5em" ? "widget_large_size" : "widget_medium_size");
          // if (document.querySelectorAll('.vnd--circular-menu')?.length) {
          //   document.querySelectorAll('.vnd--circular-menu').forEach(element => {
          //     element.style.setProperty('--vnd-4em', _widgetSize);
          //   })
          // }
        }
        setWelcomeSubMessageTitleLibrary(data.welcomeSubMessageTitle?.widgetLibraryDatas || []);
        setWelcomeMessageTitleLibrary(data.welcomeMessageTitle?.widgetLibraryDatas || []);
        data.welcomeSubMessage && setIsShowWelcomeSubMessageTextarea(true);
        data.welcomeMessage && setIsShowWelcomeMessageTextarea(true);
        setLanguages(data.languageResponseModels || []);
        setTimeZones(data.timeZones || []);
        data.script && setScript(data.script);

        if (
          data.weekDays &&
          data.weekDays.widgetLibraryDatas &&
          data.weekDays.widgetLibraryDatas.length &&
          (!data.widgetScheduleResponseModels || (data.widgetScheduleResponseModels && !data.widgetScheduleResponseModels.length))
        ) {
          data.weekDays.widgetLibraryDatas.forEach(widgetLibraryData => {
            if (widgetLibraryData.name === "SATURDAY" || widgetLibraryData.name === "SUNDAY") {
              widgetLibraryData.checked = false;
              widgetLibraryData.startTime = startTime;
              widgetLibraryData.endTime = endTime;
            } else {
              widgetLibraryData.checked = true;
              widgetLibraryData.startTime = 540; //"09:00"
              widgetLibraryData.endTime = 1080; //"18:00"
            }
          })
        }

        if (
          data.widgetScheduleResponseModels &&
          data.widgetScheduleResponseModels.length &&
          data.weekDays &&
          data.weekDays.widgetLibraryDatas &&
          data.weekDays.widgetLibraryDatas.length
        ) {
          data.widgetScheduleResponseModels.forEach(item => {
            data.weekDays.widgetLibraryDatas.forEach(el => {
              if (item.weekDay.trim() === el.name.trim()) {
                el.checked = true;
                el.startTime = convertTimeToMinutes(item.startTime);
                el.endTime = convertTimeToMinutes(item.endTime);
              } else {
                el.startTime = 540; //"09:00"
                el.endTime = 1080; //"18:00"
              }
            })
          })
        }
        setValues((values) => ({
          ...values,
          id: data.id,
          domainName: data.domainName || "",
          companyName: data.companyName || "",
          position: data.position || "bottom-right",
          welcomeMessageLibDataId: data.welcomeConfirmedMessageId || 5,
          welcomeSubMessageLibDataId: data.welcomeConfirmedSubMessageId || 2,
          widgetFAQs: data.widgetFAQs || [],
          isCustomButton: typeof data.isCustomButton === "boolean" ? data.isCustomButton : false,
          useWidgetButton: typeof data.useWidgetButton === "boolean" ? data.useWidgetButton : true,
          workingGraphicId: data.isFullWeek ? 2 : 1,
          color: data.color || "#00266C",
          textColor: data.textColor || "#ffffff",
          isGradient: data.gradient ? true : false,
          widgetSize: _widgetSize || "4em",
          welcomeSubMessageTitle: data.welcomeSubMessageTitle?.displayString || "",
          welcomeMessageTitle: data.welcomeMessageTitle?.displayString || "",
          welcomeSubMessage: data.welcomeSubMessage || "",
          welcomeMessage: data.welcomeMessage || "",
          languageId: data.languageId || data.languageResponseModels && data.languageResponseModels.length && data.languageResponseModels[0].id || null,
          timeZoneId: data.timeZoneId || data.timeZones && data.timeZones[0].id || null,
          weekDaysModel: data.weekDays && data.weekDays?.widgetLibraryDatas || [],
          isSolidColor: data.gradient ? false : true,
          customButtonTag: data.customButtonTag || "",
        }))
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const convertMinutesToTime = (minutes) => {
    var m = minutes % 60;
    var h = (minutes - m) / 60;
    var hhmm = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
    return hhmm;
  }

  const convertTimeToMinutes = (hms) => {
    var time = hms.split(':');
    var minutes = (+time[0]) * 60 + (+time[1]);
    return minutes;
  }

  const changeWeekDayHours = (event, name) => {
    if (!event || !name) { return false; }
    const weekDaysModelCopy = [...values.weekDaysModel];
    weekDaysModelCopy.forEach(weekDaysModelCopyItem => {
      if (weekDaysModelCopyItem.name === name) {
        weekDaysModelCopyItem.startTime = event[0];
        weekDaysModelCopyItem.endTime = event[1];
      }
    })
    setValues((values) => ({ ...values, weekDaysModelCopy }))
  }

  const copyScript = () => {
    const copiedText = document.querySelector(".script-tag");
    navigator.clipboard.writeText(copiedText.innerText);
    setIsShowCopiedMessage(true);
    setTimeout(() => {
      setIsShowCopiedMessage(false)
    }, 1000);
  }

  const onWidgetPositionChange = (position) => {
    setValues((values) => ({ ...values, position }))
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
  }

  const _onChange = (event, name, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues(() => ({
      ...values,
      [name]: event.target.value
    }))
  }

  const interpolateColor = (color1, color2) => {
    if (!color2) { color2 = "#00266C" };
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);
    const r = Math.round((r1 + r2) / 2);
    const g = Math.round((g1 + g2) / 2);
    const b = Math.round((b1 + b2) / 2);
    setWidgetGradientColor(`#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`)
  }

  const removeFAQ = (faq) => {
    let newFaqs = [...values.widgetFAQs];
    newFaqs = newFaqs.filter(faqItem => faqItem !== faq);
    setValues((values) => ({ ...values, widgetFAQs: newFaqs }));
  }

  const addCurrentFAQinarray = (event) => {
    event && event.preventDefault();
    event && event.stopPropagation();
    if (currentFAQ.length && currentFAQ.trim().length) {
      let newFaqs = [...values.widgetFAQs, currentFAQ];
      setValues((values) => ({ ...values, widgetFAQs: newFaqs }));
      setCurrentFAQ("");
    }
  }

  const scrollToInvalidFieldPosition = () => {
    setTimeout(() => {
      let firstInvalidControl =
        document.querySelector(".select-fail") ||
        document.querySelector(".error-border");
      firstInvalidControl && window.scrollTo({
        top: firstInvalidControl?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
        behavior: "smooth"
      });
    }, 200);
  }

  const onChangeColor = (color) => {
    setValues((values) => ({ ...values, color }));
    values.isGradient && interpolateColor(color, widgetGradientColor);
  }

  const onChangeWidgetTextColor = (color) => {
    setValues((values) => ({ ...values, textColor: color }))
    if (document.querySelectorAll("#vndPlusIcon").length) {
      document.querySelectorAll("#vndPlusIcon").forEach(element => {
        element?.style.setProperty('--white-color', color);
      })
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();
    let newValues = { ...values };
    if (!newValues.companyName.trim().length || !newValues.domainName.trim().length) {
      setIsInvalidSubmit(true);
      scrollToInvalidFieldPosition();
      return false;
    }

    let widgetSchedules = [];
    if (newValues.isGradient) {
      newValues.gradient = widgetGradientColor;
    }
    newValues.weekDaysModel.forEach(item => {
      if (item.checked) {
        widgetSchedules.push({
          weekDay: item.name,
          startTime: convertMinutesToTime(item.startTime),
          endTime: convertMinutesToTime(item.endTime),
        })
      }
    })
    newValues.widgetSize = newValues.widgetSize === "3em" ? 48 : newValues.widgetSize === "4em" ? 64 : newValues.widgetSize === "5em" ? 80 : 64;
    newValues.isFullWeek = newValues.workingGraphicId === 1 ? false : true;
    if (widgetSchedules.length && newValues.workingGraphicId === 1) { newValues.widgetSchedules = widgetSchedules }
    if (newValues.workingGraphicId !== 1) {
      newValues.timeZoneId = null;
    }

    setButtonSpinner(buttonSpinnerId);
    ApiService.updateWidgetData(newValues).then(() => {
      AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_WIDGET")} ${translationService.translate("TR_UPDATED_SUCCESSFULLY")}`)
      extractButtonSpinner(buttonSpinnerId);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };


  return user && translationService ? <aside className='right-section'>
    <section className="top-section">
      <div className="container">
        {
          values.id ?
            <Auxiliary>
              <div className="row">
                <div className="col-12">
                  <div className="vnd--aside-title-block">
                    <div className="backspace">
                      <span
                        className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                        onClick={() => {
                          dispatch(setActiveSection("home"))
                          window.history.back();
                        }}
                      >
                        <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                      </span>
                    </div>
                    <span className="slash">|</span>
                    <p className="vnd--upp-text-22">{translationService.translate("TR_WIDGET_SETTINGS")}</p>
                  </div>
                </div>
              </div>
              <hr className='mt-0' />
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className='vnd--section-title-wrapper'>
                      <p className="vnd--upp-text-20">{translationService.translate("TR_INSTALLATION")}</p>
                      <span className="font-size-14">{translationService.translate("TR_SCRIPT_MESSAGE")}</span>
                    </div>
                    <div className="vnd--m-block light-gray-background my-2">
                      {
                        script ?
                          <Auxiliary>
                            <div className="vnd-input-button">
                              <span className="vnd-text-input script-tag">{script}</span>
                              <button
                                className={`vnd-btn vnd-secondary-btn vnd-small-btn ${isShowCopiedMessage ? "copied" : ""}`}
                                onClick={copyScript}
                                disabled={isShowCopiedMessage ? true : false}
                              >
                                {`${isShowCopiedMessage ? `${translationService.translate("TR_COPIED")} !` : ''}`}
                                <i className="ri-file-copy-line"></i>
                              </button>
                            </div>
                            <p className='font-size-14 mt-3 mb-0'>{translationService.translate("TR_WG_SCRIPT_TEXT")}</p>
                          </Auxiliary>
                          : null
                      }
                    </div>
                    <hr />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className="vnd--section-title-wrapper">
                      <p className="vnd--upp-text-20">{translationService.translate("TR_COMPANY_DETAILS")}</p>
                      <span className="font-size-14">{translationService.translate("TR_COMPANY_DETAILS_TEXT")}</span>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className='light-brand-background px-4 py-3 vnd-block mb-3'>
                          <Input
                            id="companyName"
                            htmlFor="companyName"
                            type="text"
                            name="companyName"
                            labelValue={`${translationService.translate("TR_COMPANY_NAME")} *`}
                            labelClassName="capitalize cursor-pointer font-weight-bold"
                            placeholder={`${PROJECT_NAME} LLC`}
                            value={values.companyName}
                            inputClassName={`${isInvalidSubmit && !values.companyName.trim().length ? "error-border" : ""}`}
                            blockClassName="mt-2"
                            onChange={(event) => {
                              _onChange(event, "companyName", fieldLengths.maxLength_50);
                              setIsInvalidSubmit(false);
                            }}
                          />
                          <Input
                            id="domainName"
                            htmlFor="domainName"
                            type="text"
                            name="domainName"
                            labelValue={`${translationService.translate("TR_WEBSITE_URLS")} *`}
                            labelClassName="capitalize cursor-pointer font-weight-bold"
                            placeholder="www.sample.com"
                            value={values.domainName}
                            inputClassName={`${isInvalidSubmit && !values.domainName.trim().length ? "error-border" : ""}`}
                            blockClassName="mt-2"
                            onChange={event => _onChange(event, "domainName", fieldLengths.maxLength_200)}
                          />
                          {
                            languages && languages.length ?
                              <div>
                                <label className='form-control-label capitalize font-weight-bold'>{translationService.translate("TR_WIDGET_LANGUAGE")} *</label>
                                <ReactSelectOption
                                  value={values.languageId}
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { ...languages.find(data => data.id === values.languageId) };
                                    if (selectedValue) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                    }
                                    return selectedValue;
                                  })()}
                                  items={languages.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => {
                                    setValues(values => ({
                                      ...values,
                                      languageId: item.value
                                    }))
                                  }}
                                />
                              </div>
                              : null
                          }
                          <hr />
                          <CompanyLogo
                            id={values.id}
                            companyLogoPath={companyLogoPath}
                            setCompanyLogoPath={setCompanyLogoPath}
                            translationService={translationService}
                          />
                        </div>
                        <div className="vnd--m-block light-gray-background">
                          <h4 className="vnd--upp-text-20 vnd--opacity-30 mb-2">{translationService.translate("TR_WIDGET_POSITION")}</h4>
                          <span className="font-size-14">{translationService.translate("TR_WIDGET_POSITION_EXAMPLE")}</span>
                          <p className="font-bold m-0 mt-3">{translationService.translate("TR_SELECTED_POSITION")}:&nbsp;
                            {
                              widgetPositionArr.map((widgetPositionItem, index) => {
                                if (widgetPositionItem.dataPosition === values.position) {
                                  return <span key={index} className="vnd--text-secondary text-uppercase">
                                    {translationService.translate(widgetPositionItem.name) ? translationService.translate(widgetPositionItem.name) : widgetPositionItem.name}
                                  </span>
                                }
                              })
                            }
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <hr className='d-lg-none d-block' />
                        <div className="vnd-laptop-container">
                          <div className="vnd-laptop-wrapper">
                            <img src={Laptop} alt="/" />
                            {
                              widgetPositionArr.map((widgetPositionItem, index) => {
                                return <span
                                  key={index}
                                  data-position={widgetPositionItem.dataPosition}
                                  className={`${widgetPositionItem.className} ${widgetPositionItem.dataPosition === values.position ? "active" : ""}`}
                                  onClick={() => onWidgetPositionChange(widgetPositionItem.dataPosition)}
                                />
                              })
                            }
                          </div>
                          <strong className="vnd-block-subtitle">{translationService.translate("TR_WIDGET_POSITIONS")}</strong>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="vnd--section-title-wrapper">
                      <p className="vnd--upp-text-20">{translationService.translate("TR_WIDGET_UI_SETTINGS")}</p>
                      <span className="font-size-14">{translationService.translate("TR_COMPANY_DETAILS_TEXT")}</span>
                    </div>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='row'>
                          <div className='my-2'>
                            <div className={`vnd--m-block vnd-border h-100`}>
                              <div className={`form-group mb-0 ${values.isGradient ? " disabled" : ""}`}>
                                <label className='font-bold' htmlFor="">{translationService.translate("TR_CHOOSE_WIDGET_COLOR")}</label>
                                <div className='d-flex align-items-center'>
                                  <CirclePicker
                                    circleSize={45}
                                    colors={["#EA2027", "#009432", "#00266C", "#B53471", "#F79F1F"]}
                                    onChangeComplete={color => {
                                      onChangeColor(color.hex)
                                    }}
                                  />
                                  <div className='vnd--color-picker w-auto'>
                                    <div className='color-picker' onClick={() => {
                                      document.getElementById('color') && document.getElementById('color').click();
                                    }}>
                                      <input
                                        id="color"
                                        type="color"
                                        value={values.color}
                                        style={{ border: "1px solid #ffffff" }}
                                        onChange={e => {
                                          onChangeColor(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='my-2'>
                            <div className="vnd--m-block light-brand-background h-100">
                              <div className='d-flex justify-content-between flex-wrap'>
                                <div className="form-group">
                                  <label className='font-bold'>{translationService.translate("TR_CHOOSE_WIDGET_COLOR_STYLE")} *</label>
                                  <div className="d-flex align-items-center">
                                    <div className="custom-control custom-radio mx-2">
                                      <input
                                        id="isSolidColor"
                                        name="choose_color_style"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={values.isSolidColor ? true : false}
                                        onChange={() => {
                                          setValues((values) => ({ ...values, isSolidColor: true, isGradient: false }));
                                          setWidgetGradientColor(null);
                                        }}
                                      />
                                      <label className="custom-control-label capitalize" htmlFor="isSolidColor">
                                        {translationService.translate("TR_SOLID_COLOR")}
                                      </label>
                                    </div>
                                    <div className="custom-control custom-radio mx-2">
                                      <input
                                        id="isGradient"
                                        name="choose_color_style"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={values.isGradient ? true : false}
                                        onChange={() => {
                                          setValues((values) => ({ ...values, isSolidColor: false, isGradient: true }));
                                          interpolateColor(values.color, widgetGradientColor);
                                        }}
                                      />
                                      <label className="custom-control-label capitalize" htmlFor="isGradient">
                                        {translationService.translate("TR_GRADIENT")}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${!values.isGradient ? "disabled" : ""}`}>
                                  <label className='font-bold'>{translationService.translate("TR_CHOOSE_WIDGET_GRADIENT")}</label>
                                  <div className='d-flex align-items-center'>
                                    <CirclePicker
                                      circleSize={45}
                                      colors={["#00266C", "#F79F1F"]}
                                      onChangeComplete={color => {
                                        setWidgetGradientColor(color.hex);
                                      }}
                                    />
                                    <div className='vnd--color-picker w-auto'>
                                      <div className='color-picker' onClick={() => {
                                        document.getElementById('gradient') && document.getElementById('gradient').click();
                                      }}>
                                        <input
                                          id="gradient"
                                          type="color"
                                          value={widgetGradientColor || ""}
                                          style={{ border: "1px solid #ffffff" }}
                                          onChange={e => {
                                            setWidgetGradientColor(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='vnd--color-picker w-auto'>
                                      <div
                                        className='color-picker change-gradient-colors m-0'
                                        onClick={changeGradientColors}
                                        title={translationService.translate("TR_CHANGE_GRADIENT_COLORS")}
                                      >
                                        <TiArrowSync fontSize={24} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 my-2'>
                            <div className="vnd--m-block vnd-border h-100">
                              <div className="form-group mb-0">
                                <label className='font-bold' htmlFor="">{translationService.translate("TR_CHOOSE_WIDGET_TEXT_COLOR")} *</label>
                                <div className='d-flex align-items-center'>
                                  <CirclePicker
                                    circleSize={45}
                                    colors={["#ffffff", "#000000"]}
                                    onChangeComplete={color => {
                                      onChangeWidgetTextColor(color.hex);
                                    }}
                                  />
                                  <div className='vnd--color-picker w-auto'>
                                    <div className='color-picker' onClick={() => {
                                      document.getElementById('textColor') && document.getElementById('textColor').click();
                                    }}>
                                      <input
                                        id="textColor"
                                        type="color"
                                        value={values.gra}
                                        style={{ border: "1px solid #ffffff" }}
                                        onChange={e => {
                                          onChangeWidgetTextColor(e.target.value)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 my-2'>
                            <div className="vnd--m-block vnd-border h-100">
                              <div className="form-group mb-0">
                                <div className={!values.useWidgetButton ? "disabled" : ""}>
                                  <label className='font-bold' htmlFor="">{translationService.translate("TR_WIDGET_BUTTON_SIZE")}</label>
                                  <div className="d-flex align-items-center flex-wrap">
                                    <div className="custom-control custom-radio m-2">
                                      <input
                                        id="isSmallPlusButton"
                                        name="choose_button_size"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={values.widgetSize === "3em" ? true : false}
                                        onChange={() => {
                                          setValues((values) => ({ ...values, widgetSize: "3em" }))
                                          set_widget_size("widget_small_size");
                                        }}
                                      />
                                      <label className="custom-control-label capitalize"
                                        htmlFor="isSmallPlusButton">{translationService.translate("TR_SMALL")}</label>
                                    </div>
                                    <div className="custom-control custom-radio m-2">
                                      <input
                                        id="isMediumPlusButton"
                                        name="choose_button_size"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={values.widgetSize === "4em" ? true : false}
                                        onChange={() => {
                                          setValues((values) => ({ ...values, widgetSize: "4em" }))
                                          set_widget_size("widget_medium_size");
                                        }}
                                      />
                                      <label className="custom-control-label capitalize"
                                        htmlFor="isMediumPlusButton">{translationService.translate("TR_MEDIUM")}</label>
                                    </div>
                                    <div className="custom-control custom-radio m-2">
                                      <input
                                        id="isLargePlusButton"
                                        name="choose_button_size"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={values.widgetSize === "5em" ? true : false}
                                        onChange={() => {
                                          setValues((values) => ({ ...values, widgetSize: "5em" }))
                                          set_widget_size("widget_large_size");
                                        }}
                                      />
                                      <label className="custom-control-label capitalize"
                                        htmlFor="isLargePlusButton">{translationService.translate("TR_LARGE")}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Widget
                        isActiveCircularMenu={isActiveFirstWidgetCircularMenu}
                        setIsActiveCircularMenu={setIsActiveFirstWidgetCircularMenu}
                        setIsShowWidget={setIsShowFirstWidget}
                        isShowWidget={isShowFirstWidget}
                        widget_size={widget_size}
                        widgetGradientColor={widgetGradientColor}
                        welcomeMessageTitleLibrary={welcomeMessageTitleLibrary}
                        welcomeSubMessageTitleLibrary={welcomeSubMessageTitleLibrary}
                        color={values.color}
                        textColor={values.textColor}
                        widgetSize={values.widgetSize}
                        widgetFAQs={values.widgetFAQs}
                        welcomeMessage={values.welcomeMessage}
                        welcomeSubMessage={values.welcomeSubMessage}
                        welcomeMessageLibDataId={values.welcomeMessageLibDataId}
                        welcomeSubMessageLibDataId={values.welcomeSubMessageLibDataId}
                        translationService={translationService}
                        useWidgetButton={values.useWidgetButton}
                      />
                      {
                        MainService.checkServicePermission(subscriptionService, "custom_button_service") ?
                          <div className='col-12'>
                            <div className='my-2'>
                              <div className="vnd--m-block light-brand-background">
                                <div className='form-group mb-0'>
                                  <label className='font-bold' htmlFor="">{translationService.translate("TR_CUSTOM_BTN_CONFIG")}</label>
                                  <div>
                                    <div className='mb-3'>
                                      <CheckBoxInput
                                        id="useCustomeButton"
                                        htmlFor="useCustomeButton"
                                        labelValue={translationService.translate("TR_CUSTOM_BTN_TITLE")}
                                        checked={values.isCustomButton}
                                        onChange={(event) => {
                                          if (!event.target.checked && !values.useWidgetButton) {
                                            setValues((values) => ({ ...values, useWidgetButton: true }))
                                          }
                                          setValues((values) => ({ ...values, isCustomButton: event.target.checked }))
                                        }}
                                      />
                                      <small className='mt-0 ml-4 font-weight-light'>{translationService.translate("TR_CUSTOM_BTN_TEXT")}</small>
                                    </div>
                                    <div className={`mb-3 ${!values.isCustomButton ? "disabled" : ""}`}>
                                      <Input
                                        id="customButtonTag"
                                        htmlFor="customButtonTag"
                                        type="text"
                                        name="customButtonTag"
                                        labelValue={`${translationService.translate("TR_CUSTOM_BTN_TAG")}`}
                                        placeholder={translationService.translate("TR_CUSTOM_BTN_TAG_PLACEHOLDER")}
                                        labelClassName="capitalize cursor-pointer"
                                        value={values.customButtonTag}
                                        disabled={!values.isCustomButton}
                                        onChange={(event) => {
                                          _onChange(event, "customButtonTag", fieldLengths.maxLength_50);
                                          setIsInvalidSubmit(false);
                                        }}
                                      />
                                    </div>
                                    {
                                      MainService.checkServicePermission(subscriptionService, "hide_custom_button_service") ?
                                        <div className='mb-3'>
                                          <CheckBoxInput
                                            id="useWidgetButton"
                                            htmlFor="useWidgetButton"
                                            checked={values.useWidgetButton}
                                            labelValue={translationService.translate("TR_WIDGET_BTN_TITLE")}
                                            onChange={(event) => {
                                              if (!event.target.checked && !values.isCustomButton) {
                                                setValues((values) => ({ ...values, isCustomButton: true }))
                                              }
                                              setValues((values) => ({ ...values, useWidgetButton: event.target.checked, }))
                                            }}
                                          />
                                          <small className='mt-0 ml-4 font-weight-light'>{Parser(translationService.translate("TR_WIDGET_BTN_TEXT"))}</small>
                                        </div>
                                        : null
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> : null
                      }
                    </div>
                    <hr />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="vnd--section-title-wrapper">
                      <p className="vnd--upp-text-20">{translationService.translate("TR_WM_AND_FAQ")}</p>
                      <span className="font-size-14">{translationService.translate("TR_WELCOME_MESSAGE_INFO")}:</span>
                    </div>
                    <div className='row' style={{ minHeight: "500px" }}>
                      <div className='col-lg-6'>
                        <div className='row'>
                          <div className='col-12 my-2'>
                            <div className="vnd--m-block light-brand-background h-100">
                              <Tabs
                                activeKey={key}
                                onSelect={(key) => { setKey(key) }}
                              >
                                <Tab eventKey={1} title={`${values.welcomeMessageTitle}`}>
                                  {
                                    welcomeMessageTitleLibrary && welcomeMessageTitleLibrary.length ?
                                      welcomeMessageTitleLibrary.map((item, index) => {
                                        return <div key={index} className="custom-control custom-radio form-group my-2">
                                          <input
                                            id={item.id}
                                            type="radio"
                                            name="welcome_message"
                                            className="custom-control-input"
                                            checked={values.welcomeMessageLibDataId === item.id ? true : false}
                                            onChange={() => {
                                              setValues((values) => ({ ...values, welcomeMessage: "", welcomeMessageLibDataId: item.id }))
                                              setIsInvalidSubmit(false);
                                              if (item.name === 'WELCOME_CUSTOM') setIsShowWelcomeMessageTextarea(true);
                                              else setIsShowWelcomeMessageTextarea(false);
                                            }}
                                          />
                                          <label className="custom-control-label" htmlFor={item.id}>{item.displayName}</label>
                                        </div>
                                      })
                                      : null
                                  }

                                  {
                                    isShowWelcomeMessageTextarea ?
                                      <div className='position-relative'>
                                        <div className='emoji-block widget-settings'>
                                          <ButtonDropdown
                                            className="emoji-dropdown"
                                            direction="up"
                                            isOpen={isOpenWelcomeMessageEmojies}
                                            toggle={toggleWelcomeMessageEmojies}
                                          >
                                            <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                              <i className="ri-emotion-happy-line"></i>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                              <Picker onSelect={(e) => addEmoji(e, "welcomeMessage", values.welcomeMessage, fieldLengths.maxLength_30)} />
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                          <UncontrolledTooltip target="emoji" placement="top">
                                            {translationService.translate("TR_EMOJI")}
                                          </UncontrolledTooltip>
                                        </div>
                                        <Textarea
                                          id="welcome_text"
                                          htmlFor="welcome_text"
                                          value={values.welcomeMessage}
                                          labelValue={translationService.translate("TR_MESSAGE")}
                                          labelClassName="cursor-pointer capitalize"
                                          placeholder={`${translationService.translate("TR_MESSAGE_PLACEHOLDER")}...`}
                                          rows="3"
                                          className="resize-none"
                                          isInvalidField={welcomeMessageTitleLibrary.find(libraryItem => isInvalidSubmit && libraryItem.name === "WELCOME_CUSTOM" && libraryItem.id === values.welcomeMessageLibDataId && !values.welcomeMessage.trim().length)}
                                          onChange={(event) => _onChange(event, "welcomeMessage", fieldLengths.maxLength_50)}
                                        />
                                      </div>

                                      : null
                                  }
                                </Tab>
                                <Tab eventKey={2} title={`${values.welcomeSubMessageTitle}`}>
                                  <Auxiliary>
                                    {
                                      welcomeSubMessageTitleLibrary && welcomeSubMessageTitleLibrary.length ?
                                        welcomeSubMessageTitleLibrary.map((item, index) => {
                                          return <div key={index} className="custom-control custom-radio form-group my-2">
                                            <input
                                              id={item.id}
                                              type="radio"
                                              name="welcome_sub_message"
                                              className="custom-control-input"
                                              checked={values.welcomeSubMessageLibDataId === item.id ? true : false}
                                              onChange={() => {
                                                setValues((values) => ({ ...values, welcomeSubMessageLibDataId: item.id, welcomeSubMessage: "" }))
                                                setIsInvalidSubmit(false);
                                                if (item.name === 'WELCOME_SUB_CUSTOM') setIsShowWelcomeSubMessageTextarea(true);
                                                else setIsShowWelcomeSubMessageTextarea(false);
                                              }}
                                            />
                                            <label className="custom-control-label" htmlFor={item.id}>{item.displayName}</label>
                                          </div>
                                        })
                                        : null
                                    }
                                    {
                                      isShowWelcomeSubMessageTextarea ?
                                        <div className='position-relative'>
                                          <div className='emoji-block widget-settings'>
                                            <ButtonDropdown
                                              className="emoji-dropdown"
                                              direction="up"
                                              isOpen={isOpenWelcomeSubMessageEmojies}
                                              toggle={toggleWelcomeSubMessageEmojies}
                                            >
                                              <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                                <i className="ri-emotion-happy-line"></i>
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-end">
                                                <Picker onSelect={(e) => addEmoji(e, "welcomeSubMessage", values.welcomeSubMessage, fieldLengths.maxLength_30)} />
                                              </DropdownMenu>
                                            </ButtonDropdown>
                                            <UncontrolledTooltip target="emoji" placement="top">
                                              {translationService.translate("TR_EMOJI")}
                                            </UncontrolledTooltip>
                                          </div>
                                          <Textarea
                                            id="welcome_sub_text"
                                            htmlFor="welcome_sub_text"
                                            value={values.welcomeSubMessage}
                                            labelValue={translationService.translate("TR_MESSAGE")}
                                            labelClassName="cursor-pointer capitalize"
                                            placeholder={`${translationService.translate("TR_MESSAGE_PLACEHOLDER")}...`}
                                            rows="3"
                                            className="resize-none"
                                            isInvalidField={welcomeSubMessageTitleLibrary.find(subLibraryItem => isInvalidSubmit && subLibraryItem.name === "WELCOME_SUB_CUSTOM" && subLibraryItem.id === values.welcomeSubMessageLibDataId && !values.welcomeSubMessage.trim().length)}
                                            onChange={(event) => _onChange(event, "welcomeSubMessage", fieldLengths.maxLength_100)}
                                          />
                                        </div>

                                        : null
                                    }
                                  </Auxiliary>
                                </Tab>
                              </Tabs>
                            </div>
                          </div>
                          <div className='col-12 my-2'>
                            <div className="vnd--m-block light-gray-background h-100">
                              <div className="form-group mb-0">
                                <label htmlFor="currentFAQ" className='cursor-pointer capitalize font-bold'>
                                  {translationService.translate("TR_CREATE")} {translationService.translate("TR_FAQ")}
                                </label>
                                <div className="vnd-complexe-input d-flex">
                                  <input
                                    id='currentFAQ'
                                    name="currentFAQ"
                                    type="text"
                                    placeholder={`${translationService.translate("TR_CREATE")} ${translationService.translate("TR_FAQ")}`}
                                    value={currentFAQ}
                                    onChange={event => onChange(event, setCurrentFAQ, fieldLengths.maxLength_120)}
                                    onKeyDown={event => {
                                      if (event.key === "Enter") {
                                        addCurrentFAQinarray(event);
                                      }
                                    }}
                                  />
                                  <div className="vnd-complexe-btn" onClick={addCurrentFAQinarray}>
                                    <i className="ri-add-line text-white font-size-20"></i>
                                  </div>
                                </div>
                                <div className="vnd-added-item-container">
                                  {
                                    values.widgetFAQs && values.widgetFAQs.length ?
                                      values.widgetFAQs.map((item, index) => <div key={index} className="vnd-added-item-wrapper word-break-break-word">
                                        <span>{item}</span>
                                        <img src={CloseIcon} alt="/" onClick={() => removeFAQ(item)} />
                                      </div>)
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Widget
                        isActiveCircularMenu={isActiveSecondWidgetCircularMenu}
                        setIsActiveCircularMenu={setIsActiveSecondWidgetCircularMenu}
                        setIsShowWidget={setIsShowSecondWidget}
                        isShowWidget={isShowSecondWidget}
                        widget_size={widget_size}
                        widgetGradientColor={widgetGradientColor}
                        welcomeSubMessageTitleLibrary={welcomeSubMessageTitleLibrary}
                        color={values.color}
                        textColor={values.textColor}
                        widgetSize={values.widgetSize}
                        widgetFAQs={values.widgetFAQs}
                        welcomeMessage={values.welcomeMessage}
                        welcomeSubMessage={values.welcomeSubMessage}
                        welcomeMessageTitleLibrary={welcomeMessageTitleLibrary}
                        welcomeMessageLibDataId={values.welcomeMessageLibDataId}
                        welcomeSubMessageLibDataId={values.welcomeSubMessageLibDataId}
                        translationService={translationService}
                        useWidgetButton={values.useWidgetButton}
                      />
                    </div>
                    <hr />
                  </div>
                </div>
                {
                  MainService.checkServicePermission(subscriptionService, "schedule_service") && values.weekDaysModel && values.weekDaysModel.length ?
                    <div className="row">
                      <div className="col-12">
                        <div className="vnd--section-title-wrapper">
                          <p className="vnd--upp-text-20">{translationService.translate("TR_SCHEDULE")}</p>
                          <span className="font-size-14">{translationService.translate("TR_SCHEDULE_TEXT")}</span>
                        </div>
                        <div className="row">
                          <div className='col-12'>
                            <div className="h-100">
                              <div className='vnd--m-block vnd--borderd'>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='d-flex'>
                                    {
                                      workingGraphic.map(item => {
                                        return <div key={item.id} className="custom-control custom-radio mx-2">
                                          <input
                                            id={`workingGraphic_${item.id}`}
                                            name="working_hours"
                                            type="radio"
                                            className="custom-control-input"
                                            checked={values.workingGraphicId === item.id ? true : false}
                                            onChange={() => setValues((values) => ({ ...values, workingGraphicId: item.id }))}
                                          />
                                          <label className="custom-control-label font-bold" htmlFor={`workingGraphic_${item.id}`}>
                                            {translationService.translate(item.name) ? translationService.translate(item.name) : item.name}
                                          </label>
                                        </div>
                                      })
                                    }
                                  </div>
                                  {
                                    values.workingGraphicId !== 1 ? null :
                                      <ReactSelectOption
                                        value={values.timeZoneId}
                                        className={`w-50`}
                                        selectedValue={(() => {
                                          const selectedValue = { ...timeZones.find(data => data.id === values.timeZoneId) };
                                          if (selectedValue) {
                                            selectedValue.label = selectedValue.displayName;
                                            selectedValue.value = selectedValue.id;
                                          }
                                          return selectedValue;
                                        })()}
                                        items={timeZones.map(data => ({ label: data.displayName, value: data.id }))}
                                        onChange={(item) => setValues((values) => ({ ...values, timeZoneId: item.value }))}
                                      />
                                  }
                                </div>
                              </div>
                              {
                                values.workingGraphicId === 1 ?
                                  <div className='week-days-block mt-4'>
                                    {
                                      values.weekDaysModel.map(item => {
                                        return <div key={item.id} className='week-days-block-item border-bottom'>
                                          <div>
                                            <div className='row'>
                                              <div className='col-md-4 order-12 order-md-0 d-flex align-items-center'>
                                                <CheckBoxInput
                                                  id={`working_hour_graphic_${item.name}`}
                                                  htmlFor={`working_hour_graphic_${item.name}`}
                                                  labelValue={
                                                    translationService.translate(`TR_${(item.displayName).toUpperCase()}`) ?
                                                      translationService.translate(`TR_${(item.displayName).toUpperCase()}`) :
                                                      item.displayName
                                                  }
                                                  checked={item.checked ? true : false}
                                                  onChange={(event) => {
                                                    changeWeekDayChecked(event, item.name)
                                                  }}
                                                />
                                              </div>
                                              <div className={`col-md-8 order-0 order-md-12 2 d-flex align-items-end ${!item.checked ? "disabled" : ""}`}>
                                                <div className='w-100 px-2'>
                                                  <span className='mb-1'>{convertMinutesToTime(item.startTime)} - {convertMinutesToTime(item.endTime)}</span>
                                                  <Slider
                                                    range
                                                    step={30}
                                                    min={0}
                                                    max={1439}
                                                    allowCross={false}
                                                    defaultValue={[item.startTime, item.endTime]}
                                                    tipProps={{ visible: true }}
                                                    onChange={(event) => changeWeekDayHours(event, item.name)}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      })
                                    }
                                  </div>
                                  : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null
                }

                <div className='row'>
                  <div className="col-12 mt-4">
                    <div className="d-flex justify-content-end">
                      <ActionButton
                        type="submit"
                        name={translationService.translate("TR_SAVE_CHANGES")}
                        className="vnd-btn vnd-primary-btn"
                        spinnerId={buttonSpinnerId}
                        onClick={onSubmit}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Auxiliary>
            : <NoData />
        }
      </div>
    </section>
  </aside >
    : null
}

export default WidgetSettings;