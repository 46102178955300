import React, { useState, useEffect, useCallback } from 'react';
import { Card, Label, Form, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import CustomCollapse from "../../components/CustomCollapse";
import { useSelector, useDispatch } from 'react-redux';
import { ERROR_KEY, SUCCESS_KEY, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY, VALID_IMAGE_TYPES_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import MainService from '../../../../Services/mainService';
import * as moment from "moment";
import uuid from 'react-uuid';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner';
import AlertService from '../../../../Services/AlertService';
import ApiService from '../../../../Services/ApiService';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import { updateUserData } from '../../../../Store/Actions/user';
import TranslationService from './../../../../Services/translationService';
import CustomInput from "./../../../../Components/Inputs/Input";
import { AiOutlineUser } from "react-icons/ai";

const avatarSpinnerId = uuid();
const buttonSpinnerId = uuid();

const Settings = (props) => {

    const mainService = new MainService();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const { translations } = useSelector(state => state.translation);
    const [translationService, setTranslationService] = useState(null);
    const [isOpenProfileSettings, setIsOpenProfileSettings] = useState(false);
    const [isOpenSecuritySettings, setIsOpenSecuritySettings] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [file, setFile] = useState(null);
    const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
    const [isInvalidLastName, setIsInvalidLastName] = useState(false);
    const [isInvalidDob, setIsInvalidDob] = useState(false);
    const [isInvalidPassword, setIsInvalidPassword] = useState(false);
    const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
    const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);
    const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
    const [mismatchPasswords, setMismatchPasswords] = useState(false);
    const [isSimilarPasswords, setIsSimilarPasswords] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstname ? user.firstname : "");
            setLastName(user.lastname ? user.lastname : "");
            setDob(user.dob ? user.dob : "");
        }
    }, [user]);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const onChange = (event, cb, maxLength = null) => {
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        setIsInvalidSubmit(false);
        setMismatchPasswords(false);
        setIsSimilarPasswords(false);
    }

    const onDateChange = (event) => {
        setDob(event.target.value);
        setIsInvalidSubmit(false);
    }

    const onCheckDates = () => {
        if (!dob) { return; }
        if (MainService.checkDates(dob, moment(MainService.convertUTCDateToLocalDate(new Date())))) {
            setIsInvalidDob(false);
        } else {
            setIsInvalidDob(true);
        }
    }

    const removeImage = () => {
        if (uploadedFile && !user.avatarPath) {
            setUploadedFile(null);
            setFile(null);
        }
        if (user.avatarPath && !uploadedFile) {
            AlertService.alertConfirm(
                `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
                "",
                translationService.translate("TR_YES"),
                translationService.translate("TR_NO"),
            ).then(() => {
                ApiService.removeUserAvatar().then(() => {
                    const data = { avatarPath: null };
                    dispatch(updateUserData(data));
                    AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
                }).catch(error => getFail(error, buttonSpinnerId))
            })
        }
    }

    const uploadFile = event => {
        if (!event.target.files[0]) { return; }
        const file = event.target.files[0];
        if (file) {
            mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
                setUploadedFile(uploadedImage);
                setFile(file);
            }).catch(error => error && AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_EX")));
        };
    }

    const updateUserImage = (event) => {
        event && event.preventDefault();
        setButtonSpinner(avatarSpinnerId);
        const formData = new FormData();
        formData.append("formFile", file);
        ApiService.updateUserAvatar(formData).then(response => {
            if (response && response.data) {
                const data = {
                    avatarPath: response.data,
                }
                dispatch(updateUserData(data));
            }
            setUploadedFile(null);
            setFile(null);
            extractButtonSpinner(avatarSpinnerId);
            AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
        }).catch(error => getFail(error, avatarSpinnerId))
    }

    const checkFieldsValidation = (field, fieldName) => {
        var isValid = MainService.isValidField(field, fieldName);
        if (fieldName === "newPassword") {
            changeIsinvalidFieldName(isValid, setIsInvalidNewPassword);
        }
        if (fieldName === "confirmPassword") {
            changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
        }
        if (fieldName === "newPassword" || fieldName === "confirmPassword") {
            if (newPassword.trim().length && confirmPassword.trim().length && (newPassword !== confirmPassword)) {
                changeIsinvalidFieldName(false, setMismatchPasswords);
            } else {
                changeIsinvalidFieldName(true, setMismatchPasswords);
            }
        }
        switch (fieldName) {
            case "firstName":
                changeIsinvalidFieldName(isValid, setIsInvalidFirstName)
                break;
            case "lastName":
                changeIsinvalidFieldName(isValid, setIsInvalidLastName)
                break;
            case "password":
                changeIsinvalidFieldName(isValid, setIsInvalidPassword)
                break;
            default:
                break;
        }
    }

    const changeIsinvalidFieldName = (isValid, cb) => {
        if (!isValid) cb(true);
        else cb(false);
    }

    const toggleProfileCollapse = () => {
        extractButtonSpinner(buttonSpinnerId);
        setIsOpenProfileSettings(!isOpenProfileSettings);
        setIsInvalidSubmit(false);
        setIsInvalidFirstName(false);
        setIsInvalidLastName(false);
        setIsInvalidDob(false);
        setIsOpenSecuritySettings(false);
    };

    const toggleSecurityCollapse = () => {
        extractButtonSpinner(buttonSpinnerId);
        setIsOpenSecuritySettings(!isOpenSecuritySettings);
        setIsOpenProfileSettings(false);
        setPassword("");
        setConfirmPassword("");
        setNewPassword("");
        setIsInvalidSubmit(false);
        setIsInvalidConfirmPassword(false);
        setIsInvalidNewPassword(false);
        setMismatchPasswords(false);
    };


    const updateUserDataLocal = (event) => {
        event && event.preventDefault();
        if (
            isInvalidFirstName ||
            isInvalidLastName ||
            isInvalidDob ||
            !firstName.trim().length ||
            !lastName.trim().length
        ) {
            setIsInvalidSubmit(true);
            return;
        } else {
            setButtonSpinner(buttonSpinnerId);
            const form = {
                firstname: firstName.trim(),
                lastname: lastName.trim(),
                dob,
            };
            if (!form.dob) {
                delete form.dob;
            }
            ApiService.updateUser(form).then(() => {
                form.fullName = `${form.firstname} ${form.lastname}`
                dispatch(updateUserData(form));
                AlertService.alert(SUCCESS_KEY, `${translationService.translate("TR_DATA")} ${translationService.translate("TR_SAVED")}`)
                extractButtonSpinner(buttonSpinnerId);
                setIsFormChanged(false);
            }).catch(error => getFail(error, buttonSpinnerId));
        }
    }

    const updateUserPassword = (event) => {
        event && event.preventDefault();
        if (!password.trim() || !newPassword.trim() || !confirmPassword.trim()) {
            setIsInvalidSubmit(true);
            return;
        } else {
            if (newPassword !== confirmPassword) {
                setMismatchPasswords(true)
                return false;
            } else {
                if (password.trim() === newPassword.trim()) {
                    setIsSimilarPasswords(true);
                    return false;
                } else {
                    const form = {
                        oldPassword: password,
                        password: btoa(newPassword),
                        confirmPassword: btoa(confirmPassword),
                    }
                    setButtonSpinner(buttonSpinnerId);
                    ApiService.changeOldPassword(form).then(() => {
                        extractButtonSpinner(buttonSpinnerId);
                        setPassword("");
                        setNewPassword("");
                        setConfirmPassword("")
                        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PASSWORD_CHANGED"));
                    }).catch(error => getFail(error, buttonSpinnerId));
                }
            }
        }
    }

    const getToday = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        return today = yyyy + '-' + mm + '-' + dd;
    }

    const setButtonSpinner = useCallback(spinner => {
        dispatch(addButtonSpinner(spinner));
    }, []);

    const extractButtonSpinner = useCallback(spinner => {
        dispatch(removeButtonSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractButtonSpinner(spinnerId);
    };

    return translationService ? <React.Fragment>
        <div>
            <div className="p-4 d-flex justify-content-between align-items-center">
                <h4 className="vnd--upp-text-20">{translationService.translate("TR_SETTINGS")}</h4>
            </div>
            <div className="text-center border-bottom p-4">
                <div className="mb-4 profile-user">

                    {
                        !user.avatarPath && !uploadedFile ?
                            <div className="chat-avatar avatar-lg">
                                <div className="chat-user-img align-self-center">
                                    <div className="avatar-xs mb-2">
                                        <span
                                            className="avatar-title rounded-circle bg-soft-primary vnd--text-primary"
                                            style={{ width: "6rem", height: "6rem", fontSize: "35px" }}
                                        >
                                            {user.fullName ? user.fullName.charAt(0) : user.clientName ? user.clientName.charAt(0) : <AiOutlineUser />}
                                        </span>

                                    </div>
                                </div>
                                <button onClick={MainService.triggerUploadClick} type="button" className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                    <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
                                    <i className="ri-pencil-fill"></i>
                                </button>
                            </div>
                            : null
                    }
                    {
                        user.avatarPath || uploadedFile ?
                            <div className="chat-avatar avatar-lg">
                                <div className="chat-user-img align-self-center">
                                    <div className="avatar-lg mb-2">
                                        <div
                                            className="vnd-supporter-avatar-img"
                                            style={{ backgroundImage: `url(${uploadedFile ? uploadedFile : user.avatarPath ? user.avatarPath : null})` }}
                                        />
                                        <button
                                            onClick={removeImage}
                                            type="button"
                                            className="avatar-xs p-0 rounded-circle profile-photo-remove d-flex justify-content-center align-items-center">
                                            <i className="ri-close-fill"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>

                <h5 className="font-size-18 mb-1 text-truncate">
                    {user.fullName ? user.fullName : user.clientName ? user.clientName : ""}
                </h5>
                {
                    uploadedFile ?
                        <div className='d-flex justify-content-center'>
                            <ActionButton
                                type="button"
                                name={translationService.translate("TR_SAVE")}
                                className="vnd-primary-btn vnd-btn vnd-small-btn mt-3"
                                spinnerId={avatarSpinnerId}
                                onClick={updateUserImage}
                            />
                        </div>
                        : null
                }
            </div>

            <SimpleBar style={{ maxHeight: "100%" }} className="p-4 user-profile-desc">
                <div id="profile-setting-accordion" className="custom-accordion">

                    {
                        user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ?
                            <Auxiliary>
                                <Card className="shadow-none border mb-2">
                                    <CustomCollapse
                                        title={translationService.translate("TR_PROFILE")}
                                        isOpen={isOpenProfileSettings}
                                        toggleCollapse={toggleProfileCollapse}
                                    >

                                        <Form onSubmit={updateUserDataLocal} onChange={() => setIsFormChanged(true)}>
                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_FIRST_NAME")} *</Label>
                                                <CustomInput
                                                    type='text'
                                                    className={`${((isInvalidSubmit && !firstName.trim()) || isInvalidFirstName) ? "error-border" : ""}`}
                                                    value={firstName}
                                                    onChange={(event) => onChange(event, setFirstName, fieldLengths.maxLength_100)}
                                                    onBlur={() => checkFieldsValidation(firstName, "firstName")}
                                                />
                                            </div>

                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_LAST_NAME")} *</Label>
                                                <CustomInput
                                                    type='text'
                                                    className={`${((isInvalidSubmit && !lastName.trim()) || isInvalidLastName) ? "error-border" : ""}`}
                                                    value={lastName}
                                                    onChange={(event) => onChange(event, setLastName, fieldLengths.maxLength_100)}
                                                    onBlur={() => checkFieldsValidation(lastName, "lastName")}
                                                />
                                            </div>

                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_EMAIL")} *</Label>
                                                <CustomInput
                                                    type='email'
                                                    value={user.email}
                                                    disabled={true}
                                                    onChange={() => { }}
                                                />
                                            </div>

                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_DOB")}</Label>
                                                <CustomInput
                                                    type='date'
                                                    value={dob}
                                                    min="1900-01-01"
                                                    max={getToday()}
                                                    className={`${isInvalidDob ? "error-border" : ""}`}
                                                    onChange={onDateChange}
                                                    onBlur={onCheckDates}
                                                />
                                            </div>

                                            <div className='d-flex justify-content-end'>
                                                <ActionButton
                                                    type="submit"
                                                    disabled={isInvalidFirstName || isInvalidLastName || isInvalidDob || !isFormChanged}
                                                    name={translationService.translate("TR_SAVE")}
                                                    className={`vnd-btn vnd-primary-btn vnd-small-btn ${isInvalidFirstName || isInvalidLastName || isInvalidDob || !isFormChanged ? "disabled" : ""}`}
                                                    spinnerId={buttonSpinnerId}
                                                />
                                            </div>
                                        </Form>
                                    </CustomCollapse>
                                </Card>

                                <Card className="shadow-none border mb-2">
                                    <CustomCollapse
                                        title={translationService.translate("TR_CHANGE_PASSWORD")}
                                        isOpen={isOpenSecuritySettings}
                                        toggleCollapse={toggleSecurityCollapse}
                                    >

                                        <Form onSubmit={updateUserPassword}>
                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_PASSWORD")} *</Label>
                                                <CustomInput
                                                    type='password'
                                                    className={`${(isInvalidSubmit && !password.trim()) || isInvalidPassword ? "error-border" : ""}`}
                                                    value={password}
                                                    onChange={(event) => {
                                                        onChange(event, setPassword, fieldLengths.maxLength_100);
                                                        setIsInvalidSubmit(false);
                                                    }}
                                                    onBlur={() => checkFieldsValidation(password, "password")}
                                                />
                                            </div>


                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_NEW")} {translationService.translate("TR_PASSWORD")} *</Label>
                                                <CustomInput
                                                    type='password'
                                                    className={`${(isInvalidSubmit && !newPassword.trim()) || mismatchPasswords || isInvalidNewPassword ? "error-border" : ""}`}
                                                    value={newPassword}
                                                    onChange={(event) => {
                                                        onChange(event, setNewPassword, fieldLengths.maxLength_100);
                                                        setIsInvalidSubmit(false);
                                                    }}
                                                    onBlur={() => checkFieldsValidation(newPassword, "newPassword")}
                                                />
                                            </div>

                                            <div className='form-group '>
                                                <Label className='mb-1 capitalize'>{translationService.translate("TR_CONFIRM")} {translationService.translate("TR_PASSWORD")} *</Label>
                                                <CustomInput
                                                    type='password'
                                                    className={`${isInvalidSubmit && (!confirmPassword.trim() || mismatchPasswords || isInvalidConfirmPassword) ? "error-border" : ""}`}
                                                    value={confirmPassword}
                                                    onChange={(event) => {
                                                        onChange(event, setConfirmPassword, fieldLengths.maxLength_100);
                                                        setIsInvalidSubmit(false);
                                                    }}
                                                    onBlur={() => checkFieldsValidation(confirmPassword, "confirmPassword")}
                                                />
                                            </div>
                                            {
                                                mismatchPasswords ?
                                                    <div><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                                                    : null
                                            }
                                            {
                                                isInvalidConfirmPassword || isInvalidNewPassword ?
                                                    <div><small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', 6).replace('{1}', 100)}</small></div>
                                                    : null
                                            }
                                            {
                                                isSimilarPasswords ?
                                                    <small className="red-color">{translationService.translate("TR_PASSWORD_NEW_AND_OLD")}</small>
                                                    : null
                                            }
                                            <div className='d-flex justify-content-end'>
                                                <ActionButton
                                                    type="submit"
                                                    name={translationService.translate("TR_SAVE")}
                                                    className={`vnd-btn vnd-primary-btn vnd-small-btn ${password.trim() && newPassword.trim() && confirmPassword.trim() && !isInvalidNewPassword && !isInvalidConfirmPassword ? "" : "disabled"}`}
                                                    spinnerId={buttonSpinnerId}
                                                />
                                            </div>
                                        </Form>
                                    </CustomCollapse>
                                </Card>
                            </Auxiliary>
                            : null
                    }

                </div>
            </SimpleBar>
        </div>
    </React.Fragment> : null
}

export default Settings;