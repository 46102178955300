import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import { useLocation, withRouter } from 'react-router-dom';
import UserProfileSidebar from "../../components/UserProfileSidebar";
// import SelectContact from "../../components/SelectContact";
import UserHead from "./UserHead";
import FileMessageComponent from "./FileMessageComponent";
import ChatInput from "./ChatInput";
import ApiService from '../../../../Services/ApiService';
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, FILE_MESSAGE_TYPE_KEY, SCREEN_SHARE_MESSAGE_TYPE_KEY, SUCCESS_KEY, SUPERVISOR_USER_TYPE_ID_KEY, TEXT_MESSAGE_TYPE_KEY, VIDEO_MESSAGE_TYPE_KEY, VOICE_MESSAGE_TYPE_KEY } from '../../../../Constants/MainKeys';
import MainService from '../../../../Services/mainService';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import CompletedScreenShareSvg from '../../../../Svg/CompletedScreenShareSvg';
import MissedVideoCallSvg from '../../../../Svg/MissedVideoCallSvg';
import CompletedVideoCallSvg from '../../../../Svg/CompletedVideoCallSvg';
import CompletedVoiceCallSvg from '../../../../Svg/CompletedVoiceCallSvg';
import MissedScreenShareSvg from '../../../../Svg/MissedScreenShareSvg';
import MissedVoiceCallSvg from '../../../../Svg/MissedVoiceCallSvg';
import TranslationService from '../../../../Services/translationService';
import { PARTNER_USER_TYPE_ID_KEY } from '../../../../Constants/MainKeys';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import uuid from 'react-uuid';
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner';
import {
    addMessageToActiveUserMessages,
    addFieldsToActiveUserDataFromSession,
    changeCurrentUserLastMessage,
    resetCurrentUserUnReadMessagesCount,
    setNewMessageObj,
    updateActiveUserData,
} from "../../../../Store/Actions/chat";
import cwScreenShare from "./../../../../assets/icons/stream-icons/cw-screen-share.svg";
import cwVideoCall from "./../../../../assets/icons/stream-icons/cw-video-call.svg";
import cwVoiceCall from "./../../../../assets/icons/stream-icons/cw-voice-call.svg";
import colored1 from "./../../../../assets/images/svg/1-colored.svg";
import colored2 from "./../../../../assets/images/svg/2-colored.svg";
import colored3 from "./../../../../assets/images/svg/3-colored.svg";
import colored4 from "./../../../../assets/images/svg/4-colored.svg";
import colored5 from "./../../../../assets/images/svg/5-colored.svg";
import Parser from 'html-react-parser';
import useWindowSize from '../../../../Hooks/useWindowSize';
import { AiOutlineUser } from "react-icons/ai";


const buttonSpinnerId = uuid();

const UserChat = (props) => {
    const { pathname } = useLocation();
    const messagesEndRef = useRef(null);
    const dispatch = useDispatch();
    const windowSize = useWindowSize();

    var supporterId = null;
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }

    const user = useSelector(state => state.user.user);
    const { spinners } = useSelector(state => state.spinner);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const { translations } = useSelector(state => state.translation);
    const { partnerId } = useSelector(state => state.main);
    const emojisArray = [
        {
            src: colored1,
            rate: 1,
        },
        {
            src: colored2,
            rate: 2,
        },
        {
            src: colored3,
            rate: 3,
        },
        {
            src: colored4,
            rate: 4,
        },
        {
            src: colored5,
            rate: 5,
        },
    ];

    const {
        activeUserData,
        newMessageObj,
        typerUserName
    } = useSelector(state => state.chat)

    const {
        activeGroupName,
        subscriptionService,
    } = useSelector(state => state.main);

    const [translationService, setTranslationService] = useState(null);
    const [isShowScrollButton, setIsShowScrollButton] = useState(false);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        dispatch(setNewMessageObj(null));
        setIsShowScrollButton(false);
    }

    useEffect(() => {
        if (activeUserData?.messages) {
            setTimeout(() => {
                scrollToBottom();
            }, 1000);
        }
    }, [activeUserData?.messages]);

    useEffect(() => {
        if (document.getElementById('messages') && document.getElementById('messages').querySelector('.simplebar-content-wrapper')) {
            var element = document.getElementById('messages')?.querySelector('.simplebar-content-wrapper');
            element && element.addEventListener("scroll", () => scroll(element));
            return () => {
                element && element.removeEventListener("scroll", () => scroll(element))
            }
        }
    }, [document.getElementById('messages')])

    const scroll = (element) => {
        var chatContainerItem = document.getElementById("chat-container-item");
        if (!element || !chatContainerItem) { return false; }
        if (chatContainerItem.offsetHeight - element.scrollTop < element.offsetHeight) {
            dispatch(setNewMessageObj(null));
        }
    }

    const onSendMessage = (files, textMessage, cbFiles, cbTextMessage, cb) => {
        if (!activeUserData) { return false; }
        if (connection && connection.state === "Connected") {
            if (textMessage && !files.length) {
                var textMessageData = createTextMessageModel(textMessage);
                const messageRequest = {
                    message: textMessage,
                    messageType: TEXT_MESSAGE_TYPE_KEY,
                    timeStampId: textMessageData.timeStampId,
                    senderUserId: user.id,
                    sessionSource: activeUserData.source
                }
                connection.invoke("SendMessageToSession", messageRequest, activeUserData.clientSessionKey, null).then(() => {
                    dispatch(addMessageToActiveUserMessages(textMessageData));
                    dispatch(changeCurrentUserLastMessage(activeUserData.clientSessionKey, textMessageData));
                    cbTextMessage("");
                    scrollToBottom();
                    cb(false);
                }).catch(error => getFail(error, null, cb))

            }
            if (files && files.length) {
                var fileMessageData = createFileMessageModel([...files], textMessage)
                cbFiles([]);
                const filesForSend = [];
                [...files].forEach(file => {
                    filesForSend.push(file.file);
                });
                const formData = new FormData();
                for (let i in filesForSend) {
                    formData.append(`FormFiles`, filesForSend[i]);
                }
                formData.append("ClientSessionKey", activeUserData.clientSessionKey);
                formData.append("PartnerId", user.partnerId ? user.partnerId : partnerId);
                ApiService.sendFiles(formData).then(response => {
                    if (response && response.data) {
                        const data = [...response.data];
                        const messageRequest = {
                            message: textMessage,
                            messageType: TEXT_MESSAGE_TYPE_KEY,
                            timeStampId: fileMessageData.timeStampId,
                            senderUserId: user.id,
                            sessionSource: activeUserData.source,
                        }

                        connection.invoke("SendMessageToSession", messageRequest, activeUserData.clientSessionKey, data).then(() => {
                            dispatch(addMessageToActiveUserMessages(fileMessageData));
                            dispatch(changeCurrentUserLastMessage(activeUserData.clientSessionKey, fileMessageData));
                            cbTextMessage("");
                            scrollToBottom();
                            cb(false);
                        }).catch(error => getFail(error, null, cb));
                        cbFiles([]);
                    }
                }).catch(error => getFail(error, null, cb))
            }
        } else {
            AlertService.alert(ERROR_KEY, translationService.translate("TR_CONNECTION_LOST"));
        }
    }

    const createTextMessageModel = (textMessage) => {
        let today = new Date();
        return {
            createDate: moment(today.toUTCString()).format("LLL"),
            localDate: true,
            message1: textMessage,
            timeStampId: today.getTime().toString(),
            messageType: TEXT_MESSAGE_TYPE_KEY,
            senderName: user.fullName ? user.fullName : "",
            senderUserId: user.id,
            supportSessionId: activeUserData?.supportSessionId,
            isReceived: false,
            avatarPath: user.avatarPath ? user.avatarPath : null,
        }
    }

    const createFileMessageModel = (files, textMessage) => {
        let today = new Date();
        return {
            createDate: moment(today.toUTCString()).format("LLL"),
            fileMessages: files,
            filePath: null,
            localDate: true,
            message1: textMessage,
            timeStampId: today.getTime().toString(),
            messageType: FILE_MESSAGE_TYPE_KEY,
            senderName: user.fullName ? user.fullName : "",
            senderUserId: user.id,
            supportSessionId: activeUserData?.supportSessionId,
            isReceived: false,
            avatarPath: user.avatarPath ? user.avatarPath : null,
        }
    }

    const handleScroll = () => {
        let messagesBlock = document.getElementById('messages');
        if (messagesBlock.scrollHeight - (messagesBlock.scrollTop + messagesBlock.offsetHeight) >= 200) {
            setIsShowScrollButton(true);
        } else {
            setIsShowScrollButton(false);
            dispatch(setNewMessageObj(null));
        }
    };

    const countCallDuration = (startTime, endTime) => {
        const duration = new Date(endTime).getTime() - new Date(startTime).getTime();
        let seconds = Math.floor(duration / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        hours = hours % 24;
        minutes = minutes % 60;
        seconds = seconds % 60;

        var hoursDisplay = null;
        var minutesDisplay = null;
        var secondsDisplay = null;


        if (hours) hoursDisplay = `${hours.toString().length === 1 ? "0" + hours : hours} h`
        if (minutes) minutesDisplay = `${minutes.toString().length === 1 ? "0" + minutes : minutes} m`
        if (seconds) secondsDisplay = `${seconds.toString().length === 1 ? "0" + seconds : seconds} s`

        return `${hoursDisplay ? hoursDisplay + "," : ""} ${minutesDisplay ? minutesDisplay + "," : ""} ${secondsDisplay ? secondsDisplay : ""}`;
    }

    const joinToChat = () => {
        if (!activeUserData) { return false; }
        const activeUserDataCopy = { ...activeUserData };
        setButtonSpinner(buttonSpinnerId);
        if (connection && connection.state === "Connected") {
            connection.invoke("JoinToExistedSession", user.id, activeUserData.clientSessionKey).then(() => {
                ApiService.getCurrentUserChat(activeUserData.supportSessionId).then(response => {
                    if (response && response.data) {
                        const data = { ...response.data };
                        let messageResponseModelsItem = [];
                        const messageResponseModels = [...data.messageResponseModels];
                        if (messageResponseModels && messageResponseModels.length) {
                            messageResponseModelsItem = messageResponseModels.filter(message => {
                                if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                                    return message;
                                }
                            })
                        }
                        dispatch(resetCurrentUserUnReadMessagesCount(activeUserData.clientSessionKey));
                        dispatch(
                            addFieldsToActiveUserDataFromSession(
                                data.tickets || [],
                                data?.isSessionAttachedMe,
                                data?.attachedUserId,
                                data?.sessionId,
                                data?.feedbackResponse,
                                data?.note,
                                data.source,
                            )
                        );
                        activeUserDataCopy.isSessionAttachedMe = true;
                        activeUserDataCopy.messages = messageResponseModelsItem;
                        dispatch(updateActiveUserData(activeUserDataCopy));
                        extractButtonSpinner(buttonSpinnerId);
                        scrollToBottom();
                        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_YOU_HAVE_JOINED_TO_THE_CHAT"));
                    }
                }).catch(error => getFail(error, buttonSpinnerId));
            }).catch(error => getFail(error, buttonSpinnerId));
        } else {
            AlertService.alert(ERROR_KEY, translationService.translate("TR_CONNECTION_LOST"));
            extractButtonSpinner(buttonSpinnerId)
        }

    }

    const replaceURLs = (message) => {
        if (!message) { return "" }
        var urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
        return message.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'https://' + hyperlink;
            }
            return `<a href="${hyperlink}" class="vnd--link-message" target="_blank"><mark>${url}</mark></a>`;
        });
    }

    const setButtonSpinner = useCallback(spinner => {
        dispatch(addButtonSpinner(spinner));
    }, []);

    const extractButtonSpinner = useCallback(spinner => {
        dispatch(removeButtonSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId, cb) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractButtonSpinner(spinnerId);
        cb && cb(false);
    }

    return translationService && activeUserData ?
        <div className={`user-chat `}>
            <div className={`d-lg-flex position-relative ${spinners.length ? "disabled" : ""}`}>
                <div className={`chat-main-container w-100`} style={{ height: windowSize && windowSize.height + "px" }}>

                    {/* render user head */}
                    <UserHead />
                    <div
                        id="chat-container"
                        className='position-relative h-100vh'
                    >
                        <div
                            id="messages"
                            className="chat-conversation px-lg-4 p-3"
                            onScroll={handleScroll}
                        >
                            <ul id="chat-container-item" className="list-unstyled m-0 d-block">
                                {
                                    activeUserData && activeUserData.messages && activeUserData.messages.length ?
                                        <Auxiliary>
                                            <li>
                                                <div className="chat-day-title">
                                                    <span className="title">
                                                        {
                                                            moment(activeUserData.messages[0].createDate).format("LL")
                                                        }
                                                    </span>
                                                </div>
                                            </li>
                                            {
                                                activeUserData.messages.map((message, index, array) => {
                                                    return <Auxiliary key={index}>
                                                        <li className={message.isWidgetMessage ? "sender" : "right"}>
                                                            <div className="conversation-list">
                                                                {
                                                                    (array[index + 1] && array[index].senderName !== array[index + 1].senderName) ||
                                                                        (
                                                                            array[index - 1] && array[index - 1].senderName !== array[index].senderName &&
                                                                            array[index + 1] && array[index + 1].senderName !== array[index].senderName
                                                                        ) ||
                                                                        !array[index + 1] ?

                                                                        <div className="chat-avatar" title={message.senderName}>
                                                                            {
                                                                                message.avatarPath ?
                                                                                    <img src={message.avatarPath} alt="/" /> :
                                                                                    <div className="chat-user-img align-self-center">
                                                                                        <div className="avatar-xs mb-2">
                                                                                            <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary avatar-chatboard">
                                                                                                {message.senderName ? message.senderName.charAt(0) : <AiOutlineUser />}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        : null
                                                                }

                                                                <div className="user-chat-content">
                                                                    <div className="ctext-wrap">
                                                                        <div
                                                                            className="ctext-wrap-content"
                                                                        >
                                                                            {
                                                                                message.fileMessages && message.fileMessages.length ?
                                                                                    <FileMessageComponent
                                                                                        files={message.fileMessages}
                                                                                        textMessage={
                                                                                            translationService.translate(Parser(replaceURLs(message.message1))) ?
                                                                                                translationService.translate(Parser(replaceURLs(message.message1))) :
                                                                                                Parser(replaceURLs(message.message1))
                                                                                        }
                                                                                    />
                                                                                    : null
                                                                            }
                                                                            {
                                                                                // only text message
                                                                                message.message1 &&
                                                                                    (!message.fileMessages || (message.fileMessages && !message.fileMessages.length)) &&
                                                                                    !message.mediaMessage && message.messageType === TEXT_MESSAGE_TYPE_KEY ?
                                                                                    <p className="mb-0 word-break-break-word message">
                                                                                        {
                                                                                            translationService.translate(Parser(replaceURLs(message.message1))) ?
                                                                                                translationService.translate(Parser(replaceURLs(message.message1))) :
                                                                                                Parser(replaceURLs(message.message1))
                                                                                        }

                                                                                    </p>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                // text message with call icon
                                                                                message.message1 &&
                                                                                    (!message.fileMessages || (message.fileMessages && !message.fileMessages.length)) &&
                                                                                    !message.mediaMessage && message.messageType !== TEXT_MESSAGE_TYPE_KEY ?
                                                                                    <div className='call-message-block'>
                                                                                        <div className='call-message-block-item'>
                                                                                            <p className="mb-0 word-break-break-word message">
                                                                                                {
                                                                                                    translationService.translate(Parser(replaceURLs(message.message1))) ?
                                                                                                        translationService.translate(Parser(replaceURLs(message.message1))) :
                                                                                                        Parser(replaceURLs(message.message1))
                                                                                                }
                                                                                            </p>
                                                                                            {
                                                                                                (() => {
                                                                                                    switch (message.messageType) {
                                                                                                        case VIDEO_MESSAGE_TYPE_KEY:
                                                                                                            return <img src={cwVideoCall} alt="/" />
                                                                                                        case VOICE_MESSAGE_TYPE_KEY:
                                                                                                            return <img src={cwVoiceCall} alt="/" />
                                                                                                        case SCREEN_SHARE_MESSAGE_TYPE_KEY:
                                                                                                            return <img src={cwScreenShare} alt="/" />
                                                                                                        default:
                                                                                                            break
                                                                                                    }
                                                                                                })()
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                message.mediaMessage ?
                                                                                    <div className='call-message-block'>
                                                                                        <div className='call-message-block-item'>
                                                                                            {
                                                                                                (() => {
                                                                                                    switch (message.messageType) {
                                                                                                        case 1:
                                                                                                            if (message.mediaMessage.status === 2) return <MissedVideoCallSvg />
                                                                                                            else return <CompletedVideoCallSvg />
                                                                                                        case 2:
                                                                                                            if (message.mediaMessage.status === 2) return <MissedVoiceCallSvg />
                                                                                                            else return <CompletedVoiceCallSvg />
                                                                                                        case 3:
                                                                                                            if (message.mediaMessage.status === 2) return <MissedScreenShareSvg />
                                                                                                            else return <CompletedScreenShareSvg />
                                                                                                        default:
                                                                                                            break
                                                                                                    }
                                                                                                })()
                                                                                            }
                                                                                            <p className="mb-0 word-break-break-word message">
                                                                                                {
                                                                                                    translationService.translate(message.message1) ?
                                                                                                        translationService.translate(message.message1) :
                                                                                                        message.message1
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            <p className="chat-time mb-0">
                                                                                {
                                                                                    message.mediaMessage && message.mediaMessage.startTime && message.mediaMessage.endTime ?
                                                                                        <span className='mr-2'>{countCallDuration(message.mediaMessage.startTime, message.mediaMessage.endTime)}</span>
                                                                                        : null
                                                                                }
                                                                                <i className="ri-time-line align-middle" />
                                                                                <span className="align-middle">
                                                                                    {
                                                                                        message.localDate ?
                                                                                            moment(message.createDate).format("HH:mm")
                                                                                            : moment(MainService.convertUTCDateToLocalDate(new Date(message.createDate))).format("HH:mm")
                                                                                    }
                                                                                </span>
                                                                                {
                                                                                    message.isReceived ?
                                                                                        <b><i className="ri-check-double-line ml-1"></i></b> :
                                                                                        <b><i className="ri-check-line ml-1"></i></b>
                                                                                }

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        {
                                                            array[index + 1] && array[index + 1].createDate && array[index].createDate ?
                                                                <li>
                                                                    {
                                                                        !MainService.isEqualDates(moment(array[index].createDate).format("LL"), moment(array[index + 1].createDate).format("LL")) ?
                                                                            <div className="chat-day-title">
                                                                                <span className="title">
                                                                                    {
                                                                                        moment(array[index + 1].createDate).format("LL")
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </li>
                                                                : null
                                                        }

                                                    </Auxiliary>

                                                })
                                            }
                                        </Auxiliary>
                                        : null
                                }
                            </ul>
                            <div ref={messagesEndRef} />
                        </div>
                        {
                            newMessageObj || isShowScrollButton ?
                                <div
                                    className='scroll-bottom-icon-block'
                                    title={
                                        newMessageObj && newMessageObj.message1 ?
                                            translationService.translate(newMessageObj.message1) ?
                                                translationService.translate(newMessageObj.message1) :
                                                newMessageObj.message1 :
                                            null
                                    }
                                    onClick={scrollToBottom}
                                >
                                    {
                                        newMessageObj && newMessageObj.message1 && !newMessageObj.fileMessages.length ?
                                            <p>{
                                                translationService.translate(Parser(newMessageObj.message1)) ?
                                                    translationService.translate(Parser(newMessageObj.message1)) :
                                                    Parser(newMessageObj.message1)
                                            }
                                            </p>
                                            : null
                                    }
                                    {
                                        newMessageObj && newMessageObj.fileMessages && newMessageObj.fileMessages.length ?
                                            <p><i className="ri-image-fill"></i></p>
                                            : null
                                    }
                                    <i className="ri-arrow-down-line"></i>
                                </div>
                                : null
                        }

                        {
                            typerUserName ?
                                <div className='vnd--message-typing'><strong>{typerUserName} </strong>
                                    <span>
                                        {translationService.translate("TR_TYPING")}
                                        <span className="animate-typing">
                                            <span className="dot ms-1 dot-dark"></span>
                                            <span className="dot ms-1 dot-dark"></span>
                                            <span className="dot ms-1 dot-dark"></span>
                                        </span>
                                    </span>
                                </div>
                                : null
                        }
                    </div>
                    {
                        activeUserData ?
                            <Auxiliary>
                                {
                                    !supporterId && activeUserData && !activeUserData.endTime ? <ChatInput onSendMessage={onSendMessage} /> : <div className='' />
                                }
                                {
                                    supporterId &&
                                        (user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY || user.userTypeId === PARTNER_USER_TYPE_ID_KEY) &&
                                        activeUserData.isSessionAttachedMe ? <ChatInput onSendMessage={onSendMessage} /> : <div className='' />
                                }
                                {
                                    MainService.checkServicePermission(subscriptionService, "multi_agent_chat_service") ?
                                        supporterId &&
                                            (user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY || user.userTypeId === PARTNER_USER_TYPE_ID_KEY) &&
                                            !activeUserData.isSessionAttachedMe && !activeUserData.endTime ?
                                            <div className={`border-top p-3 m-auto w-100 ${!activeGroupName ? "disabled" : ""}`}>
                                                <ActionButton
                                                    type="button"
                                                    name={translationService.translate("TR_JOIN_TO_CHAT")}
                                                    className="vnd-btn vnd-primary-btn w-100"
                                                    spinnerId={buttonSpinnerId}
                                                    onClick={joinToChat}
                                                />
                                            </div> : null
                                        : null
                                }
                                {
                                    activeUserData && activeUserData.endTime ?
                                        <>
                                            {
                                                activeUserData.feedbackResponse ?
                                                    <>
                                                        <div className='px-3'>
                                                            <div className='emojis-block justify-content-center'>
                                                                {
                                                                    emojisArray.map(emoji => {
                                                                        return <div
                                                                            key={emoji.rate}
                                                                            className={`small-emoji-block border ${activeUserData.feedbackResponse.rate !== emoji.rate ? "disabled" : ""}`}
                                                                        >
                                                                            <Auxiliary>
                                                                                {/* <b className='vnd-rating-values'>{emoji.rate}</b> */}
                                                                                <img src={emoji.src} alt="/" />
                                                                                {/* <div className='vnd--rating-count-block'>
                                                                                            <small className='vnd--rating-count'>{emoji.rate}</small>
                                                                                        </div> */}
                                                                            </Auxiliary>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                activeUserData.feedbackResponse.description ?
                                                                    <p className='mb-0 w-75 mx-auto text-center word-break-break-word font-size-16 mb-3'>{activeUserData.feedbackResponse.description}</p>
                                                                    : null
                                                            }

                                                        </div>
                                                    </>
                                                    : <div className='p-3 d-flex justify-content-center align-items-center'>
                                                        <small className='m-0'>{translationService.translate("TR_SESSION_CLOSED")}</small>
                                                    </div>
                                            }
                                        </>
                                        : null
                                }
                            </Auxiliary>
                            : null
                    }
                </div>
                {
                    activeUserData ?
                        <UserProfileSidebar />
                        : null
                }

            </div>
        </div>
        : null
}

export default withRouter(memo(UserChat));

