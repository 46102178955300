import React, { useState, useEffect, useCallback } from 'react';
import { InputGroup } from "reactstrap";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import OnlineUsers from "./OnlineUsers";
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import ApiService from '../../../../Services/ApiService';
import MainService from '../../../../Services/mainService';
import NoData from '../../../../Components/NoData/NoData';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../../Services/translationService';
import CustomInput from "./../../../../Components/Inputs/Input"
import uuid from 'react-uuid';
import * as moment from "moment";
import ReactCountryFlag from "react-country-flag"
import { setIsMuted, setTicketStatusResponse, setTicketUrgentResponse } from './../../../../Store/Actions/main';
import {
    addMessagesToUser,
    resetCurrentUserUnReadMessagesCount,
    setActiveUserData,
    setUsers,
    setDefaultUsers,
    showOrHideInboxTitleNotification,
    showOrHideMyClientsTitleNotification,
    setActiveSessionName,
    addFieldsToActiveUserDataFromSession,
    setTechnicalAssistants,
    setDefaultTechnicalAssistants,
    setSelectedCountry,
    setSelectedGroup,
    getCountries,
    setCountries,
    setScriptGroups,
    setScripts,
    toggleGroupsByCountry,
    getScriptGroupsWithoutCountry,
    removeCurrentChatAnimation
} from "./../../../../Store/Actions/chat";
import {
    addSpinner,
    removeSpinner
} from './../../../../Store/Actions/spinner';
import Parser from 'html-react-parser';
import { FaViber } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";


const Chats = (props) => {

    const dispatch = useDispatch();
    var supporterId = null;
    const { pathname } = useLocation();
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const activeGroupName = useSelector(state => state.main.activeGroupName);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);
    const user = useSelector(state => state.user.user);
    const partnerId = useSelector(state => state.main.partnerId);
    const isMuted = useSelector(state => state.main.isMuted);
    const {
        users,
        defaultUsers,
        activeUserData,
        isShowInboxTitleNotification,
        isShowMyClientsTitleNotification,
        activeSessionName,
        groupsByCountry
    } = useSelector(state => state.chat);

    const [searchText, setSearchText] = useState("");
    const [translationService, setTranslationService] = useState(null);
    const [supporterData, setSupporterData] = useState(null);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    useEffect(() => {
        dispatch(setUsers([]));
        dispatch(setActiveUserData(null));
        props.match.params.session && dispatch(setActiveSessionName(props.match.params.session));
        if (!supporterId) {
            switch (props.match.params.session) {
                case "inbox":
                    getInboxUsers()
                    break;
                case "my-clients":
                    getMyUsers();
                    break;
                case "all":
                    getAllUsers();
                    break;
                default:
                    props.history.push(`/${language}/dashboard/chats/inbox`);
                    break;
            }
        } else {
            switch (props.match.params.session) {
                case "my-clients":
                    getMyUsers();
                    break;
                case "all":
                    getAllUsers();
                    break;
                default:
                    props.history.push(`/${language}/dashboard/chats/my-clients/technical-assistant/${supporterId}`);
                    break;
            }
            getSupporterDataById(supporterId);
        }

        return () => {
            dispatch(setActiveUserData(null));
        }
    }, [pathname]);

    const getSupporterDataById = (supporterId) => {
        ApiService.getSupporterDataById(+supporterId, user.partnerId ? user.partnerId : partnerId).then(response => {
            if (response && response.data) {
                setSupporterData(response.data);
            }
        }).catch(error => getFail(error))
    }

    const search = (event, cb, maxLength = null) => {
        const defaultUsersCopy = [...defaultUsers];
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        if (event.target.value.length && event.target.value.trim().length) {
            if (defaultUsersCopy && defaultUsersCopy.length) {
                dispatch(setUsers([...defaultUsers].filter(user => user.clientName && user.clientName.toUpperCase().includes(event.target.value.toUpperCase()))))
            }
        } else {
            dispatch(setUsers(defaultUsersCopy))
        }
    }

    const getCurrentUserChat = (currentUser) => {
        var spinnerId = uuid();
        if (!currentUser || (currentUser && !currentUser.supportSessionId)) { return false; }
        if (currentUser && activeUserData && currentUser.clientSessionKey === activeUserData.clientSessionKey) { return false; }

        setSpinner(spinnerId);
        ApiService.getCurrentUserChat(currentUser.supportSessionId).then(response => {
            if (response && response.data) {
                const data = { ...response.data };
                checkSessionIsAttached(data?.sessionId);
                let messageResponseModelsItem = [];
                const messageResponseModels = [...data.messageResponseModels];
                if (messageResponseModels && messageResponseModels.length) {
                    messageResponseModelsItem = messageResponseModels.filter(message => {
                        if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                            return message;
                        }
                    })
                }
                dispatch(setActiveUserData(currentUser.clientSessionKey));
                dispatch(addMessagesToUser(currentUser.clientSessionKey, messageResponseModelsItem));
                dispatch(
                    addFieldsToActiveUserDataFromSession(
                        data.tickets || [],
                        data?.isSessionAttachedMe,
                        data?.attachedUserId,
                        data?.sessionId,
                        data?.feedbackResponse,
                        data?.note,
                        data.source,
                    )
                );
                if (pathname.includes("/dashboard/chats/my-clients") || pathname.includes("/dashboard/chats/all")) {
                    resetScripts();
                    dispatch(getCountries(user.partnerId ? +user.partnerId : +partnerId));
                    _getScriptGroupsWithoutCountry(1);
                }
                if (isConnectionOk() && activeGroupName) {
                    if (supporterId) {
                        if (activeUserData && data.isSessionAttachedMe) {
                            if (currentUser && !currentUser.isOnline) {
                                connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                    !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey)
                            } else {
                                connection.invoke("ToggleSupervisorSessionConnection", currentUser.clientSessionKey, activeUserData ? activeUserData.clientSessionKey : null)
                            }
                        }
                    } else {
                        if (currentUser && !currentUser.isOnline) {
                            connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey).catch(error => getFail(error))
                        }
                    }
                }
                ApiService.getTicketFields().then(res => {
                    if (res && res.data) {
                        dispatch(setTicketStatusResponse(res.data.ticketStatusResponse));
                        dispatch(setTicketUrgentResponse(res.data.ticketUrgentResponse));
                    }
                }).catch(error => getFail(error));
            }
            if (user.partnerId || partnerId) {
                getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
            }
            var userChat = document.querySelector(".user-chat");
            if (userChat) {
                userChat.classList.add("user-chat-show");
            }
            document.getElementById("chatInput")?.focus();
            extractSpinner(spinnerId);
            dispatch(removeCurrentChatAnimation(currentUser.clientSessionKey));
        }).catch(error => getFail(error, spinnerId));
    }

    const checkSessionIsAttached = (sessionId) => {
        if (!sessionId) { return false };
        if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
            let attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
            attachedSessionIds = attachedSessionIds.filter(item => item !== sessionId);
            localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
        }
    }

    const setInpulseClass = (sessionId) => {
        if (!sessionId) { return ""; }
        if (
            localStorage.getItem("attachedSessionIds") &&
            MainService.isJson(localStorage.getItem("attachedSessionIds")) &&
            JSON.parse(localStorage.getItem("attachedSessionIds")) &&
            JSON.parse(localStorage.getItem("attachedSessionIds")).includes(sessionId)
        ) {
            return "attached-session";
        } else {
            return "";
        }
    }

    const resetScripts = () => {
        dispatch(setCountries([]));
        dispatch(setSelectedCountry(null));
        dispatch(setScriptGroups([]));
        dispatch(setSelectedGroup(null));
        dispatch(setScripts([]));
        dispatch(toggleGroupsByCountry(false));
    }

    const _getScriptGroupsWithoutCountry = (currentPage) => {
        let data = {
            partnerId: user.partnerId ? +user.partnerId : +partnerId,
            prefix: "",
            pageNumber: currentPage,
            pageSize: fieldLengths.pageSize_10,
        }
        dispatch(getScriptGroupsWithoutCountry(data));
    }

    const getTechnicalAssistantsList = (partnerId) => {
        ApiService.getTechnicalAssistantsList(partnerId).then(response => {
            if (response && response.data) {
                const data = [...response.data];
                let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                dispatch(setTechnicalAssistants(sortedData));
                dispatch(setDefaultTechnicalAssistants(sortedData));
            }
        }).catch(error => getFail(error))
    }

    const getInboxUsers = () => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getInboxUsers(user.partnerId ? +user.partnerId : +partnerId).then(response => {
                if (response && response.data && response.data.length) {
                    const data = [...response.data];
                    let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                    dispatch(setUsers(sortedData));
                    dispatch(setDefaultUsers(sortedData));
                } else {
                    dispatch(setUsers(null));
                    dispatch(setDefaultUsers(null));
                    dispatch(setActiveUserData(null));
                }
                dispatch(showOrHideInboxTitleNotification(false));
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const getMyUsers = () => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getMyUsers(supporterId ? +supporterId : user.id, user.partnerId ? +user.partnerId : +partnerId).then(response => {
                if (response && response.data && response.data.length) {
                    let data = [...response.data];
                    let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                    dispatch(setUsers(sortedData));
                    dispatch(setDefaultUsers(sortedData));
                } else {
                    dispatch(setUsers(null));
                    dispatch(setDefaultUsers(null));
                    dispatch(setActiveUserData(null));
                }
                dispatch(showOrHideMyClientsTitleNotification(false));
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const getAllUsers = () => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getAllUsers(supporterId ? +supporterId : user.id, user.partnerId ? +user.partnerId : +partnerId).then(response => {
                if (response && response.data && response.data.length) {
                    const data = [...response.data];
                    let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                    dispatch(setUsers(sortedData));
                    dispatch(setDefaultUsers(sortedData));
                } else {
                    dispatch(setUsers(null));
                    dispatch(setDefaultUsers(null));
                    dispatch(setActiveUserData(null));
                }
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
        dispatch(setActiveUserData(null));
        dispatch(setDefaultUsers(null));
    };

    const maxHeight = () => {
        if (window.innerWidth >= 1198.98) {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 10}px)`
        } else {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 60}px)`
        }
    }

    return translationService ? <Auxiliary>
        {
            // isShowSettings && user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ?
            //     <Settings />
            //     :
            <div className={`vnd-list-container`}>
                <aside>
                    <div className="p-3 left-bar-header align-items-center">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4 className="vnd--upp-text-20">{translationService.translate("TR_CHATS")}</h4>
                            <div className='user-chat-nav ml-2'>
                                <Button type="button" color="none" onClick={() => dispatch(setIsMuted(!isMuted))} className="nav-btn user-profile-show box-shadow-none">
                                    {
                                        isMuted ? <i className="ri-volume-mute-line"></i> : <i className="ri-volume-up-line"></i>
                                    }
                                </Button>
                            </div>
                        </div>
                        {
                            defaultUsers && defaultUsers.length ?
                                <div className={`search-box chat-search-box`}>
                                    <InputGroup size="lg" className="rounded-lg">
                                        <span className="text-decoration-none text-muted pr-1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                                        <CustomInput
                                            id="searchText"
                                            htmlFor="searchText"
                                            type="text"
                                            name="searchText"
                                            placeholder={`${translationService.translate("TR_SEARCH")} ...`}
                                            value={searchText}
                                            onChange={(e) => search(e, setSearchText, fieldLengths.maxLength_100)}
                                        />
                                    </InputGroup>
                                </div>
                                : null
                        }
                    </div>

                    {/* online users */}
                    {
                        isConnectionOk() ?
                            <OnlineUsers />
                            : null
                    }
                    <div className={`w-100`}>
                        <hr className={`${supporterId ? "my-0 mt-2" : "m-0 mb-2"}`} />
                        {
                            supporterId ?
                                <div className='vnd-chat-owner-menu'>
                                    <small>
                                        <i className='capitalize'>
                                            {translationService.translate("TR_MY_CHAT")}
                                        </i>
                                    </small>
                                </div>
                                : null
                        }
                        <div className='px-4 d-flex'>
                            <h5
                                className={`font-size-16 inbox-users-title ${activeSessionName === "inbox" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => {
                                    props.history.push(`/${language}/dashboard/chats/inbox`)
                                }}
                            >
                                {translationService.translate("TR_INBOX")}
                                {
                                    isShowInboxTitleNotification ?
                                        <div className='title-notification ml-1'>
                                            <div className='title-notification-item' />
                                        </div>
                                        : null
                                }
                            </h5>
                            <h5
                                className={`font-size-16 my-users-title ${activeSessionName === "my-clients" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => { props.history.push(`/${language}/dashboard/chats/my-clients`) }}
                            >
                                {translationService.translate("TR_MY_CLIENTS")}
                                {
                                    isShowMyClientsTitleNotification ?
                                        <div className='title-notification ml-1'>
                                            <div className='title-notification-item' />
                                        </div>
                                        : null
                                }
                            </h5>
                            <h5
                                className={`font-size-16 my-users-title  ${activeSessionName === "all" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => {
                                    if (supporterId) {
                                        props.history.push(`/${language}/dashboard/chats/all/technical-assistant/${supporterId}`)
                                    } else {
                                        props.history.push(`/${language}/dashboard/chats/all`)
                                    }
                                }}
                            >
                                {translationService.translate("TR_ALL")}
                            </h5>
                        </div>
                        {
                            !supporterId ?
                                <hr className="my-2" /> : null
                        }
                    </div>
                    {
                        supporterId ?
                            <div className={`w-100`}>
                                <hr className='m-0' />
                                {
                                    supporterData && supporterData.user ?
                                        <div className='vnd-chat-owner-menu'>
                                            <small>
                                                <i className='capitalize'>
                                                    {translationService.translate("TR_CHAT").replace('{0}', supporterData.user.fullName)}
                                                </i>
                                            </small>
                                        </div>
                                        : null
                                }
                                <div className='px-4 d-flex'>
                                    <h5
                                        className={`font-size-16 my-users-title ${activeSessionName === "my-clients" && supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                        onClick={() => { props.history.push(`/${language}/dashboard/chats/my-clients/technical-assistant/${supporterId}`) }}
                                    >
                                        {translationService.translate("TR_ACTIVE")}
                                    </h5>
                                    <h5
                                        className={`font-size-16 my-users-title ${activeSessionName === "all" && supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                        onClick={() => { props.history.push(`/${language}/dashboard/chats/all/technical-assistant/${supporterId}`) }}
                                    >
                                        {translationService.translate("TR_ALL")}
                                    </h5>
                                </div>
                                <hr className='my-2' />
                            </div>
                            : null
                    }
                </aside>
                <div className="px-2 mb-auto">
                    <SimpleBar className="chat-message-list" style={{ maxHeight: maxHeight() }}>
                        <ul className="list-unstyled chat-list chat-user-list" id="chat-list">
                            {
                                users && users.length ? users.map((chat, key) =>
                                    <li
                                        key={key}
                                        id={"conversation_" + key}
                                        className={`cl-chat-wrappers 
                                            ${chat.unRead ? "unread" : ""}
                                            ${chat.isTyping ? "typing" : ""}
                                            ${chat.clientSessionKey && activeUserData && activeUserData.clientSessionKey && chat.clientSessionKey === activeUserData.clientSessionKey ? "active" : ""}
                                            ${setInpulseClass(chat.supportSessionId)}
                                            ${chat.isAnimate ? "attached-session" : ""}
                                        `}
                                    >
                                        <Link className='cl-chat-wrapper-item' to={"#"} onClick={() => getCurrentUserChat(chat)}>
                                            <div className="d-md-flex justify-content-between align-items-md-start align-items-center">
                                                <div className="d-flex">
                                                    <div className={"chat-user-img " + `${chat.isOnline ? "online" : "away"}` + " align-self-center me-3 ms-0"}>
                                                        {
                                                            chat.profilePicture ?
                                                                <img src={chat.profilePicture} className="rounded-circle avatar-xs" alt="/" />
                                                                : <div className="avatar-xs">
                                                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                                        {chat.clientName ? chat.clientName.charAt(0) : chat.clientSessionResponse ? chat.clientSessionResponse.clientName.charAt(0) : <AiOutlineUser />}
                                                                    </span>
                                                                </div>
                                                        }
                                                        <span className="user-status"></span>
                                                    </div>

                                                    <div className="flex-1 overflow-hidden menu-item-wrapper overflow-text" style={{ width: "200px" }}>
                                                        <h5 className="text-truncate font-size-15 m-0">{chat.clientName}</h5>
                                                        <p className="chat-user-message text-truncate mb-0 mt-1">
                                                            {
                                                                chat.isTyping ?
                                                                    <>
                                                                        {translationService.translate("TR_TYPING")}
                                                                        <span className="animate-typing">
                                                                            <span className="dot ms-1"></span>
                                                                            <span className="dot ms-1"></span>
                                                                            <span className="dot ms-1"></span>
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* image message */}
                                                                        {
                                                                            chat.messages && (chat.messages.length > 0 && chat.messages[(chat.messages).length - 1].isImageMessage === true) ? <i className="ri-image-fill align-middle me-1"></i> : null
                                                                        }
                                                                        {/* file message */}
                                                                        {
                                                                            chat.messages && (chat.messages.length > 0 && chat.messages[(chat.messages).length - 1].isFileMessage === true) ? <i className="ri-file-text-fill align-middle me-1"></i> : null
                                                                        }
                                                                        {
                                                                            chat.lastMessageResponse && chat.lastMessageResponse.message1 && (!chat.lastMessageResponse.fileMessages || (chat.lastMessageResponse.fileMessages && !chat.lastMessageResponse.fileMessages.length))
                                                                                ? translationService.translate(Parser(chat.lastMessageResponse.message1))
                                                                                    ? translationService.translate(Parser(chat.lastMessageResponse.message1))
                                                                                    : Parser(chat.lastMessageResponse.message1)
                                                                                : null
                                                                        }
                                                                        {
                                                                            chat.lastMessageResponse && ((chat.lastMessageResponse.fileMessages && chat.lastMessageResponse.fileMessages.length) || chat.lastMessageResponse.filePath) ? <i className="ri-image-fill"></i> : null
                                                                        }
                                                                    </>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='d-sm-flex justify-content-between align-items-start w-100'>
                                                    <ul className="vnd--list-group-horizontal d-sm-flex ms-md-5 w-auto">
                                                        {
                                                            chat.lastMessageResponse && chat.lastMessageResponse.createDate ?
                                                                <>
                                                                    <li>
                                                                        <small className='d-block'>
                                                                            {
                                                                                chat.lastMessageResponse.localDate ?
                                                                                    moment(chat.lastMessageResponse.createDate).format("HH:mm")
                                                                                    : moment(MainService.convertUTCDateToLocalDate(new Date(chat.lastMessageResponse.createDate))).format("HH:mm")
                                                                            }
                                                                        </small>
                                                                        {
                                                                            chat.unreadMessagesCount ?
                                                                                <div className="unread-message" id={"unRead" + chat.id}>
                                                                                    <span className="badge badge-soft-danger rounded-pill">{chat.unreadMessagesCount}</span>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                    </li>
                                                                </>
                                                                : null
                                                        }
                                                        <li className="ms-sm-5 ms-0">
                                                            <small className='d-block'>{translationService.translate("TR_CHANNEL")}</small>
                                                            <span className="text-md-center d-block">
                                                                {
                                                                    (() => {
                                                                        switch (chat.source) {
                                                                            case 0:
                                                                                return <i className="ri-message-3-fill font-size-22" title="Widget"></i>;
                                                                            case 1:
                                                                                return <i className="ri-facebook-box-fill font-size-22" title="Facebook"></i>;
                                                                            case 2:
                                                                                return <i className="ri-instagram-fill font-size-22" title="Instagram"></i>;
                                                                            case 3:
                                                                                return <i className="ri-whatsapp-fill font-size-22" title="WhatsApp"></i>;
                                                                            case 4:
                                                                                return <FaViber className="font-size-22" title="Viber" />;
                                                                            case 5:
                                                                                return <i className="ri-telegram-fill font-size-22" title="Telegram"></i>;
                                                                            default:
                                                                                return <i className="ri-message-3-fill font-size-22" title="Widget"></i>;
                                                                        }
                                                                    })()
                                                                }
                                                            </span>
                                                        </li>
                                                        <hr className='d-sm-none d-block my-1' />
                                                    </ul>

                                                    {
                                                        chat && chat.geolocationData && MainService.isJson(chat.geolocationData) ?
                                                            <>
                                                                <ul className="vnd--list-group-horizontal">
                                                                    <li>
                                                                        <small className='d-block'>{translationService.translate("TR_COUNTRY")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).country_name}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <small className='d-block'>{translationService.translate("TR_FLAG")}</small>
                                                                        <span>
                                                                            <ReactCountryFlag countryCode={JSON.parse(chat.geolocationData).country} svg />
                                                                        </span>
                                                                    </li>
                                                                    {/* <li>
                                                                        <small className='d-block'>{translationService.translate("TR_REGION")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).region}
                                                                        </span>
                                                                    </li> */}
                                                                    <li>
                                                                        <small className='d-block'>{translationService.translate("TR_CITY")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).city}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                                <ul className="vnd--list-group-horizontal">
                                                                    {/* <li>
                                                                        <small className='d-block'>{translationService.translate("TR_CALLING_CODE")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).country_calling_code}
                                                                        </span>
                                                                    </li> */}

                                                                    <li>
                                                                        <small className='d-block'>{translationService.translate("TR_IP")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).ip}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <small className='d-block'>{translationService.translate("TR_UTC_OFFSET")}</small>
                                                                        <span>
                                                                            {JSON.parse(chat.geolocationData).utc_offset}
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </>
                                                            : null
                                                    }
                                                    {
                                                        <ul className='vnd--list-group-horizontal w-auto'>
                                                            <li style={{ minWidth: "70px" }}>
                                                                <small className='d-block'>{translationService.translate("TR_LOGGED")}</small>
                                                                <span>
                                                                    {
                                                                        chat.scriptAttributeAuth ?
                                                                            translationService.translate("TR_YES") :
                                                                            translationService.translate("TR_NO")
                                                                    }
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ) : <NoData width={200} height={200} />
                            }
                        </ul>
                    </SimpleBar>

                </div>
            </div>
        }
    </Auxiliary> : null
}

export default withRouter(Chats)
