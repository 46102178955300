import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import loginImage from "../../../assets/images/reset-password-image.jpg";
import Input from '../../../Components/Inputs/Input';
import MainService from '../../../Services/mainService';
import uuid from 'react-uuid';
import ActionButton from '../../../Components/Buttons/ActionButton';
import TranslationService from '../../../Services/translationService';
import ApiService from '../../../Services/ApiService';
import { useCallback } from 'react';
import { addButtonSpinner, removeButtonSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import { ERROR_KEY, fieldLengths } from '../../../Constants/MainKeys';
import { userLogin } from '../../../Store/Actions/user';

const buttonSpinnerId = uuid();
const RestorePassword = (props) => {
  const query = new URLSearchParams(props.location.search);
  const key = query.get('key');
  const email = query.get('email');

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [password, setPassword] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    checkKey(key);
  }, [key]);

  useEffect(() => {
    user && props.history.push(language);
  }, [user, language, props.history])

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  const checkKey = (key) => {
    if (!key) { return false; }
    const spinnerId = uuid();
    ApiService.checkKey(key).then(response => {
    }).catch(error => getFail(error, spinnerId))
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      email.trim().length &&
      password.trim().length &&
      !isInvalidPassword
    ) {
      const form = {
        key,
        password: btoa(password)
      }
      setButtonSpinner(buttonSpinnerId);
      ApiService.restorePassword(form).then(response => {
        extractButtonSpinner(buttonSpinnerId);
        const data = {
          username: email.trim(),
          password: password.trim(),
        }
        dispatch(userLogin(data, buttonSpinnerId, props.history));
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return translationService ? <section className="section h-section">
    <div className="container">
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='vnd-block white-background vnd-border'>
            <div className='mb-4'>
              <h5 className="vnd--text-50">{translationService.translate("TR_RESTORE_PASSWORD")}</h5>
            </div>
            <form id="signinForm" onSubmit={onSubmit}>
              <Input
                id="password"
                htmlFor="password"
                type="password"
                name="password"
                value={password}
                labelValue={`${translationService.translate("TR_PASSWORD")} *`}
                placeholder={`${translationService.translate("TR_PASSWORD")}`}
                autoComplete="new-password"
                inputClassName={`${(isInvalidSubmit && !password.trim()) || isInvalidPassword ? "error-border" : ""}`}
                required={true}
                onChange={(event) => onChange(event, setPassword, fieldLengths.maxLength_100)}
                onBlur={() => checkFieldsValidation(password, "password")}
              />
              {
                isInvalidPassword ?
                  <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
                  : null
              }
              <ActionButton
                type="submit"
                name={translationService.translate("TR_SAVE")}
                className="my-4 vnd-primary-btn vnd-btn w-100"
                spinnerId={buttonSpinnerId}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </section> : null
}

export default withRouter(RestorePassword)