import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from 'react-router-dom';
import { setActiveGroupName, setActiveSection, setPartnerId } from '../../../Store/Actions/main';
import { ACTIVE_SECTION_KEY, LANGUAGE_KEY, PARTNER_USER_TYPE_ID_KEY, SPINNER_COLOR } from '../../../Constants/MainKeys';
import { logout } from '../../../Store/Actions/user';
import { setconversationNameInOpenChat } from '../../../Store/Actions/layout';
import { setActiveUserData } from '../../../Store/Actions/chat';
import { setLanguage } from '../../../Store/Actions/language';
import { getTranslations } from '../../../Store/Actions/translation';
import TranslationService from '../../../Services/translationService';
import PuffLoader from "react-spinners/PuffLoader";
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { AiOutlineUser } from "react-icons/ai";


const useOutsideAlerter = (ref, setIsShowUserPopup) => {

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowUserPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const PartnerHeader = (props) => {
  const { pathname } = useLocation()
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const activeSection = useSelector(state => state.main.activeSection);
  const language = useSelector(state => state.language.language);
  const languages = useSelector(state => state.language.languages);
  const translations = useSelector(state => state.translation.translations);
  const { isShowFlagSpinner } = useSelector(state => state.spinner);

  const [translationService, setTranslationService] = useState(null);
  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const [isShowUserPopup, setIsShowUserPopup] = useState(false);

  useOutsideAlerter(
    wrapperRef,
    setIsShowUserPopup
  );

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    localStorage.removeItem(ACTIVE_SECTION_KEY);
    setActiveSectionName("home");
  }, [])

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const logOut = () => {
    props.history.push(language);
    dispatch(logout());
    dispatch(setActiveGroupName(null));
    dispatch(setconversationNameInOpenChat("group"));
    dispatch(setPartnerId(null));
    dispatch(setActiveUserData(null));
  }

  const changeLanguage = isocode => {
    if (!isocode || isocode === language) { return false; }
    localStorage.setItem(LANGUAGE_KEY, isocode);
    setLanguageHandler(isocode);
    const newPath = pathname.toString().replace(props.match.path.toString(), `/${isocode}`);
    props.history.push(newPath.toString());
    var checkLanguage = document.getElementById("checkLanguage");
    checkLanguage && checkLanguage.click();
    dispatch(getTranslations(true));
  }

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 50,
      behavior: "smooth"
    });
  }

  return translationService ? <aside className='right-section'>
    <header className="vnd-header vnd-box-shadow">
      <nav className="vnd-navbar vnd-navbar-expand-lg vnd-light-navbar w-100">
        <div className="burger-menu-wrapper d-md-none d-flex align-items-center">
          <div className="signed-customer-modal-wrapper">
            <div className="vnd-signed-nav-link">
              <input
                type="checkbox"
                checked={isShowNavbar}
                onChange={() => setIsShowNavbar(!isShowNavbar)}
              />
              <span className='burger-icon'></span>
              <span className='burger-icon'></span>
              <span className='burger-icon'></span>
              <div className="burger-menu">
                <ul className="mt-0">
                  <li className="vnd-nav-burger-item">
                    <Link to={`/${language}/profile`} onClick={() => setIsShowNavbar(!isShowNavbar)} className="p-0">
                      {
                        <div className="chat-user-img align-self-center">
                          <div className="avatar-sm w-100 d-flex justify-content-center">
                            {
                              !user.avatarPath ?
                                <span
                                  className="avatar-title rounded-circle bg-soft-secondary d-flex"
                                  style={{ width: "3rem", height: "3rem", fontSize: "20px" }}
                                >
                                  {user.fullName ? user.fullName.charAt(0) : user.clientName ? user.clientName.charAt(0) : ""}
                                </span>
                                : <div
                                  className="vnd-supporter-avatar-img"
                                  style={{ width: "3rem", height: "3rem", backgroundImage: `url(${user.avatarPath})` }}
                                />
                            }
                          </div>
                        </div>
                      }

                      <h3 className="signed-cutomer-name">{user.fullName}</h3>
                    </Link>
                  </li>
                  <li className="hr"></li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/dashboard/chats/inbox`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_CHATBOARD")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/profile`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_PROFILE")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/widget-settings`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_WIDGET")} {translationService.translate("TR_SETTINGS")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/technical-assistants`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_TECHNICAL_ASSISTANTS")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/invitations`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_INVITATIONS")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/finances`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_FINANCES")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/subscriptions`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_SUBSCRIPTIONS")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/settings`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_SETTINGS")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="vnd-nav-burger-link m-0"
                      to={`/${language}/help`}
                      onClick={() => {
                        setActiveSectionName("home")
                        setIsShowNavbar(false)
                      }}
                    >
                      {translationService.translate("TR_HELP")}
                    </Link>
                  </li>

                  <li className="vnd-nav-burger-item">
                    <Link className="vnd-nav-burger-link" to="#" onClick={logOut}>{translationService.translate("TR_SIGNOUT")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- place for burger button --> */}

        <div className="">
          <ul className="vnd-navbar-nav">
            {/* <li className="vnd-nav-item d-flex align-items-center">
              <Link to={`/${language}/help`} className='me-2'>
                {translationService.translate("TR_HELP")}
              </Link>
            </li> */}
            <li className="vnd-nav-item d-flex align-items-center">
              <div className="current-language">
                {
                  isShowFlagSpinner ?
                    <div className='d-flex'>
                      <PuffLoader size={30} color={SPINNER_COLOR} />
                    </div>
                    : <>
                      {
                        languages && languages.length ?
                          languages.map(languageItem => {
                            if (languageItem.isocode2 === language) {
                              return <div key={languageItem.id}><img src={languageItem.flagPath} alt="" /></div>
                            }
                          })
                          : null
                      }
                      <input id="checkLanguage" type="checkbox" />
                    </>
                }
                <ul className="falges">
                  {
                    languages && languages.length ?
                      languages.map(languageItem => {
                        return <li key={languageItem.id}>
                          <div className="flag" rel={languageItem.isocode2}>
                            <img
                              src={languageItem.flagPath}
                              alt=''
                              onClick={() => changeLanguage(languageItem.isocode2)}
                            />
                          </div>
                        </li>
                      })
                      : null
                  }
                </ul>
              </div>
            </li>
            {
              user ?
                <li className="vnd-nav-item signed-customer-modal-wrapper">
                  <div className="vnd-signed-nav-link" ref={wrapperRef}>
                    <input
                      type="checkbox"
                      checked={isShowUserPopup}
                      onChange={() => setIsShowUserPopup(!isShowUserPopup)}
                    />
                    {
                      user.avatarPath ?
                        <div className="avatar vnd-customer-avatar">
                          <div className="signed-avatar vnd-bkg-cover-img" style={{ backgroundImage: `url(${user.avatarPath})` }} />
                        </div>
                        : <div className="chat-avatar avatar-lg" style={{ width: "35px", height: "35px" }}>
                          <div className="chat-user-img align-self-center">
                            <div className="avatar-xs mb-2">
                              <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary capitalize">
                                {user.fullName && user.fullName.trim().length ? user.fullName.charAt(0) : user.firstname ? user.firstname.charAt(0) : <AiOutlineUser />}
                              </span>
                            </div>
                          </div>
                        </div>
                    }
                    <ul className="signed-user-modal">
                      <li>
                        <h3 className="signed-cutomer-name">{user.email}</h3>
                      </li>
                      {
                        user && user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
                          <Auxiliary>
                            <li><Link to={`/${language}/profile`} onClick={() => setIsShowUserPopup(!isShowUserPopup)}>{translationService.translate("TR_PROFILE")}</Link></li>
                            <li><Link to={`/${language}/dashboard/chats/inbox`} onClick={() => setIsShowUserPopup(!isShowUserPopup)}>{translationService.translate("TR_CHATBOARD")}</Link></li>
                          </Auxiliary>
                          : null
                      }
                      <li className="hr"></li>
                      <li><Link to="#" onClick={logOut}>{translationService.translate("TR_SIGNOUT")}</Link></li>
                    </ul>
                  </div>
                </li>
                : <li className={`vnd-nav-item ${activeSection && activeSection.includes("sign-in") ? "active" : ""}`}>
                  <Link className="vnd-nav-link" to={`/${language}/sign-in`} onClick={() => setActiveSectionName("sign-in")}>{translationService.translate("TR_SIGNIN")}</Link>
                </li>
            }
          </ul>
        </div>
      </nav>
    </header>
  </aside>
    : null
}

export default withRouter(PartnerHeader)