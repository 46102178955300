export const RELAMY_VERSION = "1.8.8";
export const PROJECT_NAME = "Relamy";
export const TOKEN_KEY = 'token';
export const LANGUAGE_KEY = 'language';
export const LANGUAGES_KEY = 'languages';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TRANSLATE_KEYS = 'translatekeys';
export const ACTIVE_SECTION_KEY = 'active-section';
export const ACTIVE_GROUP_NAME_KEY = 'activeGroupName';
export const ACTIVE_GROUPS_NAME_KEY = 'activeGroupsName';
export const OBJECT_KEY = 'object';
export const INFO_KEY = "info";
export const ERROR_KEY = 'error';
export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";
export const PARTNER_ID_KEY = "partnerId";
export const NUMBER_KEY = "number";

export const IMAGE_KEY = 'image';
export const FILE_KEY = 'file';
export const VIDEO_KEY = 'video';
export const SPINNER_COLOR = '#00266c';

export const DEVELOPER_USER_TYPE_ID_KEY = 1;
export const SUPERADMIN_USER_TYPE_ID_KEY = 2;
export const ADMIN_USER_TYPE_ID_KEY = 3;
export const SEMI_PARTNER_TYPE_ID_KEY = 4;
export const PARTNER_USER_TYPE_ID_KEY = 5;
export const SUPPORTER_USER_TYPE_ID_KEY = 6;
export const SUPERVISOR_USER_TYPE_ID_KEY = 8;

export const ALL_VALID_FILE_TYPES = ['jpeg', 'png', 'jpg', 'pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'JPEG', 'PNG', 'JPG', 'PDF', 'TXT', 'DOC', 'DOCX', 'RTF', 'XLS', 'XLSX'];
export const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG'];
export const VALID_FILE_TYPES_KEY = ['pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'PDF', 'TXT', 'DOC', 'DOCX', 'RTF', 'XLS', 'XLSX'];
export const VALID_VIDEO_TYPES_KEY = ['mp4'];


export const TEXT_MESSAGE_TYPE_KEY = 10;
export const VIDEO_MESSAGE_TYPE_KEY = 20;
export const VOICE_MESSAGE_TYPE_KEY = 30;
export const SCREEN_SHARE_MESSAGE_TYPE_KEY = 40;
export const FILE_MESSAGE_TYPE_KEY = 50;

export const fieldLengths = {
  maxLength_30: 30,
  maxLength_50: 50,
  maxLength_100: 100,
  maxLength_120: 120,
  maxLength_150: 150,
  maxLength_200: 200,
  maxLength_250: 250,
  maxLength_1000: 1000,
  maxLength_1500: 1500,
  maxLength_2000: 2000,
  pageSize_10: 10,
  pageSize_20: 20,
}
