import React, { useState, useEffect } from 'react'
import { Link, useLocation, withRouter } from "react-router-dom"
import { useSelector } from 'react-redux';
import TranslationService from './../../../Services/translationService';
import { UncontrolledTooltip } from 'reactstrap'
import { PROJECT_NAME } from '../../../Constants/MainKeys'
import LogoImgae from '../../../assets/logo/logo-big-white.png'
import LogoMinImgae from '../../../assets/logo/logo-white.png'

const PartnerLeftMenu = (props) => {
  const { pathname } = useLocation()
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <aside className="dashboard-menu-container">
    <div className="dashboard-menu-wrapper">
      <div className="dashboard-menu-brand">
        <div className="dashboard-menu-brand-name cursor-pointer" onClick={() => props.history.push(`/${language}/widget-settings`)}>
          <img src={LogoImgae} alt={PROJECT_NAME} />
          {/* {PROJECT_NAME} */}
        </div>
        <div className="dashboard-mobile-menu-brand-name">
          <img src={LogoMinImgae} alt={PROJECT_NAME} />
        </div>
      </div>
      <ul style={{ overflowY: "auto" }} className="dashboard-menu d-flex flex-column h-100">
        <li id="widget-settings" className="dashboard-menu-item">
          <Link to={`/${language}/widget-settings`} className={`dashboard-mobile-menu-link ${pathname.includes("/widget-settings") ? "active" : ""}`} >
            <i className="ri-sound-module-line"></i>
          </Link>
          <Link to={`/${language}/widget-settings`} className={`dashboard-menu-link d-flex ${pathname.includes("/widget-settings") ? "active" : ""}`} >
            <i className="ri-sound-module-line"></i>
            {translationService.translate("TR_WIDGET_SETTINGS")}
          </Link>
          <UncontrolledTooltip target="widget-settings" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_WIDGET_SETTINGS")}
          </UncontrolledTooltip>
        </li>

        <li id="technical-assistants" className="dashboard-menu-item">
          <Link to={`/${language}/technical-assistants`} className={`dashboard-mobile-menu-link ${pathname.includes("/technical-assistants") ? "active" : ""}`} >
            <i className="ri-customer-service-line"></i>
          </Link>
          <Link to={`/${language}/technical-assistants`} className={`dashboard-menu-link d-flex ${pathname.includes("/technical-assistants") ? "active" : ""}`} >
            <i className="ri-customer-service-line"></i>
            {translationService.translate("TR_TECHNICAL_ASSISTANTS")}
          </Link>
          <UncontrolledTooltip target="technical-assistants" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_TECHNICAL_ASSISTANTS")}
          </UncontrolledTooltip>
        </li>

        <li id="invitations" className="dashboard-menu-item">
          <Link to={`/${language}/invitations`} className={`dashboard-mobile-menu-link ${pathname.includes("/invitations") ? "active" : ""}`} >
            <i className="ri-send-plane-line"></i>
          </Link>
          <Link to={`/${language}/invitations`} className={`dashboard-menu-link d-flex ${pathname.includes("/invitations") ? "active" : ""}`} >
            <i className="ri-send-plane-line"></i>
            {translationService.translate("TR_INVITATIONS")}
          </Link>
          <UncontrolledTooltip target="invitations" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_INVITATIONS")}
          </UncontrolledTooltip>
        </li>

        <li id="finances" className="dashboard-menu-item">
          <Link to={`/${language}/finances`} className={`dashboard-mobile-menu-link ${pathname.includes("/finances") ? "active" : ""}`} >
            <i className="ri-bank-line"></i>
          </Link>
          <Link to={`/${language}/finances`} className={`dashboard-menu-link d-flex ${pathname.includes("/finances") ? "active" : ""}`} >
            <i className="ri-bank-line"></i>
            {translationService.translate("TR_FINANCES")}
          </Link>
          <UncontrolledTooltip target="finances" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_FINANCES")}
          </UncontrolledTooltip>
        </li>

        <li id="subscriptions" className="dashboard-menu-item">
          <Link to={`/${language}/subscriptions`} className={`dashboard-mobile-menu-link ${pathname.includes("/subscriptions") ? "active" : ""}`} >
            <i className="ri-vip-crown-2-line"></i>
          </Link>
          <Link to={`/${language}/subscriptions`} className={`dashboard-menu-link d-flex ${pathname.includes("/subscriptions") ? "active" : ""}`} >
            <i className="ri-vip-crown-2-line"></i>
            {translationService.translate("TR_SUBSCRIPTIONS")}
          </Link>
          <UncontrolledTooltip target="subscriptions" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_SUBSCRIPTIONS")}
          </UncontrolledTooltip>
        </li>

        <li id="chats" className="dashboard-menu-item">
          <Link to={`/${language}/dashboard/chats/inbox`} className={`dashboard-mobile-menu-link ${pathname.includes("/dashboard/chats") ? "active" : ""}`} >
            <i className="ri-message-3-line"></i>
          </Link>
          <Link to={`/${language}/dashboard/chats/inbox`} className={`dashboard-menu-link d-flex ${pathname.includes("/dashboard/chats") ? "active" : ""}`} >
            <i className="ri-message-3-line"></i>
            {translationService.translate("TR_CHATBOARD")}
          </Link>
          <UncontrolledTooltip target="chats" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_CHATBOARD")}
          </UncontrolledTooltip>
        </li>

        {/* <li id="settings" className="dashboard-menu-item mt-auto">
          <Link to={`/${language}/settings`} className={`dashboard-mobile-menu-link ${pathname.includes("/settings") ? "active" : ""}`} >
            <i className="ri-settings-2-line"></i>
          </Link>
          <Link to={`/${language}/settings`} className={`dashboard-menu-link d-flex ${pathname.includes("/settings") ? "active" : ""}`} >
            <i className="ri-settings-2-line"></i>
            {translationService.translate("TR_SETTINGS")}
          </Link>
          <UncontrolledTooltip target="settings" placement="right" className='tool-tip capitalize'>
            {translationService.translate("TR_SETTINGS")}
          </UncontrolledTooltip>
        </li> */}
      </ul>
    </div>
  </aside> : null
}

export default withRouter(PartnerLeftMenu);
