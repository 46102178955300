import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, fieldLengths } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import ApiService from '../../../Services/ApiService';
import * as moment from "moment";
import TranslationService from '../../../Services/translationService';
import NoData from '../../../Components/NoData/NoData';
import ReactPaginate from 'react-paginate';
import MainService from '../../../Services/mainService';
import { AiOutlineInfoCircle, AiOutlineLeft } from "react-icons/ai";
import Auxiliary from '../../../hoc/auxiliary/auxiliary';

const Finances = (props) => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user)
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [translationService, setTranslationService] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const handlePageClick = (event) => {
    // setPageNumber(event.selected + 1);
    setActivePageNumber(event.selected);
    getPaymentHistory(event.selected + 1);
  }

  useEffect(() => {
    getPaymentHistory(1);
  }, [])

  const getPaymentHistory = (currentPage) => {
    const spinnerId = uuid();
    setSpinner(spinnerId)
    ApiService.getPaymentHistory(user.id, currentPage, fieldLengths.pageSize_10).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setPaymentHistory(response.data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService && user ?
    <aside className='right-section'>
      <section className="dashboard top-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="vnd--aside-title-block">
                <div className="backspace">
                  <span
                    className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                  </span>
                </div>
                <span className="slash">|</span>
                <p className="vnd--upp-text-22">{translationService.translate("TR_FINANCES")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-2">
              <div className='vnd--m-block light-brand-background'>
                <div className='vnd--info-block'>
                  <AiOutlineInfoCircle />
                  <p className='m-0'>{translationService.translate("TR_FINANCE_TEXT")}</p>
                </div>
              </div>
            </div>
          </div>
          {
            paymentHistory && paymentHistory.length ?
              <Auxiliary>
                <div className='row'>
                  <div className="col-12 mt-4">
                    <div className='table-responsive vnd--borderd vnd--m-block'>
                      <table className="table mb-0">
                        <thead className="thead-dark">
                          <tr>
                            <th>#</th>
                            <th>{translationService.translate("TR_AMOUNT")}</th>
                            <th>{translationService.translate("TR_CURRENCY")}</th>
                            <th>{translationService.translate("TR_RATE")}</th>
                            <th>{translationService.translate("TR_DATE")}</th>
                            <th>{translationService.translate("TR_STATUS")}</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            paymentHistory.map((item, index) => {
                              return <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  {item.currency ? item.currency?.symbol : null}
                                  &nbsp;
                                  {item.amount}
                                </td>
                                <td>
                                  {item.currency ? item.currency?.isocode : null}
                                </td>
                                <td>
                                  {item.currency ? item.currency?.rate : null}
                                </td>
                                <td>
                                  {item.createDate ? moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("ll HH:mm") : ""}
                                </td>
                                <td>
                                  {item.statusText ? translationService.translate(item.statusText) : ""}
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    {
                      pagination && pagination.TotalPages > 1 ?
                        <div className='w-100 d-flex justify-content-end mt-4'>
                          <ReactPaginate
                            nextLabel={translationService.translate("TR_NEXT")}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pagination.TotalPages}
                            previousLabel={translationService.translate("TR_PREVIOUS")}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={activePageNumber}
                          />
                        </div>
                        : null
                    }
                  </div>
                </div>
              </Auxiliary>
              : <NoData />
          }
        </div>
      </section>
    </aside >
    : null
}

export default Finances;