import { useGoogleLogin } from "@react-oauth/google";
import google from '../../../../assets/images/auth-services/google.svg';
import { userRegistration } from '../../../../Store/Actions/user';
import axios from "axios";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const spinnerId = uuid();

const GoogleAuth = props => {
    const dispatch = useDispatch();
    const signUp = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                let res = await axios.get(
                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
                );
                const data = {
                    email: res.data.email,
                    firstName: res.data.given_name,
                    lastName: res.data.family_name,
                    userRegistrationType: 0
                }
                // console.log(data);

                // dispatch(userRegistration(data, spinnerId, props.history))

                // console.log("res", res);
            } catch (error) {
                // console.log(error);
            }
        },
        onError: err => {
            // console.log(err);
        },
    });

    return <div className='vnd--alternative-auth-item' onClick={() => { signUp() }}>
        <img src={google} alt="" />
        <span>Google</span>
    </div>
}

export default withRouter(GoogleAuth)
